import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useRouter } from "vue-router";
import IconNotification from "@/icons/IconNotification.vue";
import { useStore } from "vuex";
import { computed, onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UINotificationsButton',
  props: {
  notReadCount: {
    type: [Number, String],
    default: 0,
  },
},
  setup(__props) {

const router = useRouter();

const props = __props;

const store = useStore();

const newNotificationsCount = computed<number>(
  () => store.state.notificationsStore.newNotifications.length
);

const toNotifications = async () => {
  await router.push({ path: "/notifications" });
};

onMounted(async () => {
  await store.dispatch("notificationsStore/fetchNotifications");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: "notifications-button",
    onClick: toNotifications,
    "data-testid": "notification-button"
  }, [
    _createVNode(IconNotification, {
      width: 24,
      height: 24
    }),
    _withDirectives(_createElementVNode("span", { class: "not-read-count" }, _toDisplayString(newNotificationsCount.value), 513), [
      [_vShow, newNotificationsCount.value]
    ])
  ]))
}
}

})