import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "partner-tasks-page page" }

import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import PartnerStageList from "@/modules/partner-tasks/components/PartnerStageList.vue";
import PartnerTasksTabs from "@/modules/partner-tasks/components/PartnerTasksTabs.vue";
import SecondaryHeader from "@/modules/layouts/components/headers/SecondaryHeader.vue";
import SidebarPageHeader from "@/modules/layouts/components/headers/SidebarPageHeader.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PartnerTasksPage',
  setup(__props) {

const store = useStore();

const activeTaskType = ref<string>("ALL");

const onFilter = (value: string) => {
  activeTaskType.value = value;
};

onMounted(async () => {
  await store.dispatch("partnerTasksStore/fetchStages");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(SidebarPageHeader, { class: "header" }, {
      title: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" Задачи ")
      ])),
      _: 1
    }),
    _createVNode(PartnerTasksTabs, {
      "onUpdate:filterTasks": onFilter,
      style: { marginBottom: '1.5rem' }
    }),
    _createVNode(PartnerStageList, { "active-task-type": activeTaskType.value }, null, 8, ["active-task-type"])
  ]))
}
}

})