import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-input" }
const _hoisted_2 = { class: "input-with-prefix" }
const _hoisted_3 = ["type", "readonly", "maxlength", "value"]

import { computed } from "vue";
import { vMaska } from "maska";


export default /*@__PURE__*/_defineComponent({
  __name: 'UIPhoneInput',
  props: {
  placeHolder: {
    type: String,
    required: true,
  },
  modelValue: {
    type: [String, Number],
    required: true,
  },
  type: {
    type: String,
    default: "text",
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  prefix: {
    type: String,
    default: "",
  },
  maxLength: {
    type: String,
    default: "999",
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const props = __props;

const updateValue = (event: InputEvent) => {
  const target = event.target as HTMLInputElement;
  emits("update:modelValue", target.value);
};

const active = computed(() => {
  return props.modelValue || props.modelValue === 0
    ? ["label", "labelActive"]
    : "label";
});

const withPrefix = computed(() => {
  return props.prefix && props.modelValue ? "with-prefix" : "";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("span", { class: "prefix" }, _toDisplayString(__props.prefix), 513), [
        [_vShow, !!__props.prefix && !!__props.modelValue]
      ]),
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(["input", withPrefix.value]),
        type: __props.type,
        readonly: __props.readonly ? 'readonly' : null,
        maxlength: __props.maxLength,
        value: __props.modelValue,
        "data-maska": "+7 (###) ### ##-##",
        onInput: updateValue
      }, null, 42, _hoisted_3), [
        [_unref(vMaska)]
      ])
    ]),
    _createElementVNode("label", {
      class: _normalizeClass(active.value)
    }, _toDisplayString(__props.placeHolder), 3)
  ]))
}
}

})