import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "add-expense-modal" }
const _hoisted_2 = { class: "dropdown-container" }
const _hoisted_3 = {
  key: 0,
  class: "dropdown-menu"
}
const _hoisted_4 = { class: "dropdown-list" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "description" }
const _hoisted_7 = { class: "category" }
const _hoisted_8 = { class: "button-container" }

import { computed, reactive, ref } from "vue";
import { toast } from "vue3-toastify";
import { createExpense } from "@/modules/finances/services/finances.services";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import UIInput from "@/modules/UI-kit/UIInput.vue";
import UIButton from "@/modules/UI-kit/UIButton.vue";
import axios from "axios";
import { API_URL } from "@/api";

interface ExpensesSearchItems {
  expense_category_title: string;
  id: number;
  description: string;
  title: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddExpenseModal',
  setup(__props) {

const store = useStore();
const route = useRoute();

const state = reactive({
  sum: "",
  header: "",
});

const sumInputRef = ref(null);
const isOpen = ref(false);
const searchString = ref("");
const items = ref<ExpensesSearchItems[]>([]);
const selectedItem = ref(null);

const submitButtonDisabled = computed(() => {
  return !state.sum || !state.header || !selectedItem.value;
});

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
  if (!isOpen.value) {
    searchString.value = "";
    items.value = [];
  }
  fetchItems();
};

const fetchItems = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.error("Токен авторизации отсутствует");
      return;
    }

    const response = await axios.get(
      `${API_URL}/finance/partner/${
        localStorage.getItem("spotId") || ""
      }/expense-items`,
      {
        headers: {
          Authorization: `Bearer_${token}`,
        },
        params: {
          search_string: searchString.value,
        },
      }
    );
    items.value = response.data.items;
  } catch (error) {
    console.error("Ошибка запроса:", error);
  }
};

const onSumHandler = (value: string) => {
  let processedValue = value;

  processedValue = processedValue.replace(",", ".");
  processedValue = processedValue.replace(/[^\d.]/g, "");
  const parts = processedValue.split(".");

  if (parts.length > 2) {
    processedValue = parts[0] + "." + parts.slice(1).join("");
  }

  if (parts.length === 2) {
    processedValue = parts[0] + "." + parts[1].slice(0, 3);
  }

  if (parts[0].length > 1 && parts[0].startsWith("0")) {
    processedValue =
      parts[0].replace(/^0+/, "") + (parts[1] ? "." + parts[1] : "");
  }

  state.sum = processedValue;

  if (sumInputRef.value && sumInputRef.value?.$el) {
    sumInputRef.value.$el.querySelector("input").value = processedValue;
  }
};

const selectItem = (item: any) => {
  selectedItem.value = item;
  isOpen.value = false;
};

const role = computed(() => store.state.authStore.role);

const addExpense = async () => {
  const spotId = localStorage.getItem("spotId") || "";
  try {
    const response = await createExpense(
      [
        {
          sum: state.sum,
          title: state.header,
          partnerExpenseItemId: selectedItem.value?.id,
        },
      ],
      spotId
    );

    if (response.data.exceptions.lenght) {
      throw new Error();
    }

    store.commit("modalsStore/closeModal");
    await store.dispatch("financesStore/fetchExpensesSum", {
      role: role.value,
      partnerId: route.params?.id,
      spotId: localStorage.getItem("spotId"),
    });
    toast.success("Статья затрат успешно создана");
  } catch (e) {
    toast.error("Не удалось создать");
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "title" }, [
      _createElementVNode("h1", { class: "title-text" }, "Внесение расходов")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        onClick: toggleDropdown,
        class: "dropdown"
      }, [
        _createElementVNode("span", null, _toDisplayString(selectedItem.value?.title || "Выберите статью"), 1)
      ]),
      (isOpen.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchString).value = $event)),
              onInput: fetchItems,
              placeholder: "Введите текст для поиска",
              class: "search-input"
            }, null, 544), [
              [_vModelText, searchString.value]
            ]),
            _createElementVNode("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: item.id,
                  onClick: ($event: any) => (selectItem(item)),
                  class: "dropdown-item"
                }, [
                  _createElementVNode("div", null, [
                    _createElementVNode("strong", null, _toDisplayString(item.title), 1),
                    _createElementVNode("p", _hoisted_6, _toDisplayString(item.description), 1),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(item.expense_category_title), 1)
                  ])
                ], 8, _hoisted_5))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(UIInput, {
      ref_key: "sumInputRef",
      ref: sumInputRef,
      class: "item",
      "model-value": state.sum,
      "place-holder": "Сумма",
      "onUpdate:modelValue": onSumHandler
    }, null, 8, ["model-value"]),
    _createVNode(UIInput, {
      class: "item",
      modelValue: state.header,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.header) = $event)),
      "place-holder": "Заголовок"
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(UIButton, {
        onClick: addExpense,
        "is-disabled": submitButtonDisabled.value,
        "is-loading": false
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Сохранить ")
        ])),
        _: 1
      }, 8, ["is-disabled"])
    ])
  ]))
}
}

})