import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "history-expense-modal" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "dropdown-list" }
const _hoisted_4 = { class: "title-item-text" }
const _hoisted_5 = { class: "nested-list" }
const _hoisted_6 = { class: "dropdown-item" }
const _hoisted_7 = { class: "description" }
const _hoisted_8 = { class: "category-container" }
const _hoisted_9 = { class: "category" }
const _hoisted_10 = { class: "date-item" }
const _hoisted_11 = { key: 1 }

import { computed, onMounted, reactive, ref } from "vue";
import { toast } from "vue3-toastify";
import {
  createExpense,
  getExpenses,
} from "@/modules/finances/services/finances.services";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { IExpenses } from "@/modules/finances/types/finances.types";
import UIInput from "@/modules/UI-kit/UIInput.vue";
import UIButton from "@/modules/UI-kit/UIButton.vue";
import UIDropdown from "@/modules/UI-kit/UIDropdown.vue";
import axios from "axios";
import { API_URL } from "@/api";

interface ExpensesItems {
  id: number;
  sum: number;
  date: string;
  title: string;
  category_title: string;
  item_title: string;
}

interface ExpensesHistoryItems {
  date: Date;
  expenses: ExpensesItems[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HistoryExpensesModal',
  setup(__props) {

const store = useStore();
const route = useRoute();

const empty = ref<any>({
  checked: false,
  label: "Не выбрано",
  value: "Не выбрано",
});
const state = reactive({
  sum: "",
  header: "",
});

const role = computed(() => store.state.authStore.role);

const formatDate = (dateInput: Date | string): string => {
  const date = typeof dateInput === "string" ? new Date(dateInput) : dateInput;
  return new Intl.DateTimeFormat("ru-RU", {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(date);
};
function formatTime(dateString: string): string {
  const date = new Date(dateString);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

const formatCurrency = (sum: number): string => {
  return new Intl.NumberFormat("ru-RU").format(sum);
};

//константы для нового элемента
const isOpen = ref(false);
const searchString = ref("");
const items = ref<ExpensesHistoryItems[]>([]);
const selectedItem = ref(null);

const fetchItems = async () => {
  const token = localStorage.getItem("accessToken");
  if (!token) return;

  const url =
    role.value === "ROLE_PARTNER"
      ? `${API_URL}/finance/partner/${localStorage.getItem("spotId")}/expenses`
      : `${API_URL}/finance/stuff/expenses/${
          route.params?.id
        }/${localStorage.getItem("spotId")}`;

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer_${token}` },
    });
    items.value = data.expenses;
  } catch (error) {
    console.error("Ошибка запроса:", error);
  }
};
//---------

onMounted(async () => {
  await fetchItems();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "title" }, [
      _createElementVNode("h1", { class: "title-text" }, "История расходов")
    ], -1)),
    (items.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "dropdown-item"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("strong", null, _toDisplayString(formatDate(item.date)), 1)
                ]),
                _createElementVNode("ul", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.expenses, (expense, expenseIndex) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: expense.id || expenseIndex,
                      class: "nested-item"
                    }, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("strong", null, _toDisplayString(formatCurrency(expense.sum)) + " руб. - " + _toDisplayString(expense.title), 1),
                        _createElementVNode("p", _hoisted_7, _toDisplayString(expense.item_title), 1),
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("span", _hoisted_9, _toDisplayString(expense.category_title), 1),
                          _createElementVNode("span", _hoisted_10, _toDisplayString(formatTime(expense.date)), 1)
                        ])
                      ])
                    ]))
                  }), 128))
                ])
              ]))
            }), 128))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[0] || (_cache[0] = [
          _createElementVNode("p", { class: "no-data" }, "Нет данных для отображения.", -1)
        ])))
  ]))
}
}

})