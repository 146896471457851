import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dashboard-column" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "items"
}
const _hoisted_4 = {
  key: 1,
  class: "empty"
}

import UIText from "@/modules/UI-kit/UIText.vue";
import DashboardColumnItem from "@/modules/manager-dashboard/components/DashboardColumnItem.vue";
import DashboardColumnProgressBar from "@/modules/manager-dashboard/components/DashboardColumnProgressBar.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardColumn',
  props: {
  column: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(DashboardColumnProgressBar, {
        "partners-length": __props.column?.stages.length,
        percent: "70%"
      }, null, 8, ["partners-length"]),
      _createVNode(UIText, {
        color: "main-white",
        "font-size": "15px"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(__props.column.status), 1)
        ]),
        _: 1
      })
    ]),
    (__props.column?.stages.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.column.stages, (item, index) => {
            return (_openBlock(), _createBlock(DashboardColumnItem, {
              key: index,
              stage: item,
              class: "item"
            }, null, 8, ["stage"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(UIText, { "font-size": "15px" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Партнеры отсутствуют ")
            ])),
            _: 1
          })
        ]))
  ]))
}
}

})