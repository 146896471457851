import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "description" }
const _hoisted_3 = { class: "date" }

import { useStore } from "vuex";
import { computed } from "vue";
import UIHeader from "@/modules/UI-kit/UIHeader.vue";
import UIText from "@/modules/UI-kit/UIText.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CppModalTaskInformation',
  setup(__props) {

const store = useStore();

const modalData = computed(() => store.state.modalsStore.modalData);

const parsedDate = computed(() => {
  const date = modalData.value.endDate
    ? new Date(modalData.value.endDate)
    : new Date(modalData.value.dueDate);
  return date.toLocaleDateString("ru-RU", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
});

const articles = computed(() => modalData.value?.description.split("\n"));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(UIHeader, {
        "font-size": "32px",
        "font-weight": 800,
        "font-family": "proxima-nova-extrabold",
        "is-uppercase": true
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(modalData.value?.title), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(articles.value, (article, index) => {
        return (_openBlock(), _createBlock(UIText, {
          key: `article-desc-${index}`,
          "font-size": "16px",
          "font-weight": 400,
          "font-family": "proxima-nova-regular",
          tag: "p",
          class: "article"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(article), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(UIText, {
        class: "date-left",
        "font-weight": 700,
        "font-family": "proxima-nova-bold"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" Срок исполнения: ")
        ])),
        _: 1
      }),
      _createVNode(UIText, {
        "font-weight": 400,
        "font-family": "proxima-nova-regular"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(parsedDate.value), 1)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})