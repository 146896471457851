import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

import { computed, ref } from "vue";
import { useFloating, offset as contentOffset, shift } from "@floating-ui/vue";
import type { Placement } from "@floating-ui/vue";

export interface Props {
  text?: string;
  openOnClick?: boolean;
  disabled?: boolean;
  placement?: Placement;
  offset?: number;
  contentWidth?: string;
  teleport?: string;
  teleportDisabled?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseTooltip',
  props: {
    text: {},
    openOnClick: { type: Boolean },
    disabled: { type: Boolean },
    placement: { default: "bottom-start" },
    offset: { default: 0 },
    contentWidth: { default: "328px" },
    teleport: { default: "body" },
    teleportDisabled: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const isHover = ref(false);
const isClicked = ref(false);

const isOpen = computed(() => {
  if (props.disabled) return false;
  return props.openOnClick ? isClicked.value : isHover.value;
});

const reference = ref(null);
const floating = ref(null);

const floatingSettings = computed(() => {
  return useFloating(reference, floating, {
    placement: props.placement,
    middleware: [contentOffset(props.offset), shift()],
  });
});

const contentStyles = computed(() => ({
  maxWidth: props.contentWidth,
  ...floatingSettings.value.floatingStyles.value,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "tooltip-wrapper",
    onMouseenter: _cache[3] || (_cache[3] = ($event: any) => (isHover.value = true)),
    onMouseleave: _cache[4] || (_cache[4] = ($event: any) => (isHover.value = false))
  }, [
    _createElementVNode("div", {
      ref_key: "reference",
      ref: reference,
      class: "activator",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isClicked.value = !isClicked.value))
    }, [
      _renderSlot(_ctx.$slots, "activator", { open: isOpen.value }, () => [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "activator-wrapper" }, null, -1))
      ])
    ], 512),
    (_openBlock(), _createBlock(_Teleport, {
      to: _ctx.teleport,
      disabled: _ctx.teleportDisabled
    }, [
      _createVNode(_Transition, null, {
        default: _withCtx(() => [
          (isOpen.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                ref_key: "floating",
                ref: floating,
                class: "tooltip-content",
                style: _normalizeStyle(contentStyles.value),
                onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (isHover.value = true)),
                onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (isHover.value = false))
              }, [
                _renderSlot(_ctx.$slots, "default", {}, () => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.text), 1)
                ])
              ], 36))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ], 8, ["to", "disabled"]))
  ], 32))
}
}

})