import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconDone',
  props: {
  size: {
    type: Number,
    default: 16,
  },
  color: {
    type: String,
    default: "black",
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    width: __props.size,
    height: __props.size,
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M13.4714 4.19526C13.7317 4.45561 13.7317 4.87772 13.4714 5.13807L6.47136 12.1381C6.21101 12.3984 5.7889 12.3984 5.52855 12.1381L2.86189 9.4714C2.60154 9.21105 2.60154 8.78894 2.86189 8.5286C3.12224 8.26825 3.54435 8.26825 3.8047 8.5286L5.99996 10.7239L12.5286 4.19526C12.7889 3.93491 13.211 3.93491 13.4714 4.19526Z",
      fill: __props.color
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}
}

})