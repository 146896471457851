import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "full-event-list-container" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "list"
}

import { PropType } from "vue";
import { IProgressEvent } from "@/modules/progress/types/progress.types";
import IconDropDown from "@/icons/IconDropDown.vue";
import ProgressEventItem from "@/modules/progress/components/ProgressEventItem.vue";
import EventEmptyState from "@/modules/progress/components/EventEmptyState.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'FullEventList',
  props: {
  fullEventList: {
    type: Array as PropType<Array<IProgressEvent>>,
    required: true,
  },
},
  emits: ["update:fullList"],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const toggleFullList = () => {
  emits("update:fullList");
};

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", { onClick: toggleFullList }, [
        _createVNode(IconDropDown, {
          class: "icon",
          width: 14
        })
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("span", null, " История открытия ", -1)),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "epmty" }, null, -1))
    ]),
    (__props.fullEventList.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.fullEventList, (eventItem, index) => {
            return (_openBlock(), _createBlock(ProgressEventItem, {
              class: "item",
              title: eventItem.title,
              date: eventItem.date,
              subtitle: eventItem.performer,
              key: index
            }, null, 8, ["title", "date", "subtitle"]))
          }), 128))
        ]))
      : (_openBlock(), _createBlock(EventEmptyState, { key: 1 }))
  ]))
}
}

})