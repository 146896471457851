import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "found-stages-container" }
const _hoisted_2 = {
  key: 0,
  class: "loader"
}
const _hoisted_3 = {
  key: 1,
  class: "stages-container"
}
const _hoisted_4 = {
  key: 0,
  class: "stages-wrapper"
}
const _hoisted_5 = { class: "found-stages" }
const _hoisted_6 = {
  key: 1,
  class: "empty-state"
}

import { computed } from "vue";
import { useStore } from "vuex";
import DashboardColumnItem from "@/modules/manager-dashboard/components/DashboardColumnItem.vue";
import UICircleLoader from "@/modules/UI-kit/UICircleLoader.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardFoundStages',
  setup(__props) {

const store = useStore();

const stages = computed(() => store.state.managerDashboardStore.stages);

const searchLoader = computed(
  () => store.state.managerDashboardStore.searchLoader
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (searchLoader.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(UICircleLoader)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (stages.value.length && !searchLoader.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "title-container" }, "Результаты поиска", -1)),
                _createElementVNode("ul", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stages.value, (item, index) => {
                    return (_openBlock(), _createBlock(DashboardColumnItem, {
                      key: index,
                      stage: item,
                      class: "stage-item"
                    }, null, 8, ["stage"]))
                  }), 128))
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, "Совпадений нет"))
        ]))
  ]))
}
}

})