import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/modules/UI-kit/images/attach.svg'


const _hoisted_1 = { class: "preview" }
const _hoisted_2 = {
  key: 0,
  class: "preview__input"
}
const _hoisted_3 = {
  key: 1,
  class: "preview__image"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "preview-icon"
}
const _hoisted_6 = { class: "info" }
const _hoisted_7 = { class: "info__description" }
const _hoisted_8 = {
  key: 0,
  class: "cancel"
}

import { computed, PropType, ref, watch } from "vue";
import { Undefinable, Nullable } from "@/types/types";
import IconClose from "@/icons/IconClose.vue";
import BaseTooltip from "@/components/ui/BaseTooltip.vue";

const TEXT_OVERFLOWED_WIDTH = 218;


export default /*@__PURE__*/_defineComponent({
  __name: 'UIFileUpload',
  props: {
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    default: "Прикрепите файл",
  },
  filePath: {
    type: String,
    required: false,
    default: () => "",
  },
  fileType: {
    type: String,
    required: false,
    default: "image/png",
  },
  existFile: {
    type: Object as PropType<Undefinable<File>>,
    default: undefined,
  },
},
  emits: ["file", "deleteFile"],
  setup(__props, { emit: __emit }) {

const file = ref<Nullable<File>>(null);
const source = ref<string>("");
const currentFileType = ref<Undefinable<string>>("");
const currentFileName = ref<string>("");
const hasThreeDots = ref<HTMLSpanElement | null>(null);
const fileInput = ref<HTMLInputElement | null>(null);

const setFileName = computed<string>(() =>
  currentFileName.value ? currentFileName.value : props.title
);

const isTextOverflowed = computed(() => {
  return {
    textOverflowed:
      Number(hasThreeDots.value?.scrollWidth) > TEXT_OVERFLOWED_WIDTH,
    styleWidth: `${TEXT_OVERFLOWED_WIDTH}px`,
  };
});

const emits = __emit;

const props = __props;

const selectFile = (event: any) => {
  file.value = event.target.files[0];
  currentFileType.value = file?.value?.type;

  if (file.value) {
    source.value = URL.createObjectURL(file.value);
    currentFileName.value = file.value.name;
    emits("file", event.target.files[0]);
  }
};

const triggerFileInput = () => {
  if (fileInput.value) {
    fileInput.value.click(); // Programmatically click the file input
  }
};

const initFile = () => {
  if (props.existFile) {
    file.value = props.existFile;
    source.value = URL.createObjectURL(file.value);
    emits("file", file.value);
  }
};

const deleteFile = () => {
  emits("deleteFile", {
    file: file.value,
    fileName: file.value?.name,
  });
  file.value = null;
  source.value = "";
  currentFileName.value = "";
};

watch(
  () => props.existFile,
  (newFile) => {
    if (newFile) {
      file.value = newFile;
      source.value = URL.createObjectURL(file.value);
      emits("file", file.value);
    }
  },
  { deep: true }
);

watch(
  () => hasThreeDots.value,
  (newValue) => {
    if (newValue) {
      console.log(newValue.innerText); // Access the element's content
    }
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "file-upload",
    onClick: triggerFileInput
  }, [
    _createElementVNode("input", {
      ref_key: "fileInput",
      ref: fileInput,
      class: "file-upload-input",
      type: "file",
      accept: "image/jpeg,image/png,application/pdf,image/x-eps,.doc,.docx",
      onChange: selectFile
    }, null, 544),
    _createElementVNode("div", _hoisted_1, [
      (!__props.filePath && !file.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "attach"
            }, null, -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (currentFileType.value.includes('image'))
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "preview-image-container",
                  src: source.value,
                  alt: "preview",
                  width: "60px",
                  height: "60px"
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true),
            (!currentFileType.value.includes('image'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5))
              : _createCommentVNode("", true)
          ]))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(BaseTooltip, {
        text: setFileName.value,
        disabled: !isTextOverflowed.value.textOverflowed
      }, {
        activator: _withCtx(() => [
          _createElementVNode("span", {
            class: "info__filename",
            style: _normalizeStyle({ width: isTextOverflowed.value.styleWidth }),
            ref_key: "hasThreeDots",
            ref: hasThreeDots
          }, _toDisplayString(setFileName.value), 5)
        ]),
        _: 1
      }, 8, ["text", "disabled"]),
      _createElementVNode("span", _hoisted_7, _toDisplayString(__props.description), 1)
    ]),
    (file.value !== null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("button", {
            onClick: _withModifiers(deleteFile, ["stop"])
          }, [
            _createVNode(IconClose, { size: 16 })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})