import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title" }

import { computed } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardColumnItemTask',
  props: {
  task: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const store = useStore();

const props = __props;

const getTaskTitle = computed(() => props.task.title);

const showModalWindow = () => {
  store.commit("modalsStore/showModal", {
    data: props.task,
    modalType: "cppMainPopup",
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    class: "task-item",
    onClick: showModalWindow
  }, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "circle" }, null, -1)),
    _createElementVNode("span", _hoisted_1, _toDisplayString(getTaskTitle.value), 1)
  ]))
}
}

})