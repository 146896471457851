import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar" }

import { Roles } from "@/enums/enums";
import { Route } from "@/modules/layouts/types/layouts.types";
import { toast } from "vue3-toastify";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { getPartnerOpeningInfo } from "@/modules/layouts/services/layouts.services";
import NavigationContainer from "@/modules/layouts/components/sidebar/NavigationContainer.vue";
import CurrentUserInfo from "@/modules/layouts/components/sidebar/CurrentUserInfo.vue";
import ExpenseStatistic from "@/modules/finances/components/ExpenseStatistic.vue";
import OpenPercentage from "@/modules/layouts/components/sidebar/OpenPercentage.vue";
import CurrentSpotWidget from "@/modules/partner-dashboard/components/CurrentSpotWidget.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarContainer',
  setup(__props) {

const store = useStore();
const route = useRoute();

const openingPercentage = ref<number>(0);

const role = computed(() => store.state.authStore.role);
const currentAmount = computed(() => store.state.financesStore.currentAmount);
const expectedAmount = computed(() => store.state.financesStore.expectedAmount);

const getNavigationArray = computed(() => {
  switch (role.value) {
    case Roles.partner:
      return partnerRoutes;

    case Roles.manager:
      return managerRoutes;

    case Roles.instructor:
      return instructorRoutes;

    default:
      return [];
  }
});

const partnerRoutes: Array<Route> = [
  {
    iconPath: require("@/modules/layouts/images/nav-icons/home-icon.svg"),
    title: "Главная",
    path: "/partner/main",
    component: "IconHome",
    name: "PartnerDashboard",
  },
  // {
  //   iconPath: require("@/modules/layouts/images/nav-icons/tasks-icon.svg"),
  //   title: "Задачи",
  //   path: "/partner/tasks",
  //   component: "IconTask",
  //   name: "PartnerTasksPage",
  // },
  {
    iconPath: require("@/modules/layouts/images/nav-icons/store-icon.svg"),
    title: "Моя кофейня",
    path: "/partner/shop",
    component: "IconCoffeeShop",
    params: { value: "finances" },
    name: "CoffeeShopPage",
  },
  {
    iconPath: require("@/modules/layouts/images/nav-icons/exit-icon.svg"),
    title: "Выход",
    path: "/auth",
    component: "IconExit",
    name: "Auth",
  },
];

const managerRoutes: Array<Route> = [
  {
    iconPath: require("@/modules/layouts/images/nav-icons/home-icon.svg"),
    title: "Главная",
    path: "/manager/main",
    component: "IconHome",
    name: "ManagerDashboard",
  },
  {
    iconPath: require("@/modules/layouts/images/nav-icons/exit-icon.svg"),
    title: "Выход",
    path: "/auth",
    component: "IconExit",
    name: "Auth",
  },
];

const instructorRoutes: Array<Route> = [
  {
    iconPath: require("@/modules/layouts/images/nav-icons/home-icon.svg"),
    title: "Главная",
    path: "/instructor/main",
    component: "IconHome",
    name: "InstructorDashboard",
  },
  {
    iconPath: require("@/modules/layouts/images/nav-icons/exit-icon.svg"),
    title: "Выход",
    path: "/auth",
    name: "Auth",
    component: "IconExit",
  },
];

const acts = computed(() => store.state.partnerProfileStore.acts);

const initPartnerInfo = async () => {
  if (role.value === Roles.partner) {
    try {
      const spotId = localStorage.getItem("spotId") || "";
      const response = await getPartnerOpeningInfo(spotId);
      openingPercentage.value = response.data.openingPercentage;
      await store.dispatch("financesStore/fetchExpensesSum", {
        role: role.value,
        partnerId: route.params?.id,
        spotId,
      });
    } catch (e) {
      console.log(e);
      toast.error("Не удалось получить информацию по партнеру");
    }
  }
};

onMounted(async () => {
  await initPartnerInfo();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    (role.value !== _unref(Roles).executive)
      ? (_openBlock(), _createBlock(CurrentUserInfo, {
          key: 0,
          class: "user-info"
        }))
      : _createCommentVNode("", true),
    (role.value === _unref(Roles).partner && acts.value.length > 1)
      ? (_openBlock(), _createBlock(CurrentSpotWidget, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(NavigationContainer, { routes: getNavigationArray.value }, null, 8, ["routes"]),
    (role.value === _unref(Roles).partner)
      ? (_openBlock(), _createBlock(ExpenseStatistic, {
          key: 2,
          "current-amount": currentAmount.value,
          "expected-amount": expectedAmount.value,
          class: "expenses-static"
        }, null, 8, ["current-amount", "expected-amount"]))
      : _createCommentVNode("", true),
    (role.value === _unref(Roles).partner)
      ? (_openBlock(), _createBlock(OpenPercentage, {
          key: 3,
          percentage: openingPercentage.value,
          class: "open-percentage"
        }, null, 8, ["percentage"]))
      : _createCommentVNode("", true)
  ]))
}
}

})