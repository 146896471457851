import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "outlay-statistic" }
const _hoisted_2 = { class: "outlay-statistic-title" }
const _hoisted_3 = { class: "right" }
const _hoisted_4 = { class: "outlay-expenses-container" }
const _hoisted_5 = { class: "expenses-container-title" }
const _hoisted_6 = { class: "title-text" }
const _hoisted_7 = { class: "expenses-container-item" }
const _hoisted_8 = { class: "item-title" }
const _hoisted_9 = { class: "right" }
const _hoisted_10 = ["onKeydown"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onKeydown"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "subtitle item-title" }
const _hoisted_15 = { class: "expenses-container-sum" }
const _hoisted_16 = { class: "sum-result" }

import { useStore } from "vuex";
import { computed, ref } from "vue";
import {
  updateAmount,
  updateUnitCost,
} from "@/modules/finances/services/finances.services";
import { toast } from "vue3-toastify";
import { useRoute } from "vue-router";
import { Roles } from "@/enums/enums";
import UIEditInput from "@/modules/finances/components/UIEditInput.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'OutlaysStatistic',
  setup(__props) {

const store = useStore();
const route = useRoute();

const spotId = localStorage.getItem("spotId");

const editUnitCost = ref<string>("");
const editAmount = ref<string>("");

const outlays = computed(() => store.state.financesStore.outlays);
const sum = computed(() => store.state.financesStore.sum);
const role = computed(() => store.state.authStore.role);
const outlaySum = computed(() => store.state.financesStore.outlaySum);

const withCurrency = computed(() => {
  return function (value: number) {
    return new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "RUB",
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 0,
    }).format(value);
  };
});

const formatSum = computed(() => {
  return function (value: number) {
    return new Intl.NumberFormat("ru-RU", {
      minimumFractionDigits: 0,
    }).format(value);
  };
});

const updateCost = async (outlayId: number, itemId: number) => {
  try {
    const dispatchParams = {
      role: role.value,
      partnerId: route.params.id,
      spotId,
    };

    const response = await updateUnitCost(itemId, editUnitCost.value);
    store.commit("financesStore/turnOffOutlayEditCost", { outlayId, itemId });
    await store.dispatch("financesStore/fetchOutlays", dispatchParams);
    await store.dispatch("financesStore/fetchEstimate", dispatchParams);
  } catch (e) {
    console.log(e);
    toast.error("Не удалось обновить переменную");
  }
};

const updatedAmount = async (outlayId: number, itemId: number) => {
  try {
    const dispatchParams = {
      role: role.value,
      partnerId: route.params.id,
      spotId,
    };

    const response = await updateAmount(itemId, editAmount.value);
    store.commit("financesStore/turnOffOutlayEditCost", { outlayId, itemId });
    await store.dispatch("financesStore/fetchOutlays", dispatchParams);
    await store.dispatch("financesStore/fetchEstimate", dispatchParams);
  } catch (e) {
    console.log(e);
    toast.error("Не удалось обновить переменную");
  }
};

const toEditUnitCost = ({ itemId, outlayId, value }: any) => {
  if (role.value !== Roles.manager) {
    return;
  }

  editUnitCost.value = `${value}`;
  store.commit("financesStore/setOutlayEditingCost", { itemId, outlayId });
};

const toEditAmount = ({ itemId, outlayId, value }: any) => {
  if (role.value !== Roles.manager) {
    return;
  }

  editAmount.value = `${value}`;
  store.commit("financesStore/setOutlayEditingAmount", { itemId, outlayId });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "left" }, " Затраты ", -1)),
      _createElementVNode("span", _hoisted_3, _toDisplayString(withCurrency.value(outlaySum.value)), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(outlays.value, (outlay) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(outlay.title), 1),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "right" }, [
            _createElementVNode("span", { class: "subtitle" }, " Стоимость (₽) "),
            _createElementVNode("span", { class: "subtitle" }, " Количество "),
            _createElementVNode("span", { class: "subtitle" }, " Сумма (₽) ")
          ], -1))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(outlay.items, (item) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(item.title), 1),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", {
                class: "subtitle unit-cost-container",
                onKeydown: _withKeys(($event: any) => (updateCost(outlay.id, item.id)), ["enter"])
              }, [
                (item.isEditingCost)
                  ? (_openBlock(), _createBlock(UIEditInput, {
                      key: 0,
                      modelValue: editUnitCost.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((editUnitCost).value = $event)),
                      class: "edit-input"
                    }, null, 8, ["modelValue"]))
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      onClick: ($event: any) => (
                toEditUnitCost({
                  itemId: item.id,
                  outlayId: outlay.id,
                  value: item.unitCost,
                })
              ),
                      class: "item-title"
                    }, _toDisplayString(formatSum.value(item.unitCost)), 9, _hoisted_11))
              ], 40, _hoisted_10),
              _createElementVNode("div", {
                class: "subtitle amount-container",
                onKeydown: _withKeys(($event: any) => (updatedAmount(outlay.id, item.id)), ["enter"])
              }, [
                (item.isEditingAmount)
                  ? (_openBlock(), _createBlock(UIEditInput, {
                      key: 0,
                      modelValue: editAmount.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((editAmount).value = $event)),
                      class: "edit-input"
                    }, null, 8, ["modelValue"]))
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      onClick: ($event: any) => (
                toEditAmount({
                  itemId: item.id,
                  outlayId: outlay.id,
                  value: item.amount,
                })
              ),
                      class: "item-title"
                    }, _toDisplayString(item.amount), 9, _hoisted_13))
              ], 40, _hoisted_12),
              _createElementVNode("span", _hoisted_14, _toDisplayString(formatSum.value(item.sum)), 1)
            ])
          ]))
        }), 256)),
        _createElementVNode("div", _hoisted_15, [
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "sum-text" }, " Итого ", -1)),
          _createElementVNode("span", _hoisted_16, _toDisplayString(withCurrency.value(outlay.sum)), 1)
        ])
      ]))
    }), 256))
  ]))
}
}

})