import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "update-equipment-modal" }
const _hoisted_2 = {
  key: 0,
  class: "update-equipment-wrapper"
}
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "back-button-container" }
const _hoisted_5 = { class: "inputs-container" }
const _hoisted_6 = { class: "info-item" }
const _hoisted_7 = { class: "info" }
const _hoisted_8 = { class: "info-item" }
const _hoisted_9 = { class: "info" }
const _hoisted_10 = { class: "info-item" }
const _hoisted_11 = { class: "info" }
const _hoisted_12 = { class: "info-item" }
const _hoisted_13 = { class: "info" }
const _hoisted_14 = {
  key: 0,
  class: "buttons-container"
}
const _hoisted_15 = ["disabled"]
const _hoisted_16 = {
  key: 1,
  class: "button-container"
}
const _hoisted_17 = {
  key: 1,
  class: "submit-deleting"
}
const _hoisted_18 = { class: "submit-deleting-title" }
const _hoisted_19 = { class: "buttons-container" }
const _hoisted_20 = ["disabled"]

import { useStore } from "vuex";
import { Roles } from "@/enums/enums";
import { useRoute } from "vue-router";
import { Nullable, Undefinable } from "@/types/types";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { IDecoration } from "@/modules/decorations/types/decorations.types";
import UIText from "@/modules/UI-kit/UIText.vue";
import IconArrow from "@/icons/IconArrow.vue";
import UIUpdatePhoto from "@/modules/equipments/components/UIUpdatePhoto.vue";
import UIInput from "@/modules/UI-kit/UIInput.vue";
import UIRadioButtons from "@/modules/UI-kit/UIRadioButtons.vue";
import UIButton from "@/modules/UI-kit/UIButton.vue";
import { toast } from "vue3-toastify";
import {
  deleteDecoration,
  updateDecor,
} from "@/modules/decorations/services/decorations.services";

interface IField {
  label: string;
  value: boolean;
  checked: boolean;
}

interface IState {
  file: Nullable<File>;
  title: string;
  price: number | "";
  amount: number | "";
  ordered: Undefinable<boolean>;
  thumbnail: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UpdateDecorationModal',
  setup(__props) {

const store = useStore();
const route = useRoute();

const prevState = reactive<IState>({
  title: "",
  price: "",
  amount: "",
  ordered: false,
  thumbnail: "",
  file: null,
});

const activeState = reactive<IState>({
  title: "",
  price: "",
  amount: "",
  ordered: false,
  thumbnail: "",
  file: null,
});

const fields = ref<IField[]>([
  {
    label: "заказан",
    value: true,
    checked: false,
  },
  {
    label: "не заказан",
    value: false,
    checked: false,
  },
]);

const isInputsVisible = ref<boolean>(false);
const showSubmitDeleting = ref<boolean>(false);

const role = computed(() => store.state.authStore.role);
const currentDecoration = computed<IDecoration>(
  () => store.state.decorationsStore.currentDecoration
);

const setTitle = computed(() => {
  return isInputsVisible.value ? "Редактирование" : "Декор";
});

const setEquipmentName = computed(() => {
  return currentDecoration.value.title.length >= 54
    ? currentDecoration.value.title.slice(0, 35) + "..."
    : currentDecoration.value.title;
});

const getPrice = computed(() => {
  return new Intl.NumberFormat("ru-RU", {
    minimumFractionDigits: 0,
  }).format(currentDecoration.value.price);
});

const setStatus = computed(() => {
  return activeState.ordered ? "заказан" : "не заказан";
});

watch(
  () => fields.value,
  () => {
    const field = fields.value.find((item: IField) => item.checked);
    activeState.ordered = field?.value;
  }
);

const initValues = () => {
  prevState.title = currentDecoration.value.title;
  prevState.price = currentDecoration.value.price;
  prevState.amount = currentDecoration.value.amount;
  prevState.thumbnail = currentDecoration.value.thumbnail;

  activeState.title = currentDecoration.value.title;
  activeState.price = currentDecoration.value.price;
  activeState.amount = currentDecoration.value.amount;
  activeState.thumbnail = currentDecoration.value.thumbnail;

  activeState.ordered = currentDecoration.value.ordered;
  prevState.ordered = currentDecoration.value.ordered;

  fields.value = fields.value.map((item) => {
    if (item.value === currentDecoration.value.ordered) {
      return { ...item, checked: true };
    }

    return { ...item };
  });
};

const clearImage = () => {
  activeState.thumbnail = "";
};

const newFileUpload = (file: File, fileUrl: string) => {
  activeState.file = file;
  activeState.thumbnail = fileUrl;
};
const toggleInputs = () => {
  for (let key of Object.keys(activeState)) {
    // @ts-ignore
    activeState[key] = prevState[key];
  }

  isInputsVisible.value = !isInputsVisible.value;
};

const updateDecoration = async () => {
  try {
    const formData = new FormData();

    formData.append("id", String(currentDecoration.value.id));

    for (let key of Object.keys(activeState)) {
      if (key !== "file" && key !== "thumbnail") {
        // @ts-ignore
        formData.append(key, activeState[key]);
      }
    }

    if (activeState.file) {
      formData.append("file", activeState.file);
    }

    if (!activeState.file && !activeState.thumbnail) {
      // @ts-ignore
      formData.append("file", null);
    }

    const response = await updateDecor(role.value, formData);

    store.commit("modalsStore/closeModal");
    await store.dispatch("decorationsStore/fetchDecorations", {
      partnerId: route?.params?.id,
      role: role.value,
    });
    toast.success("Успешно");
  } catch (e: any) {
    toast.error(e.response.reason);
  }
};

const removeEquipment = async () => {
  try {
    const response = await deleteDecoration(
      role.value,
      currentDecoration.value.id
    );
    store.commit("modalsStore/closeModal");
    await store.dispatch("decorationsStore/fetchDecorations", {
      partnerId: route?.params?.id,
      role: role.value,
    });
    toast.success("Успешно");
  } catch (e: any) {
    console.log(e);
    toast.error(e.response.reason);
  }
};

onMounted(() => {
  initValues();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!showSubmitDeleting.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(UIText, {
              "font-size": "34px",
              "font-family": "proxima-nova-semibold"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(setTitle.value), 1)
              ]),
              _: 1
            })
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "back-button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (isInputsVisible.value = false))
            }, [
              _createVNode(IconArrow, { class: "arrow" }),
              _cache[7] || (_cache[7] = _createTextVNode(" Назад "))
            ])
          ], 512), [
            [_vShow, isInputsVisible.value]
          ]),
          _createVNode(UIUpdatePhoto, {
            "onUpdate:clearImage": clearImage,
            "onUpdate:newFile": newFileUpload,
            "is-editable": isInputsVisible.value,
            "file-url": activeState.thumbnail,
            class: "file-uploader"
          }, null, 8, ["is-editable", "file-url"]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("div", _hoisted_7, [
                _createVNode(UIText, {
                  color: "main-gray",
                  "font-family": "proxima-nova-semibold",
                  class: "info-title",
                  "font-size": "13px"
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode(" Наименование ")
                  ])),
                  _: 1
                }),
                _createVNode(UIText, {
                  "font-weight": 400,
                  "font-family": "proxima-nova-regular",
                  "font-size": "17px"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(setEquipmentName.value), 1)
                  ]),
                  _: 1
                })
              ], 512), [
                [_vShow, !isInputsVisible.value]
              ]),
              _withDirectives(_createVNode(UIInput, {
                modelValue: activeState.title,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((activeState.title) = $event)),
                "place-holder": "Наименование"
              }, null, 8, ["modelValue"]), [
                [_vShow, isInputsVisible.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("div", _hoisted_9, [
                _createVNode(UIText, {
                  color: "main-gray",
                  "font-family": "proxima-nova-semibold",
                  class: "info-title",
                  "font-size": "13px"
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode(" Стоимость (₽) ")
                  ])),
                  _: 1
                }),
                _createVNode(UIText, {
                  "font-weight": 400,
                  "font-family": "proxima-nova-regular",
                  "font-size": "17px"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(getPrice.value), 1)
                  ]),
                  _: 1
                })
              ], 512), [
                [_vShow, !isInputsVisible.value]
              ]),
              _withDirectives(_createVNode(UIInput, {
                modelValue: activeState.price,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((activeState.price) = $event)),
                modelModifiers: { number: true },
                "place-holder": "Наименование"
              }, null, 8, ["modelValue"]), [
                [_vShow, isInputsVisible.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _withDirectives(_createElementVNode("div", _hoisted_11, [
                _createVNode(UIText, {
                  color: "main-gray",
                  "font-family": "proxima-nova-semibold",
                  class: "info-title",
                  "font-size": "13px"
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode(" Количество ")
                  ])),
                  _: 1
                }),
                _createVNode(UIText, {
                  "font-weight": 400,
                  "font-family": "proxima-nova-regular",
                  "font-size": "17px"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(currentDecoration.value.amount), 1)
                  ]),
                  _: 1
                })
              ], 512), [
                [_vShow, !isInputsVisible.value]
              ]),
              _withDirectives(_createVNode(UIInput, {
                modelValue: activeState.amount,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((activeState.amount) = $event)),
                modelModifiers: { number: true },
                "place-holder": "Наименование"
              }, null, 8, ["modelValue"]), [
                [_vShow, isInputsVisible.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _withDirectives(_createElementVNode("div", _hoisted_13, [
                _createVNode(UIText, {
                  color: "main-gray",
                  "font-family": "proxima-nova-semibold",
                  class: "info-title",
                  "font-size": "13px"
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode(" Статус ")
                  ])),
                  _: 1
                }),
                _createVNode(UIText, {
                  "font-weight": 400,
                  "font-family": "proxima-nova-regular",
                  "font-size": "17px"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(setStatus.value), 1)
                  ]),
                  _: 1
                })
              ], 512), [
                [_vShow, !isInputsVisible.value]
              ]),
              _withDirectives(_createVNode(UIRadioButtons, {
                direction: "row",
                name: "2",
                modelValue: fields.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((fields).value = $event))
              }, null, 8, ["modelValue"]), [
                [_vShow, isInputsVisible.value]
              ])
            ])
          ]),
          (isInputsVisible.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(UIButton, {
                  "is-loading": false,
                  "is-disabled": false,
                  onClick: updateDecoration
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode(" Сохранить ")
                  ])),
                  _: 1
                }),
                _createElementVNode("button", {
                  class: "delete-button",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (showSubmitDeleting.value = true)),
                  disabled: role.value === _unref(Roles).manager
                }, " Удалить ", 8, _hoisted_15)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createVNode(UIButton, {
                  onClick: toggleInputs,
                  "is-disabled": role.value !== _unref(Roles).partner && role.value !== _unref(Roles).instructor,
                  class: "edit-button"
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createElementVNode("span", { class: "edit-text" }, "Редактировать", -1)
                  ])),
                  _: 1
                }, 8, ["is-disabled"])
              ]))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createVNode(UIText, {
              "font-size": "34px",
              "font-weight": "proxima-nova-semibold"
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createTextVNode(" Удалить? ")
              ])),
              _: 1
            })
          ]),
          _cache[16] || (_cache[16] = _createElementVNode("div", { class: "submit-deleting-description" }, " Отменить это действие будет невозможно ", -1)),
          _createElementVNode("div", _hoisted_19, [
            _createVNode(UIButton, {
              class: "submit-removing",
              onClick: removeEquipment
            }, {
              default: _withCtx(() => [
                _createVNode(UIText, {
                  "font-family": "proxima-nove-semibold",
                  "font-size": "17px",
                  color: "main-white"
                }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode(" Удалить ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("button", {
              class: "delete-button",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (showSubmitDeleting.value = false)),
              disabled: role.value === _unref(Roles).manager
            }, " Отменить ", 8, _hoisted_20)
          ])
        ]))
  ]))
}
}

})