import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { getMainPagePath } from "@/helpers/get-main-page";

import timelineRouter from "@/modules/timeline/router/timeline.router";
import authRouter from "@/modules/auth/router/auth.router";
import partnerDashboardRouter from "@/modules/partner-dashboard/router/partner-dashboard.router";
import managerDashboardRouter from "@/modules/manager-dashboard/router/manager-dashboard.router";
import instructorDashboardRouter from "@/modules/instructor-dashboard/router/instructor-dashboard.router";
import partnerTasksRouter from "@/modules/partner-tasks/router/partner-tasks.router";
import partnerProfileRoute from "@/modules/partner-profile/router/pratner-profile.router";
import notificationsRouter from "@/modules/notifications/router/notifications.router";
import appealRouter from "@/modules/appeal/router/appeal.router";
import coffeeShopRouter from "@/modules/coffee-shop/router/coffee-shop.router";
import supportRouter from "@/modules/support/router/support.router";
import executiveRouter from "@/modules/executive/router/executive.router";

const routes: Array<RouteRecordRaw> = [
  ...authRouter,
  ...partnerDashboardRouter,
  ...managerDashboardRouter,
  ...instructorDashboardRouter,
  ...partnerTasksRouter,
  ...partnerProfileRoute,
  ...timelineRouter,
  ...notificationsRouter,
  ...appealRouter,
  ...coffeeShopRouter,
  ...supportRouter,
  ...executiveRouter,
  { path: "/:catchAll(.*)", redirect: "/auth" },
];

export function initRouter(store: any) {
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

  router.beforeEach((to, from, next) => {
    const { role: requiredRoles }: any = to.meta;
    const currentRole = store.getters["authStore/getRole"];
    const token = localStorage.getItem("accessToken");
    const isAuthRoute = to.path === "/auth";

    if (isAuthRoute && token) {
      const redirectPath = getMainPagePath(currentRole);
      return next(redirectPath);
    }

    if (requiredRoles) {
      if (!token) {
        return next("/auth");
      }

      if (!requiredRoles.includes(currentRole)) {
        const redirectPath = getMainPagePath(currentRole);
        return next(redirectPath);
      }
    }

    next();
  });

  return router;
}
