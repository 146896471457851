import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "navigation",
  "data-testid": "navigation-container"
}

import { Route } from "@/modules/layouts/types/layouts.types";
import { computed, PropType } from "vue";
import { useStore } from "vuex";
import PartnerNavbar from "@/modules/layouts/components/sidebar/PartnerNavbar.vue";
import { Roles } from "@/enums/enums";
import ManagerNavbar from "@/modules/layouts/components/sidebar/ManagerNavbar.vue";
import InstructorNavbar from "@/modules/layouts/components/sidebar/InstructorNavbar.vue";
import ExecutiveNavbar from "@/modules/layouts/components/sidebar/ExecutiveNavbar.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'NavigationContainer',
  props: {
  routes: {
    type: Array as PropType<Array<Route>>,
    required: true,
  },
},
  setup(__props) {

const store = useStore();

const role = computed(() => store.state.authStore.role);

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (role.value === _unref(Roles).partner)
      ? (_openBlock(), _createBlock(PartnerNavbar, { key: 0 }))
      : _createCommentVNode("", true),
    (role.value === _unref(Roles).manager)
      ? (_openBlock(), _createBlock(ManagerNavbar, { key: 1 }))
      : _createCommentVNode("", true),
    (role.value === _unref(Roles).instructor)
      ? (_openBlock(), _createBlock(InstructorNavbar, { key: 2 }))
      : _createCommentVNode("", true),
    (role.value === _unref(Roles).executive)
      ? (_openBlock(), _createBlock(ExecutiveNavbar, { key: 3 }))
      : _createCommentVNode("", true)
  ]))
}
}

})