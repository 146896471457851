import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/modules/auth/images/welcome-background.png'
import _imports_1 from '@/modules/auth/images/surf-logo.svg'


const _hoisted_1 = { class: "auth-form" }
const _hoisted_2 = { class: "welcome" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "action" }
const _hoisted_6 = { class: "buttons" }

import { toast } from "vue3-toastify";
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getMainPagePath } from "@/helpers/get-main-page";
import UIHeader from "@/modules/UI-kit/UIHeader.vue";
import UIInput from "@/modules/UI-kit/UIInput.vue";
import UIButton from "@/modules/UI-kit/UIButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthForm',
  setup(__props) {

const router = useRouter();
const store = useStore();

const authState = reactive({
  email: "",
  password: "",
});

const isLoading = ref<boolean>(false);

const login = async () => {
  try {
    isLoading.value = true;

    const response = await store.dispatch("authStore/login", {
      email: authState.email,
      password: authState.password,
    });

    if (response?.status === 401 || response?.status === 403) {
      toast.error("Email или пароль неверны");
      return;
    }

    await store.dispatch("authStore/checkUser");
    await router.push(getMainPagePath(response.data.role));
  } catch (e) {
    toast.error("Email или пароль неверны");
  } finally {
    isLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "background-container" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "background",
          class: "background-image"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "logo-container" }, [
          _createElementVNode("img", {
            src: _imports_1,
            alt: "logo"
          })
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(UIHeader, { color: "main-white" }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" ОТКРЫТИЕ СПОТА")
            ])),
            _: 1
          }),
          _createVNode(UIHeader, { color: "main-white" }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" SURF COFFEE")
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("form", {
      class: "main-form",
      onSubmit: _withModifiers(login, ["prevent"])
    }, [
      _createVNode(UIHeader, { "font-size": "17px" }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode(" Авторизуйтесь в своём аккаунте")
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(UIInput, {
          modelValue: authState.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((authState.email) = $event)),
          type: "text",
          "place-holder": "Почта",
          class: "auth-input",
          "data-testid": "auth-form-input-email"
        }, null, 8, ["modelValue"]),
        _createVNode(UIInput, {
          modelValue: authState.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((authState.password) = $event)),
          type: "password",
          "place-holder": "Пароль",
          class: "auth-input",
          "data-testid": "auth-form-input-password"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(UIButton, {
          "is-loading": isLoading.value,
          "data-testid": "auth-form-submit",
          "button-style": "solid"
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode(" Войти ")
          ])),
          _: 1
        }, 8, ["is-loading"])
      ])
    ], 32)
  ]))
}
}

})