import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "income-statistic" }
const _hoisted_2 = { class: "statistic" }

import { computed } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'IncomeStatistic',
  setup(__props) {

const store = useStore();

const estimate = computed(() => store.state.financesStore.estimate);

const formattedPrice = computed(() => {
  return function (price: number) {
    return new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "RUB",
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 0,
    }).format(price);
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(formattedPrice.value(estimate.value.income)), 1),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "title" }, "Доход", -1))
  ]))
}
}

})