import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/modules/layouts/images/sidebar-images/image.png'


const _hoisted_1 = {
  class: "open-percentage",
  "data-testid": "open-percentage"
}
const _hoisted_2 = { class: "percentage" }
const _hoisted_3 = { class: "percentage-text" }

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'OpenPercentage',
  props: {
  percentage: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const withPercentage = computed(() => {
  return function (value: number) {
    return new Intl.NumberFormat("ru-RU", {
      style: "percent",
    }).format(value / 100);
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "percentage-text" }, "открытие", -1)),
      _createElementVNode("span", _hoisted_3, _toDisplayString(withPercentage.value(__props.percentage)), 1)
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "image" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "coffee-shop"
      })
    ], -1))
  ]))
}
}

})