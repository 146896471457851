import $api from "@/api";

export async function getPartnerActiveTasks(spotId: number | string) {
  return $api.get(`/partner/${spotId}/tasks/active`);
}

export async function getPartnerTasks(spotId: number | string) {
  return $api.get(`partner/${spotId}/tasks`);
}

export async function changeTaskStatus(taskId: number, status: string) {
  return $api.patch(`partner/task/${taskId}/${status}`);
}
