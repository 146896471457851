import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "digital-clock" }

import { onBeforeUnmount, onMounted, ref } from "vue";
import { Nullable } from "@/types/types";
import UIText from "@/modules/UI-kit/UIText.vue";
import dayjs from "dayjs";
import "dayjs/locale/ru";


export default /*@__PURE__*/_defineComponent({
  __name: 'UIDigitalClock',
  setup(__props) {

const currentTime = ref<string>("");
const intervalId = ref<Nullable<any>>(null);

const init = () => {
  const updateTime = () => {
    const date = new Date();
    const formattedDate = dayjs(date).locale("ru");
    currentTime.value = `${formattedDate.format("D MMMM H:mm")}`;
  };

  updateTime();
  intervalId.value = setInterval(updateTime, 1000);
};

onMounted(() => {
  init();
});

onBeforeUnmount(() => {
  clearInterval(intervalId.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(UIText, { "font-size": "12px" }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" сегодня ")
      ])),
      _: 1
    }),
    _createVNode(UIText, { "font-size": "28px" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(currentTime.value), 1)
      ]),
      _: 1
    })
  ]))
}
}

})