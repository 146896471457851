import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "decorations-list" }

import type { Document } from "@/modules/documents/types/documents.types";
import DocumentsListItem from "@/modules/documents/components/DocumentsListItem.vue";

export interface Props {
  documents: Document[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentsList',
  props: {
    documents: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.documents, (document) => {
      return (_openBlock(), _createBlock(DocumentsListItem, {
        key: document.id,
        document: document,
        class: "item"
      }, null, 8, ["document"]))
    }), 128))
  ]))
}
}

})