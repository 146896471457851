import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "filters-modal" }

import { computed } from "vue";
import { useStore } from "vuex";
import DashboardFiltersList from "@/modules/manager-dashboard/components/DashboardFiltersList.vue";
import DashboardDateFilter from "@/modules/manager-dashboard/components/DashboardDateFilter.vue";
import UIButton from "@/modules/UI-kit/UIButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardManagerFilters',
  setup(__props) {

const store = useStore();

const filters = computed(() => store.state.managerDashboardStore.filters);
const filtersRequestBody = computed(
  () => store.state.managerDashboardStore.filtersRequestBody
);

const clearFilters = () => {
  store.commit("managerDashboardStore/clearFiltersRequestBody");
};

const onRadioHandler = (event: any) => {
  store.commit("managerDashboardStore/setActiveRadio", event);
};

const setFilters = async () => {
  await store.dispatch(
    "managerDashboardStore/fetchDashboardStages",
    filtersRequestBody.value
  );
  store.commit("modalsStore/closeModal");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", { class: "top" }, [
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "title-text" }, "Фильтр", -1)),
      _createElementVNode("button", {
        class: "clear-button",
        onClick: clearFilters
      }, "сбросить")
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filters.value.radio, (radioList, radioListIndex) => {
      return (_openBlock(), _createBlock(DashboardFiltersList, {
        key: radioListIndex,
        "radio-list": radioList,
        "radio-list-index": +radioListIndex,
        "variable-name": radioList.variableName,
        onRadio: onRadioHandler
      }, null, 8, ["radio-list", "radio-list-index", "variable-name"]))
    }), 128)),
    _createVNode(DashboardDateFilter, {
      date: filtersRequestBody.value.date
    }, null, 8, ["date"]),
    _createVNode(UIButton, {
      class: "button",
      "is-disabled": false,
      onClick: setFilters
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode(" Применить ")
      ])),
      _: 1
    })
  ]))
}
}

})