import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "equipments-tab" }
const _hoisted_2 = {
  key: 1,
  class: "equipments-tab-main"
}
const _hoisted_3 = { class: "button-container" }

import EquipmentsList from "@/modules/equipments/components/EquipmentsList.vue";
import { computed, PropType } from "vue";
import { IEquipment } from "@/modules/equipments/types/equipments.types";
import EmptyEquipments from "@/modules/equipments/components/EmptyEquipments.vue";
import IconPlus from "@/icons/IconPlus.vue";
import UIButton from "@/modules/UI-kit/UIButton.vue";
import { useStore } from "vuex";
import { ModalTypes, Roles } from "@/enums/enums";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquipmentsTab',
  props: {
  equipments: {
    type: Object as PropType<Array<IEquipment>>,
    required: true,
  },
},
  setup(__props) {

const store = useStore();

const props = __props;

const role = computed(() => store.state.authStore.role);

const showWindow = () => {
  if (
    role.value === Roles.partner ||
    role.value === Roles.instructor ||
    role.value === Roles.executive
  ) {
    store.commit("modalsStore/showModal", {
      modalType: ModalTypes.addEquipment,
    });
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!__props.equipments.length)
      ? (_openBlock(), _createBlock(EmptyEquipments, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(EquipmentsList, { equipments: __props.equipments }, null, 8, ["equipments"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(UIButton, {
              class: "button",
              onClick: showWindow
            }, {
              default: _withCtx(() => [
                _createVNode(IconPlus, { class: "plus" }),
                _cache[0] || (_cache[0] = _createTextVNode(" Добавить оборудование "))
              ]),
              _: 1
            })
          ])
        ]))
  ]))
}
}

})