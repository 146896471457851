import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "list-item" }
const _hoisted_2 = { class: "info" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "title-text" }
const _hoisted_5 = { class: "subtitle" }
const _hoisted_6 = { class: "subtitle-text" }
const _hoisted_7 = { class: "date" }
const _hoisted_8 = { class: "date-text" }

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgressEventItem',
  props: {
  subtitle: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  date: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const formattedDate = computed(() => {
  return function (date: string) {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString("ru-RU").split("/").reverse().join(".");
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(__props.title), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(__props.subtitle), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, _toDisplayString(formattedDate.value(__props.date)), 1)
    ])
  ]))
}
}

})