import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "auth-page" }

import AuthForm from "@/modules/auth/components/AuthForm.vue";

// const store = useStore();

// onMounted(async () => {
// await store.dispatch("firebaseStore/firebaseLogout");
// store.commit("authStore/clearAuthState");
// });

export default /*@__PURE__*/_defineComponent({
  __name: 'AuthPage',
  setup(__props) {

// import { useStore } from "vuex";
// import { onMounted } from "vue";

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AuthForm)
  ]))
}
}

})