import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loader-container" }

import lottieModel from "@/plugins/lottie/models/Lottie.json";
import { Vue3Lottie } from "vue3-lottie";


export default /*@__PURE__*/_defineComponent({
  __name: 'UILottieLoader',
  props: {
  width: {
    type: Number,
    default: 80,
  },
  height: {
    type: Number,
    default: 80,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Vue3Lottie), {
      "animation-data": _unref(lottieModel),
      width: __props.width,
      height: __props.height
    }, null, 8, ["animation-data", "width", "height"])
  ]))
}
}

})