import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "empty-tasks" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "subtitle" }
const _hoisted_5 = { class: "subtitle-text" }

import UIText from "@/modules/UI-kit/UIText.vue";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EmptyTasks',
  props: {
  moduleText: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const setTitleText = computed(() => {
  return !props.moduleText
    ? "Задач на сегодня нет"
    : "В данном модуле пока нет задач";
});

const setSubtitleText = computed(() => {
  return !props.moduleText ? "В данном модуле пока нет задач" : "";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(UIText, {
          "font-weight": 600,
          "font-size": "17px",
          color: "main-black"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(setTitleText.value), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(setSubtitleText.value), 1)
      ])
    ])
  ]))
}
}

})