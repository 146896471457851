<template>
  <div class="surf-download-file">
    <div class="preview">
      <div class="preview-icon" v-if="contentType">
        <img
          src="@/modules/UI-kit/images/file-images/upload-icon.png"
          alt="icon"
        />
      </div>
      <img
        v-show="hasImageError"
        :src="fileUrl"
        alt=""
        class="preview-img"
        @error="hasImageError = true"
      />
      <button @click.prevent="downloadFile" class="button">
        <IconDownload />
      </button>
    </div>
    <div class="file-info">
      <span class="file-info__name">{{ title }}</span>
      <span class="file-info__size">{{ description }}</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import IconDownload from "@/icons/IconDownload.vue";

export default {
  name: "SurfDownloadFile",
  components: { IconDownload },
  data() {
    return {
      fileUrl: "",
      contentType: "",
      hasImageError: false,
    };
  },
  props: {
    downloadLink: {
      type: String,
      required: true,
    },
    previewLink: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "Скачать файл",
    },
    fileType: {
      type: String,
      required: false,
      default: "image/png",
    },
  },
  methods: {
    async initPreview() {
      try {
        const imageTypes = ["image/png", "image/jpg"];
        const fetchFile = await axios.get(this.previewLink, {
          responseType: "blob",
        });
        const contentType = fetchFile.headers["content-type"];

        if (imageTypes.includes(contentType)) {
          const blob = new Blob([fetchFile.data], { type: contentType });
          this.fileUrl = URL.createObjectURL(blob);
        } else {
          this.contentType = fetchFile.headers["content-type"];
        }
      } catch (e) {
        console.log(e);
      }
    },
    async downloadFile() {
      try {
        const fetchFile = await axios.get(this.previewLink, {
          responseType: "blob",
        });
        const contentType = fetchFile.headers["content-type"];
        const blob = new Blob([fetchFile.data], { type: contentType });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = this.title;
        link.click();
        link.remove();
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    await this.initPreview();
  },
};
</script>

<style lang="scss" scoped>
.surf-download-file {
  display: flex;
  align-items: center;
  cursor: pointer;

  .preview {
    cursor: pointer;
    background-color: var(--main-light-gray);
    width: 60px;
    height: 60px;
    margin-right: 1rem;
    position: relative;

    .button {
      background-color: rgba(0, 0, 0, 0.5);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .preview-img {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .file-info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__name {
      font-family: var(--proxima-nova-bold);
      font-size: 1rem;
      width: 200px;
      color: var(--main-black);
    }

    &__size {
      font-family: var(--proxima-nova-semibold);
      font-size: 0.75rem;
      color: #949494;
    }
  }
}

.preview-icon {
  border: 1px solid var(--main-light-gray);
  background-color: var(--main-white);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 40px;
    height: 40px;
  }
}
</style>
