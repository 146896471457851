import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "link-container" }
const _hoisted_2 = ["href"]

import { computed } from "vue";
import UIText from "@/modules/UI-kit/UIText.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UILink',
  props: {
  link: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const shortLink = computed(() => {
  return props.link.length > 40
    ? `${props?.link.slice(0, 50)}...`
    : props?.link;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(UIText, {
      class: "text",
      "font-weight": 700,
      "font-family": "proxima-nova-bold"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(__props.description), 1)
      ]),
      _: 1
    }),
    _createElementVNode("a", {
      href: __props.link,
      target: "_blank"
    }, _toDisplayString(shortLink.value), 9, _hoisted_2)
  ]))
}
}

})