import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "finances-tab" }

import {
  IEstimate,
  IExpenses,
  IOutlay,
} from "@/modules/finances/types/finances.types";
import InvestmentProgress from "@/modules/finances/components/investment/InvestmentProgress.vue";
import AveragesStatistic from "@/modules/finances/components/averages/AveragesStatistic.vue";
import IncomeStatistic from "@/modules/finances/components/income/IncomeStatistic.vue";
import NetProfitStatistic from "@/modules/finances/components/net-profit/NetProfitStatistic.vue";
import { PropType } from "vue";
import BreakEvenStatistic from "@/modules/finances/components/break-even/BreakEvenStatistic.vue";
import PaybackStatistics from "@/modules/finances/components/payback/PaybackStatistics.vue";
import BudgetCostItems from "@/modules/finances/components/BudgetCostItems.vue";
import OutlaysStatistic from "@/modules/finances/components/OutlaysStatistic.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'FinancesTab',
  props: {
  estimate: {
    type: Object as PropType<IEstimate>,
    required: true,
  },
  outlays: {
    type: Object as PropType<Array<IOutlay>>,
    required: true,
  },
  expenses: {
    type: Object as PropType<Array<IExpenses>>,
    required: true,
  },
  sum: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InvestmentProgress),
    _createVNode(AveragesStatistic, { class: "item" }),
    _createVNode(IncomeStatistic, { class: "item" }),
    _createVNode(NetProfitStatistic, { class: "item" }),
    _createVNode(BreakEvenStatistic, { class: "item" }),
    _createVNode(PaybackStatistics, { class: "item" }),
    _createVNode(BudgetCostItems, { class: "item" }),
    _createVNode(OutlaysStatistic, { class: "item" })
  ]))
}
}

})