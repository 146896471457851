import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "decorations-tab" }
const _hoisted_2 = {
  key: 1,
  class: "decorations-tab-main"
}

import DocumentsList from "@/modules/documents/components/DocumentsList.vue";
import EmptyDocuments from "@/modules/documents/components/EmptyDocuments.vue";

export interface Props {
  documents: Document[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentsTab',
  props: {
    documents: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.documents.length)
      ? (_openBlock(), _createBlock(EmptyDocuments, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(DocumentsList, { documents: _ctx.documents }, null, 8, ["documents"])
        ]))
  ]))
}
}

})