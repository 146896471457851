import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "subtext" }
const _hoisted_2 = { class: "sub-content" }

import { toast } from "vue3-toastify";
import { useStore } from "vuex";
import { computed, PropType } from "vue";
import { changeTaskStatus } from "@/modules/partner-tasks/services/partner-tasks.services";
import PartnerTaskStatus from "@/modules/partner-tasks/components/PartnerTaskStatus.vue";
import { IPartnerTask } from "@/modules/partner-tasks/types/partner-tasks.types";
import UIText from "@/modules/UI-kit/UIText.vue";
import { TaskStatus } from "@/modules/partner-tasks/enums/partner-tasks.enums";


export default /*@__PURE__*/_defineComponent({
  __name: 'PartnerTask',
  props: {
  task: {
    type: Object as PropType<IPartnerTask>,
    required: true,
  },
},
  setup(__props) {

const store = useStore();

const props = __props;

const setTextWidth = computed(() => (text: string) => {
  return text.length > 90 ? text.slice(0, 90) + "..." : text;
});

const noAccessProperty = computed(() => {
  return props.task?.status === TaskStatus.SUSPENDED ? "no-access" : "";
});

const openTask = () => {
  if (props.task?.status === TaskStatus.ACTIVE) {
    store.commit("modalsStore/showModal", {
      data: props.task,
      modalType: "cppMainPopup",
    });
  }
};

const takeInWork = async () => {
  try {
    await changeTaskStatus(props.task?.taskId, "ACTIVE");
    await store.dispatch("partnerTasksStore/fetchStages");
  } catch (e) {
    toast("Пока нельзя взять задачу в работу");
    console.log(e);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["partner-task", noAccessProperty.value])
  }, [
    _createElementVNode("div", {
      class: "content",
      onClick: openTask
    }, [
      _createVNode(UIText, {
        "font-size": "17px",
        "font-weight": 600,
        color: "main-black",
        class: "title"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(__props.task.title), 1)
        ]),
        _: 1
      }),
      _createElementVNode("span", _hoisted_1, _toDisplayString(setTextWidth.value(__props.task.description)), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(PartnerTaskStatus, {
        status: __props.task?.status,
        "due-date": __props.task.dueDate,
        "task-id": __props.task?.id,
        onClick: takeInWork
      }, null, 8, ["status", "due-date", "task-id"])
    ])
  ], 2))
}
}

})