import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "base-input-label" }
const _hoisted_3 = ["data-maska", "value", "placeholder", "type"]

export interface Props {
  modelValue: string;
  placeholder?: string;
  label?: string;
  mask?: string;
  type?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseInput',
  props: {
    modelValue: {},
    placeholder: { default: "" },
    label: { default: "" },
    mask: { default: "" },
    type: { default: "text" }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

const onUpdateModelValue = (e: InputEvent) => {
  const value = e.target as HTMLInputElement;
  emit("update:modelValue", value);
};

return (_ctx: any,_cache: any) => {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _withDirectives(_createElementVNode("input", {
      "data-maska": _ctx.mask,
      value: _ctx.modelValue,
      placeholder: _ctx.placeholder,
      type: _ctx.type,
      onInput: onUpdateModelValue,
      class: "base-input"
    }, null, 40, _hoisted_3), [
      [_directive_maska]
    ])
  ]))
}
}

})