import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "payback-statistics" }
const _hoisted_2 = {
  class: "payback-statistics-item",
  style: { marginBottom: '0.5rem' }
}
const _hoisted_3 = { class: "payback-statistic-value" }
const _hoisted_4 = { class: "value-value" }
const _hoisted_5 = { class: "payback-statistics-item" }
const _hoisted_6 = { class: "payback-statistic-value" }
const _hoisted_7 = { class: "value-value" }

import { useStore } from "vuex";
import { computed, ref } from "vue";
import { Roles } from "@/enums/enums";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";
import {
  updatePayback1,
  updatePayback2,
} from "@/modules/finances/services/finances.services";
import UIEditInput from "@/modules/finances/components/UIEditInput.vue";
import { useOutsideClick } from "@/composables/useOutsideClick";


export default /*@__PURE__*/_defineComponent({
  __name: 'PaybackStatistics',
  setup(__props) {

const showPayback1Input = ref<boolean>(false);
const showPayback2Input = ref<boolean>(false);
const editPayback1 = ref<string>("");
const editPayback2 = ref<string>("");
const payback1 = ref(null);
const payback2 = ref(null);

const store = useStore();
const route = useRoute();

const spotId = localStorage.getItem("spotId");

const estimate = computed(() => store.state.financesStore.estimate);
const role = computed(() => store.state.authStore.role);

const withCurrency = computed(() => {
  return function (value: number) {
    return new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "RUB",
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 0,
    }).format(value);
  };
});

const fetchEstimate = async () => {
  await store.dispatch("financesStore/fetchEstimate");
};

const showPayback1 = () => {
  if (role.value !== Roles.manager) {
    return;
  }

  showPayback1Input.value = true;
  editPayback1.value = String(...estimate.value.payback_1);
};

const showPayback2 = () => {
  if (role.value !== Roles.manager) {
    return;
  }

  showPayback2Input.value = true;
  editPayback2.value = String(...estimate.value.payback_2);
};

const updateFirst = async () => {
  try {
    const response = await updatePayback1(
      estimate.value.id,
      editPayback1.value
    );
    showPayback1Input.value = false;
    editPayback1.value = "";
    await store.dispatch("financesStore/fetchEstimate", {
      role: role.value,
      partnerId: route.params.id,
      spotId,
    });
  } catch (e) {
    console.log(e);
    toast.error("Не удалось обновить значение");
  }
};

const updateSecond = async () => {
  try {
    const response = await updatePayback2(
      estimate.value.id,
      editPayback2.value
    );
    showPayback2Input.value = false;
    editPayback2.value = "";
    await store.dispatch("financesStore/fetchEstimate", {
      role: role.value,
      partnerId: route.params.id,
      spotId,
    });
  } catch (e) {
    console.log(e);
    toast.error("Не удалось обновить значение");
  }
};

useOutsideClick(payback1, async () => {
  if (showPayback1Input.value) {
    await updateFirst();
  }
});

useOutsideClick(payback2, async () => {
  if (showPayback2Input.value) {
    await updateSecond();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "payback-statistics-title" }, "Окупаемость", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "value-title" }, "Прямые финансовые риски", -1)),
        _createElementVNode("span", _hoisted_4, _toDisplayString(withCurrency.value(estimate.value?.directFinancialRisks)), 1)
      ]),
      _createElementVNode("div", {
        class: "payback-value",
        onKeydown: _withKeys(updateFirst, ["enter"]),
        ref_key: "payback1",
        ref: payback1
      }, [
        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "payback-value-title" }, " Окупаемость ", -1)),
        (showPayback1Input.value)
          ? (_openBlock(), _createBlock(UIEditInput, {
              key: 0,
              class: "edit-input",
              modelValue: editPayback1.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((editPayback1).value = $event))
            }, null, 8, ["modelValue"]))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              onClick: showPayback1,
              class: "payback-value"
            }, _toDisplayString(estimate.value?.payback_1 || "0") + " месяцев", 1))
      ], 544)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "value-title" }, "Бюджет", -1)),
        _createElementVNode("span", _hoisted_7, _toDisplayString(withCurrency.value(estimate.value?.budget)), 1)
      ]),
      _createElementVNode("div", {
        class: "payback-value",
        onKeydown: _withKeys(updateSecond, ["enter"]),
        ref_key: "payback2",
        ref: payback2
      }, [
        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "payback-value-title" }, " Окупаемость ", -1)),
        (showPayback2Input.value)
          ? (_openBlock(), _createBlock(UIEditInput, {
              key: 0,
              class: "edit-input",
              modelValue: editPayback2.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((editPayback2).value = $event))
            }, null, 8, ["modelValue"]))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              onClick: showPayback2,
              class: "payback-value"
            }, _toDisplayString(estimate.value?.payback_2 || "0") + " месяцев", 1))
      ], 544)
    ])
  ]))
}
}

})