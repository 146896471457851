import $api from "@/api";

export async function getNotifications() {
  return $api.get("/firebase/user/notifications");
}

export async function readNotification(id: number) {
  return $api.patch(`firebase/user/notifications/${id}/read`);
}

export async function readAllNotifications() {
  return $api.patch(`firebase/user/notifications/read`);
}

export async function removeAllNotifications() {
  return $api.patch(`firebase/user/notifications/hide`);
}

export async function removeNotification(id: number) {
  return $api.patch(`firebase/user/notifications/${id}/hide`);
}
