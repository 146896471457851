import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "partner-dashboard" }
const _hoisted_2 = {
  key: 0,
  class: "tasks"
}

import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import PartnerDashboardTask from "@/modules/partner-dashboard/components/PartnerDashboardTask.vue";
import MainHeader from "@/modules/layouts/components/headers/MainHeader.vue";
import EmptyTasksDashboard from "@/modules/partner-dashboard/components/EmptyTasksDashboard.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PartnerDashboard',
  setup(__props) {

const store = useStore();

const activeTasks = computed(() => {
  return store.state.partnerTasksStore.activeTasks;
});

onMounted(async () => {
  await store.dispatch("partnerTasksStore/fetchActiveTasks");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MainHeader, { class: "header" }),
    (activeTasks.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeTasks.value, (task) => {
            return (_openBlock(), _createBlock(PartnerDashboardTask, {
              key: task.id,
              task: task,
              class: "task"
            }, null, 8, ["task"]))
          }), 128))
        ]))
      : (_openBlock(), _createBlock(EmptyTasksDashboard, { key: 1 }))
  ]))
}
}

})