import { getPartnerActiveTasks } from "@/modules/partner-tasks/services/partner-tasks.services";
import {
  IPartnerTask,
  IStage,
} from "@/modules/partner-tasks/types/partner-tasks.types";

export default {
  namespaced: true,
  state: {
    stages: [] as Array<IStage>,
    activeTasks: [] as Array<IPartnerTask>,
  },
  mutations: {
    setStages(state: any, stages: any) {
      state.stages = stages;
    },
    setActiveTasks(state: any, stages: any) {
      state.activeTasks = [...stages];
    },
  },
  actions: {
    async fetchActiveTasks(context: any) {
      const spotId = localStorage.getItem("spotId") || "";

      try {
        const response = await getPartnerActiveTasks(spotId);
        context.commit("setActiveTasks", response.data.tasks);
      } catch (e) {
        return e;
      }
    },
  },
};
