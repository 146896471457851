import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "activator" }
const _hoisted_3 = { key: 0 }

import { computed, ref } from "vue";
import UITippy from "@/modules/UI-kit/UITippy.vue";

interface Props {
  text?: string | object;
  disabled?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UITooltip',
  props: {
    text: { default: "" },
    disabled: { type: Boolean, default: false }
  },
  emits: ["show", "hide"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const tooltip = ref();

const tippy = computed(() => tooltip.value?.tippyRef);

const contentClasses = computed(() => {
  return `content auto`;
});

const onShow = () => {
  emit("show");
};
const onHide = () => {
  emit("hide");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(UITippy, {
      ref_key: "tooltip",
      ref: tooltip,
      disabled: _ctx.disabled,
      onShow: onShow,
      onHide: onHide,
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
    }, {
      reference: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(contentClasses.value)
        }, [
          _renderSlot(_ctx.$slots, "content", {}, () => [
            (typeof _ctx.text === 'string')
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.text), 1))
              : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.text), { key: 1 }))
          ])
        ], 2)
      ]),
      _: 3
    }, 8, ["disabled"])
  ]))
}
}

})