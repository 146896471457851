import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "instructor-dashboard-page" }

import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import MainHeader from "@/modules/layouts/components/headers/MainHeader.vue";
import InstructorDashboardAct from "@/modules/instructor-dashboard/components/InstructorDashboardAct.vue";
// import InstructorDashboardFilters from "@/modules/instructor-dashboard/components/InstructorDashboardFilters.vue";
import DashboardSearchbar from "@/modules/manager-dashboard/components/DashboardSearchbar.vue";
import { debounce } from "@/helpers/debounce";


export default /*@__PURE__*/_defineComponent({
  __name: 'InstructorDashboardPage',
  setup(__props) {

const store = useStore();

const acts = computed(() => store.state.instructorDashboardStore.acts);

const searchString = ref<string>("");

const debouncedSearchActs = debounce(async () => {
  await store.dispatch("instructorDashboardStore/searchActs", {
    searchString: searchString.value,
  });
}, 500);

watch(
  () => searchString.value,
  () => {
    debouncedSearchActs();
  }
);

onMounted(async () => {
  await store.dispatch("instructorDashboardStore/fetchActs");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MainHeader, { class: "header" }),
    _createVNode(DashboardSearchbar, {
      modelValue: searchString.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchString).value = $event)),
      class: "search-bar"
    }, null, 8, ["modelValue"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(acts.value, (act) => {
      return (_openBlock(), _createBlock(InstructorDashboardAct, {
        key: act.startDate,
        act: act,
        class: "act"
      }, null, 8, ["act"]))
    }), 128))
  ]))
}
}

})