import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createBlock as _createBlock, withKeys as _withKeys, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "budget-cost-items" }
const _hoisted_2 = { class: "first-items" }
const _hoisted_3 = { class: "first-item" }
const _hoisted_4 = { class: "first-item-content" }
const _hoisted_5 = { class: "first-item-title" }
const _hoisted_6 = { class: "first-item-values" }
const _hoisted_7 = { class: "first-item-plan" }
const _hoisted_8 = { class: "first-item-fact" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 0,
  class: "first-item-second-items"
}
const _hoisted_11 = { class: "second-item" }
const _hoisted_12 = { class: "second-item-title" }
const _hoisted_13 = { class: "second-item-values" }
const _hoisted_14 = ["onKeydown"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "second-item-fact" }
const _hoisted_17 = { class: "cost-items-sum" }
const _hoisted_18 = { class: "title-values" }
const _hoisted_19 = { class: "plan-title" }
const _hoisted_20 = { class: "fact-title" }

import { useStore } from "vuex";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { Roles } from "@/enums/enums";
import IconArrow from "@/icons/IconArrow.vue";
import IconAbout from "@/icons/IconAbout.vue";
import UIEditInput from "@/modules/finances/components/UIEditInput.vue";
import { updateExpense } from "@/modules/finances/services/finances.services";
import { toast } from "vue3-toastify";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import DownloadIcon from "@/modules/UI-kit/icons/DownloadIcon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BudgetCostItems',
  setup(__props) {

const store = useStore();
const route = useRoute();

const currentAmount = ref<string>("");

const formatSum = computed(() => {
  return function (value: number) {
    return new Intl.NumberFormat("ru-RU", {
      minimumFractionDigits: 0,
    }).format(value);
  };
});

const rotate = computed(() => {
  return function (value: boolean) {
    return value ? "rotated" : "";
  };
});

const withCurrency = computed(() => {
  return function (value: number) {
    return new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "RUB",
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 0,
    }).format(value);
  };
});

const secondItemPadding = computed(() => {
  return function (isVisible: boolean) {
    return isVisible ? "second-item-padding-bottom" : "";
  };
});

const role = computed(() => store.state.authStore.role);
const expenses = computed(() => store.state.financesStore.expenses);
const expensesTest = computed(() => store.state.financesStore.expenses);
const currentAmountState = computed(
  () => store.state.financesStore.currentAmount
);
const expectedAmount = computed(() => store.state.financesStore.expectedAmount);

const setVisibleMainExpense = (params: any) => {
  store.commit("financesStore/setVisibleMainExpense", params);
};

const setVisibleMainExpenseItem = (params: any) => {
  store.commit("financesStore/setVisibleMainExpenseItem", params);
};

const setExpensesEditing = (params: any) => {
  store.commit("financesStore/setExpensesEditing", params);
};

const setExpensesSecondItemEditing = (params: any) => {
  store.commit("financesStore/setExpensesSecondItemEditing", params);
};

const turnOffExpensesEditing = () => {
  store.commit("financesStore/turnOffExpensesEditing");
};

const setSecondItemEdit = (
  mainExpensesId: number,
  secondItemId: number,
  isEditing = true
) => {
  if (role.value !== Roles.manager) {
    return;
  }

  turnOffExpensesEditing();
  setExpensesSecondItemEditing({
    id: mainExpensesId,
    itemId: secondItemId,
    isEditing,
  });
};

const setExpensesEdit = (
  mainExpensesId: number,
  secondItemId: number,
  thirdItemId: number
) => {
  if (role.value !== Roles.manager) {
    return;
  }

  turnOffExpensesEditing();
  setExpensesEditing({
    id: mainExpensesId,
    itemId: secondItemId,
    subItemId: thirdItemId,
    isEditing: true,
  });
};

const updateExpenseItem = async (
  mainExpensesId: number,
  secondItemId: number,
  thirdItemId: number
) => {
  try {
    const response = await updateExpense(thirdItemId, +currentAmount.value);
    setExpensesSecondItemEditing({
      id: mainExpensesId,
      itemId: secondItemId,
      subItemId: thirdItemId,
      isEditing: false,
    });
    currentAmount.value = "";
    await store.dispatch("financesStore/refetchExpenses", {
      role: role.value,
      partnerId: route.params.id,
      spotId: localStorage.getItem("spotId"),
    });
    await store.dispatch("financesStore/fetchExpensesSum", {
      role: role.value,
      partnerId: route.params.id,
      spotId: localStorage.getItem("spotId"),
    });
  } catch (e) {
    toast.error("Не удалось обновить значение");
  }
};

const updateExpenses = async (mainExpensesId: number, secondItemId: number) => {
  try {
    const response = await updateExpense(secondItemId, +currentAmount.value);
    setExpensesSecondItemEditing({
      id: mainExpensesId,
      itemId: secondItemId,
      isEditing: false,
    });
    currentAmount.value = "";
    await store.dispatch("financesStore/refetchExpenses", {
      role: role.value,
      partnerId: route.params.id,
      spotId: localStorage.getItem("spotId"),
    });
    await store.dispatch("financesStore/fetchExpensesSum", {
      role: role.value,
      partnerId: route.params.id,
      spotId: localStorage.getItem("spotId"),
    });
  } catch (e) {
    toast.error("Не удалось обновить значение");
  }
};

const generateExcelFile = async () => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Статьи затрат бюджета");
  worksheet.addRow(["Статьи затрат", "План", "Факт", "Отклонение"]).font = {
    bold: true,
  };

  for (const item of expenses.value) {
    let deviation =
      ((item.expectedAmount - item.currentAmount) / item.currentAmount) * 100;

    if (!deviation || isNaN(deviation) || !isFinite(deviation)) deviation = 100;

    const buff = [
      item.title,
      item.expectedAmount,
      item.currentAmount,
      `${deviation.toFixed(2)}%`,
    ];
    const buffRow = worksheet.addRow(buff);
    buffRow.font = { bold: true };

    for (const element of item.items) {
      let deviation =
        ((element.expectedAmount - element.currentAmount) /
          element.currentAmount) *
        100;

      if (!deviation || isNaN(deviation) || !isFinite(deviation))
        deviation = 100;

      const temp = [
        element.title,
        element.expectedAmount,
        element.currentAmount,
        `${deviation.toFixed(2)}%`,
      ];

      worksheet.addRow(temp);
    }
  }

  worksheet.getColumn(1).width = 60;
  worksheet.getColumn(2).width = 30;
  worksheet.getColumn(3).width = 20;
  worksheet.getColumn(4).width = 20;

  const buff = await workbook.xlsx.writeBuffer();
  saveAs(
    new Blob([buff], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
    "Статьи затрат бюджета.xlsx"
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (role.value === _unref(Roles).instructor)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "download-button",
          onClick: generateExcelFile
        }, [
          _createVNode(DownloadIcon, { class: "download-icon" })
        ]))
      : _createCommentVNode("", true),
    _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"cost-items-title\" data-v-4d2265d0><span class=\"title-text\" data-v-4d2265d0>Статьи затрат бюджета</span><div class=\"title-values\" data-v-4d2265d0><span class=\"plan-title\" data-v-4d2265d0>План (₽)</span><span class=\"fact-title\" data-v-4d2265d0>Факт (₽)</span></div></div>", 1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(expenses.value, (mainExpenses) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createTextVNode(_toDisplayString(mainExpenses?.title) + " ", 1),
              _createVNode(IconAbout, { class: "icon-about" })
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(formatSum.value(mainExpenses?.expectedAmount)), 1),
              _createElementVNode("span", _hoisted_8, _toDisplayString(formatSum.value(mainExpenses?.currentAmount)), 1),
              _createElementVNode("button", {
                class: "main-arrow-button",
                onClick: ($event: any) => (setVisibleMainExpense({ id: mainExpenses.id }))
              }, [
                _createVNode(IconArrow, {
                  class: _normalizeClass(["arrow", rotate.value(mainExpenses.isVisible)]),
                  size: 20,
                  color: "#1B1A16"
                }, null, 8, ["class"])
              ], 8, _hoisted_9)
            ])
          ]),
          (mainExpenses.isVisible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mainExpenses.items, (secondItem) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["second-item-content", secondItemPadding.value(secondItem.isVisible)])
                    }, [
                      _createElementVNode("div", _hoisted_12, _toDisplayString(secondItem?.title), 1),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", {
                          onKeydown: _withKeys(($event: any) => (
                    updateExpenses(mainExpenses.id, secondItem.id)
                  ), ["enter"])
                        }, [
                          (!secondItem?.isEditing)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                class: "second-item-plan",
                                onClick: _withModifiers(($event: any) => (
                      setSecondItemEdit(mainExpenses.id, secondItem.id)
                    ), ["stop"])
                              }, _toDisplayString(formatSum.value(secondItem?.expectedAmount)), 9, _hoisted_15))
                            : (_openBlock(), _createBlock(UIEditInput, {
                                key: 1,
                                modelValue: currentAmount.value,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentAmount).value = $event))
                              }, null, 8, ["modelValue"]))
                        ], 40, _hoisted_14),
                        _createElementVNode("span", _hoisted_16, _toDisplayString(formatSum.value(secondItem?.currentAmount)), 1)
                      ])
                    ], 2)
                  ]))
                }), 256))
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 256))
    ]),
    _createElementVNode("div", _hoisted_17, [
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "title-text" }, "Итого", -1)),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("span", _hoisted_19, _toDisplayString(formatSum.value(expectedAmount.value)), 1),
        _createElementVNode("span", _hoisted_20, _toDisplayString(formatSum.value(currentAmountState.value)), 1)
      ])
    ])
  ]))
}
}

})