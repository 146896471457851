import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'IconSearch',
  props: {
  color: {
    type: String,
    default: "main-gray",
  },
  size: {
    type: Number,
    default: 24,
  },
},
  setup(__props) {

const props = __props;

const setColor = computed(() => `var(--${props.color})`);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M21.6339 19.9638L16.158 14.4857C17.1395 13.1469 17.6654 11.5284 17.658 9.86832C17.6393 5.53505 14.1345 2.02511 9.80123 2.00008C7.72753 1.9907 5.7362 2.81106 4.27087 4.27841C2.80553 5.74575 1.98791 7.7382 2.00014 9.81189C2.01886 14.1456 5.52402 17.6558 9.85766 17.6808C11.5245 17.6881 13.1489 17.1561 14.4886 16.1643L14.4943 16.1601L19.9653 21.6339C20.2611 21.9442 20.7019 22.0698 21.1168 21.9621C21.5317 21.8543 21.8557 21.5301 21.9631 21.115C22.0705 20.7 21.9445 20.2593 21.6339 19.9638ZM9.85195 16.1122C6.38523 16.0923 3.58114 13.2843 3.56593 9.8176C3.55657 8.15887 4.21072 6.56523 5.38277 5.39144C6.55482 4.21765 8.14748 3.56112 9.80623 3.56801C13.2729 3.58796 16.077 6.39586 16.0922 9.86261C16.1016 11.5213 15.4475 13.115 14.2754 14.2888C13.1034 15.4626 11.5107 16.1191 9.85195 16.1122Z",
      fill: setColor.value
    }, null, 8, _hoisted_2)
  ]))
}
}

})