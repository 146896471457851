import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "appeal-page no-scroll" }

import { useStore } from "vuex";
import { onMounted } from "vue";
import AppealForm from "@/modules/appeal/components/AppealForm.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppealPage',
  setup(__props) {

const store = useStore();

onMounted(() => {
  store.commit("authStore/clearAuthState");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppealForm)
  ]))
}
}

})