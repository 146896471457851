import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Tippy } from "vue-tippy";
import { onMounted, ref, watch } from "vue";

interface Props {
  disabled?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UITippy',
  props: {
    disabled: { type: Boolean, default: false }
  },
  emits: [
  "afterUpdate",
  "beforeUpdate",
  "clickOutside",
  "create",
  "destroy",
  "hidden",
  "hide",
  "mount",
  "show",
  "shown",
  "trigger",
  "untrigger",
],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const tippyRef = ref();

const props = __props;

const emit = __emit;

const onAfterUpdate = () => emit("afterUpdate");
const onBeforeUpdate = () => emit("beforeUpdate");
const onClickOutside = () => emit("clickOutside");
const onCreate = () => emit("create");
const onDestroy = () => emit("destroy");
const onHidden = () => emit("hidden");
const onHide = () => emit("hide");
const onMount = () => emit("mount");
const onShow = () => emit("show");
const onShown = () => emit("shown");
const onTrigger = () => emit("trigger");
const onUntrigger = () => emit("untrigger");

onMounted(() => {
  if (props.disabled) {
    tippyRef.value?.disable();
  }
});

watch(
  () => props.disabled,
  (newVal: boolean) => {
    if (newVal) {
      tippyRef.value?.disable();
    } else {
      tippyRef.value?.enable();
    }
  }
);

__expose({
  tippyRef,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Tippy), {
    ref_key: "tippyRef",
    ref: tippyRef,
    "max-width": "none",
    delay: 0,
    onAfterUpdate: onAfterUpdate,
    onBeforeUpdate: onBeforeUpdate,
    onClickOutside: onClickOutside,
    onCreate: onCreate,
    onDestroy: onDestroy,
    onHidden: onHidden,
    onHide: onHide,
    onMount: onMount,
    onShow: onShow,
    onShown: onShown,
    onTrigger: onTrigger,
    onUntrigger: onUntrigger
  }, {
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "reference")
    ]),
    _: 3
  }, 512))
}
}

})