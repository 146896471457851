import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "loader" }

import { computed } from "vue";
import UILottieLoader from "@/modules/UI-kit/UILottieLoader.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UIButton',
  props: {
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  buttonStyle: {
    type: String,
    default: "solid",
  },
},
  emits: ["click"],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const props = __props;

const disableButton = computed(() => {
  return props.isDisabled || props.isLoading;
});

const setButtonStyles = computed(() => {
  return {
    solid: props.buttonStyle === "solid",
    ghost: props.buttonStyle === "ghost",
    "disabled-solid": props.buttonStyle === "solid" && disableButton.value,
    "disabled-ghost": props.buttonStyle === "ghost" && disableButton.value,
  };
});

const onClickHandler = () => {
  emits("click");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    onClick: onClickHandler,
    class: _normalizeClass(["button", setButtonStyles.value]),
    disabled: disableButton.value
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["content", { invisible: __props.isLoading }])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    _withDirectives(_createElementVNode("span", _hoisted_2, [
      _createVNode(UILottieLoader, {
        width: 70,
        height: 70
      })
    ], 512), [
      [_vShow, __props.isLoading]
    ])
  ], 10, _hoisted_1))
}
}

})