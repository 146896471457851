import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconPlus',
  props: {
  color: {
    type: String,
    default: "white",
  },
  size: {
    type: Number,
    default: 12,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    width: __props.size,
    height: __props.size,
    viewBox: "0 0 13 12",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M7.25 0.75C7.25 0.335786 6.91421 0 6.5 0C6.08579 0 5.75 0.335786 5.75 0.75V5.25H1.25C0.835786 5.25 0.5 5.58579 0.5 6C0.5 6.41421 0.835786 6.75 1.25 6.75H5.75V11.25C5.75 11.6642 6.08579 12 6.5 12C6.91421 12 7.25 11.6642 7.25 11.25V6.75H11.75C12.1642 6.75 12.5 6.41421 12.5 6C12.5 5.58579 12.1642 5.25 11.75 5.25H7.25V0.75Z",
      fill: __props.color
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}
}

})