import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconDownload',
  props: {
  width: {
    type: Number,
    default: 16,
  },
  height: {
    type: Number,
    default: 19,
  },
  color: {
    type: String,
    default: "white",
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    width: __props.width,
    height: __props.height,
    viewBox: "0 0 16 19",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M15 11C15.5523 11 16 11.4477 16 12V16C16 17.6569 14.6569 19 13 19H3C1.34315 19 0 17.6569 0 16V12C0 11.4477 0.447715 11 1 11C1.55228 11 2 11.4477 2 12V16C2 16.5523 2.44772 17 3 17H13C13.5523 17 14 16.5523 14 16V12C14 11.4477 14.4477 11 15 11Z",
      fill: __props.color
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M8 13.5L8.01935 13.4998C8.04268 13.4994 8.066 13.4981 8.08925 13.496L8 13.5L7.98164 13.4998C7.95798 13.4994 7.93434 13.4981 7.90997 13.496C7.89015 13.4942 7.8705 13.4919 7.85153 13.489C7.83405 13.4864 7.81662 13.4833 7.79921 13.4798C7.77725 13.4754 7.75556 13.4702 7.73401 13.4642C7.71854 13.4599 7.70315 13.4553 7.68826 13.4505C7.6682 13.4439 7.64842 13.4367 7.62866 13.4288C7.6112 13.4218 7.5939 13.4143 7.5769 13.4063C7.55611 13.3966 7.53571 13.3862 7.51594 13.3753C7.50361 13.3685 7.49139 13.3614 7.47934 13.3539C7.45372 13.3383 7.42887 13.3215 7.40469 13.3037C7.36567 13.2747 7.32829 13.2425 7.29289 13.2071L7.37455 13.2803L7.38325 13.2872L7.29289 13.2071C7.32829 13.2425 7.36567 13.2747 7.40469 13.3037C7.42887 13.3215 7.45372 13.3383 7.47934 13.3539C7.49139 13.3614 7.50361 13.3685 7.51594 13.3753C7.53571 13.3862 7.55611 13.3966 7.5769 13.4063C7.5939 13.4143 7.6112 13.4218 7.62866 13.4288C7.64842 13.4367 7.6682 13.4439 7.68826 13.4505C7.70315 13.4553 7.71854 13.4599 7.73401 13.4642C7.75556 13.4702 7.77725 13.4754 7.79921 13.4798C7.81662 13.4833 7.83405 13.4864 7.85153 13.489C7.8705 13.4919 7.89015 13.4942 7.90997 13.496C7.93434 13.4981 7.95798 13.4994 7.98164 13.4998L8 13.5Z",
      fill: __props.color
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M8.01935 13.4998L8 13.5C8.05062 13.5 8.10036 13.4962 8.14896 13.489C8.16595 13.4864 8.18337 13.4833 8.20073 13.4798C8.22275 13.4754 8.24444 13.4702 8.26584 13.4643C8.28146 13.4599 8.29685 13.4553 8.31214 13.4503C8.3318 13.4439 8.35158 13.4367 8.37106 13.4289C8.3888 13.4218 8.4061 13.4143 8.42322 13.4063C8.44389 13.3966 8.46429 13.3862 8.48428 13.3751C8.49639 13.3685 8.50861 13.3614 8.52071 13.354C8.54628 13.3383 8.57113 13.3215 8.59516 13.3037C8.6026 13.2982 8.60984 13.2927 8.61702 13.2871L8.62545 13.2803L8.70711 13.2071L13.2071 8.70711C13.5976 8.31658 13.5976 7.68342 13.2071 7.29289C12.8166 6.90237 12.1834 6.90237 11.7929 7.29289L9 10.086V1C9 0.447716 8.55228 0 8 0C7.44772 0 7 0.447716 7 1V10.086L4.20711 7.29289C3.81658 6.90237 3.18342 6.90237 2.79289 7.29289C2.40237 7.68342 2.40237 8.31658 2.79289 8.70711L7.29289 13.2071L7.38325 13.2872L7.37455 13.2803L7.29289 13.2071C7.32829 13.2425 7.36567 13.2747 7.40469 13.3037C7.42887 13.3215 7.45372 13.3383 7.47934 13.3539C7.49139 13.3614 7.50361 13.3685 7.51594 13.3753C7.53571 13.3862 7.55611 13.3966 7.5769 13.4063C7.5939 13.4143 7.6112 13.4218 7.62866 13.4288C7.64842 13.4367 7.6682 13.4439 7.68826 13.4505C7.70315 13.4553 7.71854 13.4599 7.73401 13.4642C7.75556 13.4702 7.77725 13.4754 7.79921 13.4798C7.81662 13.4833 7.83405 13.4864 7.85153 13.489C7.8705 13.4919 7.89015 13.4942 7.90997 13.496C7.93434 13.4981 7.95798 13.4994 7.98164 13.4998L8 13.5L8.08925 13.496C8.066 13.4981 8.04268 13.4994 8.01935 13.4998Z",
      fill: __props.color
    }, null, 8, _hoisted_4)
  ], 8, _hoisted_1))
}
}

})