import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dashboard-actions" }

import { useStore } from "vuex";
import DashboardSearchbar from "@/modules/manager-dashboard/components/DashboardSearchbar.vue";
import DashboardFiltersBar from "@/modules/manager-dashboard/components/DashboardFiltersBar.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardActions',
  props: {
  modelValue: {
    type: String,
    required: true,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const store = useStore();

const emits = __emit;

const props = __props;

const onInputHandler = (event: string) => {
  emits("update:modelValue", event);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(DashboardSearchbar, {
      class: "search-bar",
      "model-value": __props.modelValue,
      "onUpdate:modelValue": onInputHandler
    }, null, 8, ["model-value"]),
    _createVNode(DashboardFiltersBar, { class: "filters" })
  ]))
}
}

})