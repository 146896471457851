import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "stages" }
const _hoisted_2 = { class: "list" }

import { computed, PropType } from "vue";
import { IProgressStage } from "@/modules/progress/types/progress.types";
import ProgressStageItem from "@/modules/progress/components/ProgressStageItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgressStageList',
  props: {
  stages: {
    type: Array as PropType<Array<IProgressStage>>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const isDone = computed(() => (percent: number) => {
  return percent === 100 ? "done" : "active";
});

const convertDate = computed(() => {
  return function (date: string) {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString("ru-RU").split("/").reverse().join(".");
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "title" }, [
      _createElementVNode("span", null, "этапы")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.stages, (stage, index) => {
        return (_openBlock(), _createBlock(ProgressStageItem, {
          key: index,
          title: stage.name,
          "date-percentage": stage.datePercentage,
          "stage-percentage": stage.stagePercentage,
          progress: isDone.value(stage.datePercentage),
          "end-date": convertDate.value(stage.endDate),
          "start-date": convertDate.value(stage.startDate)
        }, null, 8, ["title", "date-percentage", "stage-percentage", "progress", "end-date", "start-date"]))
      }), 128))
    ])
  ]))
}
}

})