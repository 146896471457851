import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "left" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "empty-image-state"
}
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "title-text-container" }
const _hoisted_6 = { class: "title-text" }

import { computed, ref } from "vue";
import axios from "axios";
import dayjs from "dayjs";
import type { Document } from "@/modules/documents/types/documents.types";
import IconAvatarEmpty from "@/icons/IconAvatarEmpty.vue";

export interface Props {
  document: Document;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DocumentsListItem',
  props: {
    document: {}
  },
  setup(__props: any) {

const props = __props;

const error = ref(false);

const showNoImage = computed(() => error.value || !props.document.requestUrl);

const formattedDate = computed(() => {
  return dayjs(props.document.createDate).format("DD.MM.YYYY");
});

const downloadFile = async () => {
  try {
    const fetchFile = await axios.get(props.document.requestUrl, {
      responseType: "blob",
    });
    const contentType = fetchFile.headers["content-type"];
    const blob = new Blob([fetchFile.data], { type: contentType });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = props.document.name;
    link.click();
    link.remove();
  } catch (e) {
    console.log(e);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    class: "decor-list-item",
    onClick: downloadFile
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!showNoImage.value)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: `${_ctx.document.requestUrl}?v=${Date.now()}`,
            alt: "preview",
            ref: "preview",
            class: "preview-image",
            onError: _cache[0] || (_cache[0] = ($event: any) => (error.value = true))
          }, null, 40, _hoisted_2))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(IconAvatarEmpty)
          ])),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.document.name), 1),
          _createElementVNode("p", null, _toDisplayString(formattedDate.value), 1)
        ])
      ])
    ])
  ]))
}
}

})