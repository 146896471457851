import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "column-item" }
const _hoisted_2 = { class: "top" }
const _hoisted_3 = { class: "item-title" }
const _hoisted_4 = { class: "partner-name" }
const _hoisted_5 = {
  key: 0,
  class: "bottom"
}
const _hoisted_6 = { class: "percent" }
const _hoisted_7 = { class: "progress-bar" }
const _hoisted_8 = { class: "total-container" }
const _hoisted_9 = { class: "current" }
const _hoisted_10 = { class: "total" }
const _hoisted_11 = {
  key: 1,
  class: "tasks-list"
}
const _hoisted_12 = { class: "list" }

import { computed, PropType } from "vue";
import { useRouter } from "vue-router";
import DashboardColumnItemTask from "@/modules/manager-dashboard/components/DashboardColumnItemTask.vue";
import UIText from "@/modules/UI-kit/UIText.vue";
import UITooltip from "@/modules/UI-kit/UITooltip.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardColumnItem',
  props: {
  stage: {
    type: Object as PropType<any>,
    required: true,
  },
},
  setup(__props) {

_useCssVars(_ctx => ({
  "61018e35": (isPointer.value)
}))

const props = __props;

const router = useRouter();

// computed properties
const isPointer = computed(() => (props.stage.partner?.id ? "pointer" : ""));

const getCompletedTaskCount = computed(() => {
  return props.stage.completedTasks;
});

const getAllTaskCount = computed(() => {
  return `/${props.stage.allTasks}`;
});

const validateName = computed(() => {
  const fullName = `${props.stage?.partner?.name} ${props.stage?.partner?.surname}`;

  const formatted =
    fullName.length > 18 ? fullName.slice(0, 17) + "..." : fullName;

  return {
    formatted,
    fullName,
  };
});

const getStagePercentage = computed(() => {
  return `${props.stage.stagePercentage}%`;
});

const checkPercentage = computed(() => {
  return props.stage?.stagePercentage === undefined;
});

const hideTasksList = computed(() => {
  return props.stage.tasks.length;
});

const getStartDate = computed(() => {
  return `начато ${new Date(props.stage?.startDate).toLocaleDateString(
    "ru-RU"
  )}`;
});

const setStatusClass = computed(() => {
  if (!props.stage?.subStatus) {
    return;
  }

  const subStatus = props.stage?.subStatus?.toLowerCase();

  switch (subStatus) {
    case "новое":
      return "new";

    case "просрочено":
      return "old";

    case "почти просрочено":
      return "old";

    default:
      return "new";
  }
});

const isOverflowed = computed(() => {
  return !validateName.value.formatted.includes("...");
});

//methods
const pushToPartner = async () => {
  if (!props.stage.partner?.id) {
    return;
  }

  localStorage.setItem("spotId", props.stage?.partnerActId);

  await router.push({
    path: `/profile/${props.stage.partner?.id}/finances`,
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (__props.stage.startDate)
          ? (_openBlock(), _createBlock(UIText, {
              key: 0,
              "font-weight": 600,
              "font-size": "11px",
              color: "main-gray"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(getStartDate.value), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(UITooltip, {
            disabled: isOverflowed.value,
            text: validateName.value.fullName
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: "full-name-text",
                onClick: pushToPartner
              }, _toDisplayString(validateName.value.formatted), 1)
            ]),
            _: 1
          }, 8, ["disabled", "text"])
        ])
      ]),
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass(["status", setStatusClass.value])
      }, _toDisplayString(__props.stage?.subStatus?.toLowerCase()), 3), [
        [_vShow, __props.stage?.subStatus]
      ])
    ]),
    (!checkPercentage.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(UIText, {
              "font-size": "11px",
              "font-weight": 600
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(__props.stage.stagePercentage + "%"), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: "active",
              style: _normalizeStyle({ width: getStagePercentage.value })
            }, null, 4)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(getCompletedTaskCount.value), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(getAllTaskCount.value), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (hideTasksList.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("ul", _hoisted_12, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.stage.tasks, (task) => {
              return (_openBlock(), _createBlock(DashboardColumnItemTask, {
                key: task?.id,
                task: task,
                class: "task"
              }, null, 8, ["task"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})