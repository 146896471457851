import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "secondary-header" }
const _hoisted_2 = { class: "back-button-container" }
const _hoisted_3 = { class: "title" }

import IconDropDown from "@/icons/IconDropDown.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SecondaryHeader',
  emits: ["update:pushBack"],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const pushBack = () => {
  emits("update:pushBack");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "button",
        onClick: pushBack
      }, [
        _createVNode(IconDropDown, {
          color: "#aaaaaa",
          width: 14,
          height: 7,
          class: "arrow"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "pageTitle")
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "settings-button-container" }, [
      _createElementVNode("span", { class: "empty" })
    ], -1))
  ]))
}
}

})