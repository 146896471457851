import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tasks-tabs" }
const _hoisted_2 = ["onClick"]

import { computed, ref } from "vue";
import UIText from "@/modules/UI-kit/UIText.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PartnerTasksTabs',
  emits: ["update:filter-tasks"],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const tabs = ref<any>([
  { label: "Все задачи", value: "ALL", isActive: true },
  { label: "В работе", value: "ACTIVE", isActive: false },
  { label: "Доступно", value: "NOT_STARTED", isActive: false },
  { label: "Недоступно", value: "SUSPENDED", isActive: false },
  { label: "Завершено", value: "ENDED", isActive: false },
]);

const setActive = computed(() => (isActive: string) => {
  return isActive ? "active" : "";
});

const setActiveColor = computed(() => (isActive: string) => {
  return isActive ? "main-white" : "subhead-medium";
});

const changeActiveChip = (tab: any) => {
  if (tab.isActive) {
    return;
  }

  tabs.value.forEach((item: any) => (item.isActive = false));

  tab.isActive = true;
  emits("update:filter-tasks", tab.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabs.value, (tab) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["tab", setActive.value(tab.isActive)]),
        key: tab.label,
        onClick: ($event: any) => (changeActiveChip(tab))
      }, [
        _createVNode(UIText, {
          class: "text",
          "font-size": "15px",
          "font-weight": 600,
          color: setActiveColor.value(tab.isActive)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(tab.label), 1)
          ]),
          _: 2
        }, 1032, ["color"])
      ], 10, _hoisted_2))
    }), 128))
  ]))
}
}

})