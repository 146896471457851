import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "current-user-info",
  "data-testid": "current-user-info"
}
const _hoisted_2 = { class: "info" }
const _hoisted_3 = { class: "name" }
const _hoisted_4 = { class: "role" }

import { computed } from "vue";
import { useStore } from "vuex";
import UIUserAvatar from "@/modules/UI-kit/UIUserAvatar.vue";
import { Roles } from "@/enums/enums";


export default /*@__PURE__*/_defineComponent({
  __name: 'CurrentUserInfo',
  setup(__props) {

const store = useStore();

const role = computed(() => {
  switch (store.state.authStore.role) {
    case Roles.instructor:
      return "Инструктор";

    case Roles.partner:
      return "Партнер";

    case Roles.manager:
      return "Менеджер";
  }

  return "";
});

const userName = computed(() => {
  const name = store.state.authStore.username || "";
  const surname = store.state.authStore.surname || "";

  return `${name} ${surname}`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(UIUserAvatar, { class: "avatar" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(userName.value), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(role.value), 1)
    ])
  ]))
}
}

})