import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "partner-profile-page" }

import { Roles } from "@/enums/enums";
import { useStore } from "vuex";
import { Undefinable } from "@/types/types";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import UITabs from "@/modules/UI-kit/UITabs.vue";
import ProgressTab from "@/modules/progress/views/ProgressTab.vue";
import SecondaryHeader from "@/modules/layouts/components/headers/SecondaryHeader.vue";
import PartnerMainInformation from "@/modules/partner-profile/components/PartnerMainInformation.vue";
import FinancesTab from "@/modules/finances/views/FinancesTab.vue";
import DecorationsTab from "@/modules/decorations/views/DecorationsTab.vue";
import EquipmentsTab from "@/modules/equipments/views/EquipmentsTab.vue";
import BuildingsTab from "@/modules/buildings/views/BuildingsTab.vue";
import DocumentsTab from "@/modules/documents/views/DocumentsTab.vue";

interface ITab {
  label: string;
  value: string;
  checked: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PartnerProfilePage',
  props: ["value"],
  setup(__props) {

const spotId = localStorage.getItem("spotId");

const route = useRoute();
const router = useRouter();
const store = useStore();

const props = __props;

const tabs = ref<ITab[]>([
  {
    label: "Финансы",
    value: "finances",
    checked: true,
  },
  {
    label: "Прогресс",
    value: "progress",
    checked: false,
  },
  {
    label: "Помещение",
    value: "buildings",
    checked: false,
  },
  {
    label: "Декор",
    value: "decorations",
    checked: false,
  },
  {
    label: "Оборудование",
    value: "equipment",
    checked: false,
  },
  {
    label: "Документы",
    value: "documents",
    checked: false,
  },
]);

const role = computed(() => store.state.authStore.role);

const currentTab = computed<Undefinable<ITab>>(() =>
  tabs.value.find((tab: ITab) => tab.checked)
);

const pushBack = async () => {
  switch (role.value) {
    case Roles.instructor:
      await router.push({ path: "/instructor/main" });
      break;

    case Roles.manager:
      await router.push({ path: "/manager/main" });
      break;

    case Roles.partner:
      await router.push({ path: "/partner/main" });
      break;

    case Roles.executive:
      await router.push({ path: "/executive/main" });
      break;
  }
};

const partnerInfo = computed(() => {
  return {
    name: store.state.partnerProfileStore.partnerInfo.name,
    surname: store.state.partnerProfileStore.partnerInfo.surname,
    city: store.state.partnerProfileStore.partnerInfo.city,
  };
});

watch(
  () => tabs.value,
  async () => {
    const activeTab = tabs.value.find((item) => item.checked);
    const userId = route.params.id;
    await router.replace(`/profile/${userId}/${activeTab?.value}`);
  }
);

const initTabs = () => {
  const activeTab = tabs.value.find((tab) => tab.value === props.value);

  if (!activeTab) {
    return;
  }

  tabs.value = tabs.value.map((tab) => {
    if (tab.value === props.value) {
      return {
        ...tab,
        checked: true,
      };
    }
    return {
      ...tab,
      checked: false,
    };
  });
};

onMounted(async () => {
  initTabs();

  // partner information
  await store.dispatch("partnerProfileStore/fetchUserInfo", {
    partnerId: route?.params?.id,
    role: role.value,
  });

  // partner progress
  await store.dispatch("progressStore/fetchFullHistory", {
    partnerId: route.params.id,
    role: role.value,
    spotId,
  });
  await store.dispatch("progressStore/fetchStages", {
    partnerId: route.params.id,
    role: role.value,
    spotId,
  });
  await store.dispatch("progressStore/fetchLastHistory", {
    partnerId: route.params.id,
    role: role.value,
    spotId,
  });

  // decorations
  await store.dispatch("decorationsStore/fetchDecorations", {
    partnerId: route.params.id,
    role: role.value,
    spotId,
  });

  // equipments
  await store.dispatch("equipmentsStore/fetchEquipments", {
    partnerId: route.params.id,
    role: role.value,
    spotId,
  });

  // buildings
  await store.dispatch("buildingsStore/fetchBuildings", {
    partnerId: route.params.id,
    role: role.value,
    spotId,
  });

  // finances
  await store.dispatch("financesStore/fetchEstimate", {
    role: role.value,
    partnerId: route.params.id,
    spotId,
  });

  await store.dispatch("financesStore/fetchOutlays", {
    role: role.value,
    partnerId: route.params.id,
    spotId,
  });

  await store.dispatch("financesStore/fetchExpenses", {
    role: role.value,
    partnerId: route.params.id,
    spotId,
  });

  await store.dispatch("financesStore/fetchExpensesSum", {
    role: role.value,
    partnerId: route.params.id,
    spotId,
  });

  // documents
  await store.dispatch("documentsStore/fetchDocuments", {
    role: role.value,
    partnerId: route.params.id,
    spotId,
  });
});

onUnmounted(() => {
  localStorage.removeItem("spotId");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(SecondaryHeader, {
      class: "header",
      "onUpdate:pushBack": pushBack
    }, {
      pageTitle: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode(" Кофейня партнера")
      ])),
      _: 1
    }),
    _createVNode(PartnerMainInformation, {
      name: partnerInfo.value?.name,
      surname: partnerInfo.value?.surname,
      city: partnerInfo.value?.city,
      class: "info"
    }, null, 8, ["name", "surname", "city"]),
    _createVNode(UITabs, {
      class: "tabs",
      name: "profile-tab",
      modelValue: tabs.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tabs).value = $event))
    }, null, 8, ["modelValue"]),
    (currentTab.value?.value === 'progress')
      ? (_openBlock(), _createBlock(ProgressTab, {
          key: 0,
          "stage-list": _unref(store).state.progressStore.stages,
          "full-history-items": _unref(store).state.progressStore.fullHistory,
          "last-history-items": _unref(store).state.progressStore.lastHistory
        }, null, 8, ["stage-list", "full-history-items", "last-history-items"]))
      : _createCommentVNode("", true),
    (currentTab.value?.value === 'finances')
      ? (_openBlock(), _createBlock(FinancesTab, {
          key: 1,
          estimate: _unref(store).state.financesStore.estimate,
          expenses: _unref(store).state.financesStore.expenses,
          outlays: _unref(store).state.financesStore.outlays,
          sum: _unref(store).state.financesStore.sum
        }, null, 8, ["estimate", "expenses", "outlays", "sum"]))
      : _createCommentVNode("", true),
    (currentTab.value?.value === 'decorations')
      ? (_openBlock(), _createBlock(DecorationsTab, {
          key: 2,
          decorations: _unref(store).state.decorationsStore.decorations
        }, null, 8, ["decorations"]))
      : _createCommentVNode("", true),
    (currentTab.value?.value === 'equipment')
      ? (_openBlock(), _createBlock(EquipmentsTab, {
          key: 3,
          equipments: _unref(store).state.equipmentsStore.equipments
        }, null, 8, ["equipments"]))
      : _createCommentVNode("", true),
    (currentTab.value?.value === 'buildings')
      ? (_openBlock(), _createBlock(BuildingsTab, {
          key: 4,
          buildings: _unref(store).state.buildingsStore.buildings
        }, null, 8, ["buildings"]))
      : _createCommentVNode("", true),
    (currentTab.value?.value === 'documents')
      ? (_openBlock(), _createBlock(DocumentsTab, {
          key: 5,
          documents: _unref(store).state.documentsStore.documents
        }, null, 8, ["documents"]))
      : _createCommentVNode("", true)
  ]))
}
}

})