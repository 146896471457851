import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"
import _imports_0 from '@/modules/progress/images/backgrounds/background-black.svg'
import _imports_1 from '@/modules/progress/images/backgrounds/background-white.svg'


const _hoisted_1 = {
  src: _imports_0,
  alt: "background",
  class: "background-black"
}
const _hoisted_2 = {
  src: _imports_1,
  alt: "background",
  class: "background-white"
}
const _hoisted_3 = { class: "top" }
const _hoisted_4 = { class: "percent" }
const _hoisted_5 = {
  key: 0,
  class: "percentage"
}
const _hoisted_6 = {
  key: 1,
  class: "done-mark"
}
const _hoisted_7 = { class: "bottom" }
const _hoisted_8 = { class: "date start-date" }
const _hoisted_9 = { class: "date end-date" }

import { computed } from "vue";
import IconDone from "@/icons/IconDone.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgressStageItem',
  props: {
  startDate: {
    type: String,
    required: true,
  },
  endDate: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  progress: {
    type: String,
    required: true,
  },
  stagePercentage: {
    type: [String, Number],
    required: true,
  },
  datePercentage: {
    type: [String, Number],
    required: true,
  },
},
  setup(__props) {

_useCssVars(_ctx => ({
  "dd892554": (getLineWidth.value)
}))

const props = __props;

const showProgressBar = computed(() =>
  props.stagePercentage === 100 ? "withoutProgressBar" : ""
);

const getLineWidth = computed(() => `${props.datePercentage}%`);

const getBackgroundColor = computed(() =>
  props.stagePercentage === 100 ? "done" : "active"
);

const setTitleColor = computed(() => {
  return props.stagePercentage === 100 ? "done-text" : "";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["stage-item", getBackgroundColor.value])
  }, [
    _withDirectives(_createElementVNode("img", _hoisted_1, null, 512), [
      [_vShow, __props.stagePercentage === 100]
    ]),
    _withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [
      [_vShow, __props.stagePercentage !== 100]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", {
        class: _normalizeClass(["title", setTitleColor.value])
      }, _toDisplayString(__props.title), 3),
      _createElementVNode("div", _hoisted_4, [
        (__props.stagePercentage !== 100)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(`${__props.stagePercentage}%`), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(IconDone)
            ]))
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["center", showProgressBar.value])
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "progress" }, null, -1)
    ]), 2),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("span", _hoisted_8, _toDisplayString(__props.startDate), 1),
      _createElementVNode("span", _hoisted_9, _toDisplayString(__props.endDate), 1)
    ])
  ], 2))
}
}

})