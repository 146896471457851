import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-container" }

import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import SidebarContainer from "@/modules/layouts/components/sidebar/SidebarContainer.vue";
import ModalWrapper from "@/modules/layouts/components/modals/ModalWrapper.vue";
import FirebaseInit from "@/plugins/firebase/components/FirebaseInit.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LayoutContainer',
  setup(__props) {

const route: any = useRoute();
const store = useStore();

const isInitFirebase = computed(() => {
  return route?.meta?.role?.length && localStorage.getItem("accessToken");
});

const showLayout = computed(() => {
  return !(
    route.path === "/auth" ||
    route.path === "/appeal" ||
    route.path === "/timeline"
  );
});

const pagesStyles = computed(() => {
  return route.path === "/auth" ||
    route.path === "/appeal" ||
    route.path === "/timeline"
    ? "without-layout"
    : "page";
});

const showModalWindow = computed(() => store.state.modalsStore.isShownModal);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (showLayout.value)
      ? (_openBlock(), _createBlock(SidebarContainer, { key: 0 }))
      : _createCommentVNode("", true),
    (showModalWindow.value)
      ? (_openBlock(), _createBlock(ModalWrapper, { key: 1 }))
      : _createCommentVNode("", true),
    (isInitFirebase.value)
      ? (_openBlock(), _createBlock(FirebaseInit, { key: 2 }))
      : _createCommentVNode("", true),
    _createElementVNode("main", {
      class: _normalizeClass(pagesStyles.value)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}
}

})