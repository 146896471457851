import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "current-spot-widget" }
const _hoisted_2 = { class: "spot-name" }

import { computed } from "vue";
import { useStore } from "vuex";
import { ModalTypes } from "@/enums/enums";


export default /*@__PURE__*/_defineComponent({
  __name: 'CurrentSpotWidget',
  setup(__props) {

const store = useStore();

const spotName = computed(() => store.state.authStore.spotName);

const openChangeSpotWindow = () => {
  store.commit("modalsStore/showModal", {
    modalType: ModalTypes.changeSpotModal,
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(spotName.value), 1),
    _createElementVNode("button", {
      class: "change-spot-button",
      onClick: openChangeSpotWindow
    }, " Сменить спот ")
  ]))
}
}

})