import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "information" }
const _hoisted_2 = { class: "date" }
const _hoisted_3 = {
  key: 0,
  class: "status"
}

import { useStore } from "vuex";
import { computed } from "vue";
import { readNotification } from "@/modules/notifications/services/notifications.services";
import { toast } from "vue3-toastify";


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsListItem',
  props: {
  id: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  date: {
    type: String,
    required: true,
  },
  isImportant: {
    type: Boolean,
    default: false,
  },
  isNew: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const store = useStore();

const props = __props;

const setAsRead = async () => {
  try {
    await readNotification(props.id);
    await store.dispatch("notificationsStore/fetchNotifications");
  } catch (e: any) {
    toast(e.response.data.reason);
  }
};

const formattedDate = computed(() => {
  const date = new Date(props.date);
  const formattedTime = new Date(date).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  const formattedDate = new Date(date).toLocaleDateString("ru-RU", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
  return `${formattedDate} в ${formattedTime}`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    class: "notifications-list-item",
    onClick: setAsRead
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["title", { titleNew: __props.isNew }])
      }, _toDisplayString(__props.title), 3),
      _createElementVNode("div", _hoisted_2, _toDisplayString(formattedDate.value), 1)
    ]),
    (__props.isImportant)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, "важное"))
      : _createCommentVNode("", true)
  ]))
}
}

})