import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ui-popup-open" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "button-container" }

import { PropType } from "vue";
import { Undefinable } from "@/types/types";
import { useStore } from "vuex";
import UIButton from "@/modules/UI-kit/UIButton.vue";
import UIText from "@/modules/UI-kit/UIText.vue";
import { ModalTypes } from "@/enums/enums";


export default /*@__PURE__*/_defineComponent({
  __name: 'UIPopupOpen',
  props: {
  title: {
    type: Object as PropType<Undefinable<string>>,
    required: true,
  },
  subtitle: {
    type: Object as PropType<Undefinable<string>>,
    required: true,
  },
  fields: {
    type: Array as PropType<any[]>,
    required: true,
  },
  structureIndex: {
    type: Number,
    default: 0,
  },
},
  setup(__props) {

const store = useStore();

const props = __props;

const openHelperPopup = () => {
  store.commit("modalsStore/setPrevModalType", {
    modalData: props.fields,
    modalType: ModalTypes.cppSidePopup,
    prev: ModalTypes.cppMainPopup,
  });
  store.commit("modalsStore/setSideModalData", props.fields);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(UIText, {
        color: "black",
        "font-family": "proxima-nova-bold"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(__props.title), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(UIButton, {
        class: "button",
        "button-style": "ghost",
        onClick: openHelperPopup
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" Открыть ")
        ])),
        _: 1
      })
    ])
  ]))
}
}

})