import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { Undefinable } from "@/types/types";
import { computed, PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PartnerTaskStatus',
  props: {
  status: {
    type: String as PropType<string | undefined>,
    required: true,
  },
  dueDate: {
    type: String,
    required: false,
  },
  taskId: {
    type: String as PropType<string | undefined>,
    default: undefined,
  },
},
  emits: ["click"],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const props = __props;

const setStatusContent = computed(() => {
  let date: Array<string> = [];

  switch (props.status) {
    case "ENDED":
      return "Выполнено";

    case "ACTIVE":
      if (!props.dueDate) {
        return;
      }
      date = props.dueDate.split("T")[0].split("-");
      return `до ${date[2]}.${date[1]}`;

    case "NOT_STARTED":
      return props.taskId ? "Взять в работу" : "Пока недоступно";
  }
});

const setStatusClass = computed(() => {
  switch (props.status) {
    case "ENDED":
      return "ended";

    case "ACTIVE":
      return "active";

    case "NOT_STARTED":
      return props.taskId ? "not-started" : "not-allowed";
  }
});

const onClickHandler = () => {
  if (props.status === "NOT_STARTED" && props.taskId) {
    emits("click");
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["status", setStatusClass.value]),
    onClick: onClickHandler
  }, _toDisplayString(setStatusContent.value), 3))
}
}

})