import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "manager-main" }
const _hoisted_2 = { class: "dashbaord-main-actions" }
const _hoisted_3 = { class: "dashboard-main-columns" }
const _hoisted_4 = {
  key: 0,
  class: "columns"
}
const _hoisted_5 = {
  key: 1,
  class: "stages"
}

import { useStore } from "vuex";
import { debounce } from "@/helpers/debounce";
import { computed, onMounted, ref, watch } from "vue";
import DashboardColumn from "@/modules/manager-dashboard/components/DashboardColumn.vue";
import MainHeader from "@/modules/layouts/components/headers/MainHeader.vue";
import UIGhostTabs from "@/modules/UI-kit/UIGhostTabs.vue";
import DashboardActions from "@/modules/manager-dashboard/components/DashboardActions.vue";
import DashboardFoundStages from "@/modules/manager-dashboard/components/DashboardFoundStages.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ManagerDashboard',
  setup(__props) {

const store = useStore();

const searchString = ref<string>("");

const columns = computed(() => {
  return store.state.managerDashboardStore.columns;
});

const stages = computed(() => store.state.managerDashboardStore.stages);

const debouncedSearchStage = debounce(async () => {
  await store.dispatch("managerDashboardStore/searchStages", {
    searchString: searchString.value,
  });
}, 500);

watch(searchString, () => {
  store.commit("managerDashboardStore/toggleSearchLoader", true);
  debouncedSearchStage();
});

onMounted(async () => {
  await store.dispatch("managerDashboardStore/fetchDashboardStages");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(MainHeader, { class: "header" }),
      _createVNode(DashboardActions, {
        class: "dashboard-actions",
        modelValue: searchString.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchString).value = $event)),
        modelModifiers: { trim: true }
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (!searchString.value && !stages.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(columns.value, (column) => {
              return (_openBlock(), _createBlock(DashboardColumn, {
                key: column.id,
                column: column,
                class: "dashboard-column"
              }, null, 8, ["column"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(DashboardFoundStages)
          ]))
    ])
  ]))
}
}

})