import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "net-profit-statistic" }
const _hoisted_2 = { class: "statistic-item net-profit-item" }
const _hoisted_3 = { class: "statistic" }
const _hoisted_4 = { class: "main-item statistic-item" }
const _hoisted_5 = { class: "statistic" }
const _hoisted_6 = { class: "main-item statistic-item" }
const _hoisted_7 = { class: "statistic" }
const _hoisted_8 = { class: "main-item statistic-item" }
const _hoisted_9 = { class: "statistic" }

import { computed } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'NetProfitStatistic',
  setup(__props) {

const store = useStore();

const estimate = computed(() => store.state.financesStore.estimate);

const formattedPrice = computed(() => {
  return function (price: number) {
    return new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "RUB",
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 0,
    }).format(price);
  };
});

const withPercentage = computed(() => {
  return function (value: number) {
    return new Intl.NumberFormat("ru-RU", {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(value);
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "title" }, "Чистая прибыль", -1)),
      _createElementVNode("div", _hoisted_3, _toDisplayString(formattedPrice.value(estimate.value.netProfit)), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "title" }, "Прибыль", -1)),
      _createElementVNode("div", _hoisted_5, _toDisplayString(formattedPrice.value(estimate.value.profit)), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "title" }, "Управляющий состав", -1)),
      _createElementVNode("div", _hoisted_7, _toDisplayString(formattedPrice.value(estimate.value.profitX)), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "title" }, "Рентабельность", -1)),
      _createElementVNode("div", _hoisted_9, _toDisplayString(withPercentage.value(estimate.value.profitability)), 1)
    ])
  ]))
}
}

})