import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "empty-tasks",
  "data-testid": "empty-tasks-dashboard"
}
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "title" }

import UIText from "@/modules/UI-kit/UIText.vue";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EmptyTasksDashboard',
  props: {
  moduleText: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const setTitleText = computed(() => {
  return !props.moduleText ? "Задач еще нет" : "В данном модуле пока нет задач";
});

const setSubtitleText = computed(() => {
  return !props.moduleText ? "В данном модуле пока нет задач" : "";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(UIText, {
          "font-weight": 600,
          "font-size": "17px",
          color: "main-black"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(setTitleText.value), 1)
          ]),
          _: 1
        })
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "subtitle" }, [
        _createElementVNode("span", { class: "subtitle-text" }, " Откройте задачник, чтобы выбрать задачи для выполнения ")
      ], -1))
    ])
  ]))
}
}

})