import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "structure-item" }
const _hoisted_2 = {
  key: 0,
  class: "link structure-item"
}
const _hoisted_3 = {
  key: 1,
  class: "deep-link structure-item"
}
const _hoisted_4 = {
  key: 2,
  class: "upload-file structure-item"
}
const _hoisted_5 = {
  key: 3,
  class: "text-field structure-item"
}
const _hoisted_6 = {
  key: 4,
  class: "structure-item dropdown"
}
const _hoisted_7 = {
  key: 5,
  class: "structure-item file-download"
}
const _hoisted_8 = {
  key: 6,
  class: "structure-item date-picker"
}
const _hoisted_9 = {
  key: 7,
  class: "structure-item popup-item"
}
const _hoisted_10 = {
  key: 8,
  class: "structure-item radio-buttons"
}
const _hoisted_11 = {
  key: 9,
  class: "structure-item file-download"
}

import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { computed, onMounted, PropType, Ref, ref, toRaw, watch } from "vue";
import UILink from "@/modules/UI-kit/UILink.vue";
import UIFileUpload from "@/modules/UI-kit/UIFileUpload.vue";
import UIInput from "@/modules/UI-kit/UIInput.vue";
import UIDropdown from "@/modules/UI-kit/UIDropdown.vue";
import UIRadioButtons from "@/modules/UI-kit/UIRadioButtons.vue";
import UIPopupOpen from "@/modules/UI-kit/UIPopupOpen.vue";
import UIDatePicker from "@/modules/UI-kit/UIDatePicker.vue";
import UIFileDownload from "@/modules/UI-kit/UIFileDownload.vue";
import UIDeepLink from "@/modules/UI-kit/UIDeepLink.vue";
import UIFileUploader from "@/modules/buildings/components/UIFileUploader.vue";
import BaseDatePicker from "@/components/ui/BaseDatePicker.vue";

interface Item {
  title?: string;
  description?: string;
  type?: string;
  value?: any;
  variableName?: string;
  variableType?: string;
  options?: Array<any>;
  fields?: Array<any>;
  editable?: boolean;
  getLink?: string;
  downloadLink?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CppStructureItem',
  props: {
  item: {
    type: Object as PropType<Item>,
    required: true,
  },
  structureIndex: {
    type: Number,
    required: true,
  },
},
  emits: [
  "update:textValue",
  "update:radioValue",
  "update:datePickerValue",
  "update:addFile",
  "update:dropDown",
  "update:deleteFile",
  "update:multipleFiles",
],
  setup(__props, { emit: __emit }) {

const initDate = () => {
  return dayjs().set("minutes", 0).set("seconds", 0).toDate();
};

const text = ref<string>("");
const date = ref<Date>(initDate());
const lowerLimit = ref<Date>(new Date());

const currentOption = ref({
  label: "Не выбрано",
  value: "notSelected",
});

const props = __props;

const emits = __emit;

const radios = ref<Array<any>>([]);

const generateRadioGroupName = computed(() => {
  return uuidv4();
});

const onFileDelete = (file: { fileName?: string; file: File }) => {
  emits("update:deleteFile", {
    file: file.file,
    key: props.item?.variableName,
  });
};

const updateTextValue = () => {
  const toEmitTextValue =
    props.item?.variableType === "int" ? Number(text.value) : text.value;

  emits("update:textValue", {
    value: toEmitTextValue,
    key: props.item?.variableName,
  });
};

const updateFile = (event: any) => {
  emits("update:multipleFiles", {
    value: event,
    key: props.item?.variableName,
  });
};

const onFileDownload = (file: any) => {
  emits("update:addFile", {
    value: file,
    key: props.item?.variableName,
  });
};

const onRadioUpdate = (event: any) => {
  radios.value = event;
  const active = event.find((item: any) => item.checked);
  emits("update:radioValue", {
    value: active.value,
    key: props.item?.variableName,
  });
};

const onDatePickerHandler = (event: Date) => {
  date.value = event;

  const dayjsDate = dayjs(date.value);
  const formatted = dayjsDate.format();

  emits("update:datePickerValue", {
    value: formatted,
    key: props.item?.variableName,
  });
};

const onDropDownUpdate = (event: Ref<string>) => {
  const option = props.item?.fields?.find((item) => item.value === event.value);
  currentOption.value = option;
  emits("update:dropDown", {
    value: option,
    key: props.item?.variableName,
  });
};

watch(text, () => {
  updateTextValue();
});

onMounted(() => {
  if (props.item.type === "text-field") {
    if (!props.item.value) {
      text.value = "";
    } else {
      text.value = `${props.item.value}`;
    }
  }

  if (props.item?.type === "radio" && props.item?.fields) {
    radios.value = [...props.item?.fields];
  }

  if (props.item?.type === "drop-down") {
    currentOption.value = props.item?.fields?.find((item) => item.checked);

    if (!currentOption.value) {
      currentOption.value = props.item?.fields[0];
      emits("update:dropDown", {
        value: currentOption.value,
        key: props.item?.variableName,
      });
    }
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.item.type === 'link')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(UILink, {
            description: __props.item?.description || '',
            link: __props.item?.value
          }, null, 8, ["description", "link"])
        ]))
      : _createCommentVNode("", true),
    (__props.item.type === 'deep-link')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(UIDeepLink, {
            description: __props.item?.description || '',
            link: __props.item?.value
          }, null, 8, ["description", "link"])
        ]))
      : _createCommentVNode("", true),
    (__props.item.type === 'upload-file')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(UIFileUpload, {
            title: __props.item?.title || '',
            description: __props.item?.description,
            onFile: onFileDownload,
            onDeleteFile: onFileDelete
          }, null, 8, ["title", "description"])
        ]))
      : _createCommentVNode("", true),
    (__props.item.type === 'text-field')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(UIInput, {
            modelValue: text.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((text).value = $event)),
            "place-holder": __props.item?.description || '',
            readonly: !__props.item?.editable
          }, null, 8, ["modelValue", "place-holder", "readonly"])
        ]))
      : _createCommentVNode("", true),
    (__props.item.type === 'drop-down')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(UIDropdown, {
            options: __props.item?.fields || [],
            "selected-option": currentOption.value,
            label: __props.item.description,
            "onUpdate:selectedOption": onDropDownUpdate
          }, null, 8, ["options", "selected-option", "label"])
        ]))
      : _createCommentVNode("", true),
    (__props.item.type === 'download-file')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(UIFileDownload, {
            title: __props.item?.title,
            "preview-link": __props.item?.getLink,
            "download-link": __props.item?.downloadLink
          }, null, 8, ["title", "preview-link", "download-link"])
        ]))
      : _createCommentVNode("", true),
    (__props.item.type === 'date-picker')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(BaseDatePicker, {
            "model-value": date.value,
            "onUpdate:modelValue": onDatePickerHandler
          }, null, 8, ["model-value"])
        ]))
      : _createCommentVNode("", true),
    (__props.item.type === 'pop-up')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(UIPopupOpen, {
            title: __props.item.title,
            fields: __props.item?.fields || [],
            subtitle: __props.item.description
          }, null, 8, ["title", "fields", "subtitle"])
        ]))
      : _createCommentVNode("", true),
    (__props.item.type === 'radio')
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(UIRadioButtons, {
            title: __props.item?.description,
            name: generateRadioGroupName.value,
            "model-value": radios.value,
            "onUpdate:modelValue": onRadioUpdate
          }, null, 8, ["title", "name", "model-value"])
        ]))
      : _createCommentVNode("", true),
    (__props.item.type === 'upload-file-multiple')
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(UIFileUploader, {
            "onUpdate:files": updateFile,
            "onUpdate:deleteFile": onFileDelete
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})