import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "net-profit-statistic" }
const _hoisted_2 = { class: "main-item statistic-item" }
const _hoisted_3 = { class: "main-item statistic-item" }
const _hoisted_4 = { class: "main-item statistic-item" }

import { computed, ref } from "vue";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";
import { useRoute } from "vue-router";
import { Roles } from "@/enums/enums";
import {
  updateBillsPerDay,
  updateIncomePerDay,
  updateMinIncomePerMonth,
} from "@/modules/finances/services/finances.services";
import UIEditInput from "@/modules/finances/components/UIEditInput.vue";
import { useOutsideClick } from "@/composables/useOutsideClick";


export default /*@__PURE__*/_defineComponent({
  __name: 'BreakEvenStatistic',
  setup(__props) {

const showIncomePerDayInput = ref<boolean>(false);
const showBillsPerDayInput = ref<boolean>(false);
const showMinIncomePerMonthInput = ref<boolean>(false);
const editIncomePerDay = ref<string>("");
const editBillsPerDay = ref<string>("");
const editMinIncomePerMonth = ref<string>("");
const incomePerDay = ref(null);
const billsPerDay = ref(null);
const minIncome = ref(null);

const store = useStore();
const route = useRoute();

const spotId = localStorage.getItem("spotId");

const estimate = computed(() => store.state.financesStore.estimate);
const role = computed(() => store.state.authStore.role);

const formattedPrice = computed(() => {
  return function (price: number) {
    return new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "RUB",
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 0,
    }).format(price);
  };
});

const toShowIncomePerDay = () => {
  if (role.value === Roles.manager) {
    showIncomePerDayInput.value = true;
    editIncomePerDay.value = estimate.value.incomePerDay;
  }
};

const toShowBillsPerDay = () => {
  if (role.value === Roles.manager) {
    showBillsPerDayInput.value = true;
    editBillsPerDay.value = estimate.value.billsPerDay;
  }
};

const toShowMinIncomePerMonth = () => {
  if (role.value === Roles.manager) {
    showMinIncomePerMonthInput.value = true;
    editMinIncomePerMonth.value = estimate.value.incomePerDay;
  }
};

const updateIncome = async () => {
  try {
    const response = await updateIncomePerDay(
      estimate.value.id,
      editIncomePerDay.value
    );
    showIncomePerDayInput.value = false;
    await store.dispatch("financesStore/fetchEstimate", {
      role: role.value,
      partnerId: route.params.id,
      spotId,
    });
  } catch (e) {
    console.log(e);
    toast.error("Не удалось обновить значение");
  }
};

const updateBills = async () => {
  try {
    const response = await updateBillsPerDay(
      estimate.value.id,
      editBillsPerDay.value
    );
    showBillsPerDayInput.value = false;
    await store.dispatch("financesStore/fetchEstimate", {
      role: role.value,
      partnerId: route.params.id,
      spotId,
    });
  } catch (e) {
    console.log(e);
    toast.error("Не удалось обновить значение");
  }
};

const updateMinIncome = async () => {
  try {
    const response = await updateMinIncomePerMonth(
      estimate.value.id,
      editMinIncomePerMonth.value
    );
    showMinIncomePerMonthInput.value = false;
    await store.dispatch("financesStore/fetchEstimate", {
      role: role.value,
      partnerId: route.params.id,
      spotId,
    });
  } catch (e) {
    console.log(e);
    toast.error("Не удалось обновить значение");
  }
};

useOutsideClick(incomePerDay, async () => {
  if (showIncomePerDayInput.value) {
    await updateIncome();
  }
});
useOutsideClick(billsPerDay, async () => {
  if (showBillsPerDayInput.value) {
    await updateBills();
  }
});
useOutsideClick(minIncome, async () => {
  if (showMinIncomePerMonthInput.value) {
    await updateMinIncome();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "statistic-item net-profit-item" }, [
      _createElementVNode("div", { class: "title" }, "Точка безубыточности")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "title" }, "Выручка в день", -1)),
      _createElementVNode("div", {
        ref_key: "incomePerDay",
        ref: incomePerDay,
        class: "statistic",
        onKeydown: _withKeys(updateIncome, ["enter"])
      }, [
        (showIncomePerDayInput.value)
          ? (_openBlock(), _createBlock(UIEditInput, {
              key: 0,
              modelValue: editIncomePerDay.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((editIncomePerDay).value = $event))
            }, null, 8, ["modelValue"]))
          : (_openBlock(), _createElementBlock("p", {
              key: 1,
              onClick: toShowIncomePerDay,
              class: "text"
            }, _toDisplayString(formattedPrice.value(estimate.value.incomePerDay)), 1))
      ], 544)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "title" }, "Количество чеков в день", -1)),
      _createElementVNode("div", {
        ref_key: "billsPerDay",
        ref: billsPerDay,
        class: "statistic",
        onKeydown: _withKeys(updateBills, ["enter"])
      }, [
        (showBillsPerDayInput.value)
          ? (_openBlock(), _createBlock(UIEditInput, {
              key: 0,
              modelValue: editBillsPerDay.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((editBillsPerDay).value = $event))
            }, null, 8, ["modelValue"]))
          : (_openBlock(), _createElementBlock("p", {
              key: 1,
              onClick: toShowBillsPerDay,
              class: "text"
            }, _toDisplayString(estimate.value.billsPerDay), 1))
      ], 544)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "title" }, "Минимальная выручка в месяц", -1)),
      _createElementVNode("div", {
        ref_key: "minIncome",
        ref: minIncome,
        class: "statistic",
        onKeydown: _withKeys(updateMinIncome, ["enter"])
      }, [
        (showMinIncomePerMonthInput.value)
          ? (_openBlock(), _createBlock(UIEditInput, {
              key: 0,
              modelValue: editMinIncomePerMonth.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((editMinIncomePerMonth).value = $event))
            }, null, 8, ["modelValue"]))
          : (_openBlock(), _createElementBlock("p", {
              key: 1,
              onClick: toShowMinIncomePerMonth,
              class: "text"
            }, _toDisplayString(formattedPrice.value(estimate.value.minIncomePerMonth)), 1))
      ], 544)
    ])
  ]))
}
}

})