import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "left" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "empty-image-state"
}
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "title-text-container" }
const _hoisted_6 = { class: "title-text" }
const _hoisted_7 = { class: "right" }

import { computed, PropType } from "vue";
import { IDecoration } from "@/modules/decorations/types/decorations.types";
import UIText from "@/modules/UI-kit/UIText.vue";
import IconAvatarEmpty from "@/icons/IconAvatarEmpty.vue";
import { useStore } from "vuex";
import { ModalTypes } from "@/enums/enums";
import { usePriceFormat } from "@/composables/usePriceFormat";


export default /*@__PURE__*/_defineComponent({
  __name: 'DecorListItem',
  props: {
  decoration: {
    type: Object as PropType<IDecoration>,
    required: true,
  },
},
  setup(__props) {

const store = useStore();

const props = __props;

const getStatusClass = computed(() => {
  return props.decoration.ordered ? "ordered" : "not-ordered";
});

const setTitle = computed(() => {
  return props.decoration.title.length >= 54
    ? props.decoration.title.slice(0, 35) + "..."
    : props.decoration.title;
});

const { formattedPrice } = usePriceFormat(props.decoration?.price);

const getStatusText = computed(() => {
  return props.decoration.ordered ? "заказан" : "не заказан";
});

const checkThumbnail = computed(() => {
  return !props.decoration?.thumbnail?.includes("null");
});

const openDecorationModal = () => {
  store.commit("decorationsStore/setCurrentDecoration", props.decoration);
  store.commit("modalsStore/showModal", {
    modalType: ModalTypes.updateDecor,
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    class: "decor-list-item",
    onClick: openDecorationModal
  }, [
    _createElementVNode("div", _hoisted_1, [
      (props.decoration?.thumbnail)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: `${__props.decoration?.thumbnail}?v=${Date.now()}`,
            alt: "preview",
            class: "preview-image"
          }, null, 8, _hoisted_2))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(IconAvatarEmpty)
          ])),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(setTitle.value), 1)
        ]),
        _createVNode(UIText, {
          "font-family": "proxima-nova-regular",
          "font-weight": 400,
          "font-size": "15px"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(formattedPrice)), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", {
        class: _normalizeClass(["status", getStatusClass.value])
      }, _toDisplayString(getStatusText.value), 3)
    ])
  ]))
}
}

})