import { getPartnerInfo } from "@/modules/partner-profile/services/partner-profile.services";
import type {
  IPartnerInfo,
  PartnerAct,
} from "@/modules/partner-profile/types/partner-profile.types";
import { getSpots } from "@/modules/spot/services/spot.services";

export default {
  namespaced: true,
  state: {
    partnerInfo: {} as IPartnerInfo,
    acts: [] as PartnerAct[],
  },
  mutations: {
    setPartnerInfo(state: any, payload: any) {
      state.partnerInfo = payload;
    },

    setActiveSpot(state: any, payload: any) {
      state.acts = state.acts.map((el: any) => ({
        ...el,
        checked: payload.id === el.id,
      }));
    },
  },
  actions: {
    async fetchUserInfo(
      context: any,
      payload: { role: string; partnerId: number }
    ) {
      try {
        const response = await getPartnerInfo(payload.role, payload.partnerId);
        context.commit("setPartnerInfo", response.data.partner);
      } catch (e: any) {
        return e.response;
      }
    },

    async fetchAvailableActs({ state }: any) {
      const activeSpotId = localStorage.getItem("spotId");

      try {
        const response = await getSpots();

        if (response.data.acts.length === 1) {
          localStorage.setItem("spotId", response.data.acts[0].id);
        }

        state.acts = [
          ...response.data.acts.map((item: any) => ({
            ...item,
            checked: item.id === Number(activeSpotId),
          })),
        ];

        if (!activeSpotId) {
          state.acts[0].checked = true;
          localStorage.setItem("spotId", response.data.acts[0].id);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
