import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "change-spot-window" }
const _hoisted_2 = { class: "main-content" }
const _hoisted_3 = { class: "list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "list-item-info" }
const _hoisted_6 = { class: "empty-image-state" }
const _hoisted_7 = { class: "list-item-name" }

import { computed } from "vue";
import { useStore } from "vuex";
import IconAvatarEmpty from "@/icons/IconAvatarEmpty.vue";
import UIButton from "@/modules/UI-kit/UIButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangeSpotWindow',
  setup(__props) {

const store = useStore();

const spots = computed(() => store.state.partnerProfileStore.acts);

const chooseSpot = () => {
  const spot = spots.value.find((item: any) => item.checked);
  localStorage.setItem("spotId", spot.id);
  location.reload();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "change-spot-window-title" }, "Выбрать спот", -1)),
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(spots.value, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            key: item.id,
            onClick: ($event: any) => (_unref(store).commit('partnerProfileStore/setActiveSpot', item)),
            class: "list-item"
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(IconAvatarEmpty)
              ]),
              _createElementVNode("span", _hoisted_7, _toDisplayString(item.name), 1)
            ]),
            _createElementVNode("span", {
              class: _normalizeClass(["status", { checked: item.checked }])
            }, _toDisplayString(item.checked ? "Выбран" : "Не выбран"), 3)
          ], 8, _hoisted_4))
        }), 128))
      ])
    ]),
    _createVNode(UIButton, {
      onClick: chooseSpot,
      class: "submit-button"
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode("Добавить")
      ])),
      _: 1
    })
  ]))
}
}

})