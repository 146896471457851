import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "date-picker-wrapper" }

import { computed } from "vue";
import type { PartialTimeObj } from "@vuepic/vue-datepicker";
import VueDatePicker from "@vuepic/vue-datepicker";
import dayjs from "dayjs";
import BaseInput from "@/components/ui/BaseInput.vue";

import "@vuepic/vue-datepicker/dist/main.css";

export interface Props {
  modelValue: Date[] | Date;
  disabled?: boolean;
  placeholder?: string;
  disabledDates?: Date[];
  autoRange?: number;
  minDate?: Date;
  maxDate?: Date;
  minTime?: PartialTimeObj;
  maxTime?: PartialTimeObj;
  startTime?: PartialTimeObj;
  hoursIncrement?: number | string;
  minutesIncrement?: number | string;
  secondsIncrement?: number | string;
  enableTimePicker?: boolean;
  teleport?: string;
  autoApply?: boolean;
  position?: "left" | "right" | "center";
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseDatePicker',
  props: {
    modelValue: {},
    disabled: { type: Boolean },
    placeholder: { default: "дд/мм/гг – дд/мм/гг" },
    disabledDates: { default: () => [] },
    autoRange: {},
    minDate: { default: dayjs().toDate() },
    maxDate: {},
    minTime: {},
    maxTime: {},
    startTime: { default: { hours: 0, minutes: 0 } },
    hoursIncrement: {},
    minutesIncrement: { default: 15 },
    secondsIncrement: {},
    enableTimePicker: { type: Boolean, default: true },
    teleport: {},
    autoApply: { type: Boolean, default: false },
    position: { default: "center" }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const inputText = computed(() => {
  if (!Array.isArray(props.modelValue)) {
    const date = dayjs(props.modelValue).format("DD.MM.YYYY");
    const time = dayjs(props.modelValue).format("HH:mm");
    return props.enableTimePicker ? `${date} - ${time}` : `${date}`;
  } else {
    const dateFirst = dayjs(props.modelValue[0]).format("DD.MM.YYYY");
    const dateSecond = dayjs(props.modelValue[1]).format("DD.MM.YYYY");
    const timeFirst = dayjs(props.modelValue[0]).format("HH:mm");
    const timeSecond = dayjs(props.modelValue[1]).format("HH:mm");
    return props.enableTimePicker
      ? `${dateFirst} ${timeFirst} - ${dateSecond} ${timeSecond}`
      : `${dateFirst} - ${dateSecond}`;
  }
});

const emit = __emit;

const onUpdateModelValue = (event: Date[]) => {
  emit("update:modelValue", event);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(VueDatePicker), {
      class: "datepicker",
      teleport: _ctx.teleport,
      position: _ctx.position,
      "model-value": _ctx.modelValue,
      "disabled-dates": _ctx.disabledDates,
      "min-date": _ctx.minDate,
      "max-date": _ctx.maxDate,
      "enable-time-picker": _ctx.enableTimePicker,
      "seconds-increment": _ctx.secondsIncrement,
      "minutes-increment": _ctx.minutesIncrement,
      "hours-increment": _ctx.hoursIncrement,
      "start-time": _ctx.startTime,
      "min-time": _ctx.minTime,
      "max-time": _ctx.maxTime,
      "auto-apply": _ctx.autoApply,
      locale: "ru",
      "onUpdate:modelValue": onUpdateModelValue
    }, {
      trigger: _withCtx(() => [
        _renderSlot(_ctx.$slots, "trigger", {}, () => [
          _createVNode(BaseInput, {
            "model-value": inputText.value,
            placeholder: _ctx.placeholder
          }, null, 8, ["model-value", "placeholder"])
        ])
      ]),
      _: 3
    }, 8, ["teleport", "position", "model-value", "disabled-dates", "min-date", "max-date", "enable-time-picker", "seconds-increment", "minutes-increment", "hours-increment", "start-time", "min-time", "max-time", "auto-apply"])
  ]))
}
}

})