import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "empty-buildings" }

import { computed } from "vue";
import { useStore } from "vuex";
import { ModalTypes, Roles } from "@/enums/enums";


export default /*@__PURE__*/_defineComponent({
  __name: 'EmptyDocuments',
  setup(__props) {

const store = useStore();

const role = computed(() => store.state.authStore.role);

const showWindow = () => {
  if (
    role.value === Roles.partner ||
    role.value === Roles.instructor ||
    role.value === Roles.executive
  ) {
    store.commit("modalsStore/showModal", {
      modalType: ModalTypes.addDecoration,
    });
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "content" }, [
      _createElementVNode("div", { class: "title" }, [
        _createElementVNode("span", { class: "title-text" }, " Документов еще нет ")
      ])
    ], -1)
  ])))
}
}

})