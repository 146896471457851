import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "list-header" }
const _hoisted_2 = { class: "list-title" }
const _hoisted_3 = {
  key: 0,
  class: "list"
}
const _hoisted_4 = {
  key: 1,
  class: "empty-state"
}

import { INotification } from "@/modules/notifications/types/notifications.types";
import { computed, PropType } from "vue";
import NotificationsListItem from "@/modules/notifications/components/NotificationsListItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsList',
  props: {
  title: {
    type: String,
    default: "прочитанные",
  },
  notifications: {
    type: Array as PropType<Array<INotification>>,
    required: true,
  },
  isNew: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const getNotificationTitle = computed(() => {
  return function (notification: INotification) {
    return `${notification.title}: ${notification.body}`;
  };
});

const isPointer = computed(() => {
  return props.isNew ? "pointer" : "";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(__props.title.toLowerCase()), 1)
    ]),
    (__props.notifications.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.notifications, (notification) => {
            return (_openBlock(), _createBlock(NotificationsListItem, {
              id: notification.id,
              key: notification.id,
              date: notification.createTime,
              title: getNotificationTitle.value(notification),
              class: _normalizeClass(isPointer.value),
              "is-new": __props.isNew
            }, null, 8, ["id", "date", "title", "class", "is-new"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, "Уведомлений еще нет"))
  ]))
}
}

})