import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "decorations-list" }

import { PropType } from "vue";
import { IDecoration } from "@/modules/decorations/types/decorations.types";
import DecorListItem from "@/modules/decorations/components/DecorListItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DecorList',
  props: {
  decorations: {
    type: Array as PropType<Array<IDecoration>>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.decorations, (decoration) => {
      return (_openBlock(), _createBlock(DecorListItem, {
        key: decoration.id,
        decoration: decoration,
        class: "item"
      }, null, 8, ["decoration"]))
    }), 128))
  ]))
}
}

})