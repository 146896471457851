import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UIText',
  props: {
  fontWeight: {
    type: Number,
    default: 600,
  },
  fontSize: {
    type: String,
    default: "1rem",
  },
  color: {
    type: String,
    default: "main-black",
  },
  fontFamily: {
    type: String,
    default: "proxima-nova-semibold",
  },
},
  setup(__props) {

const props = __props;

const setColor = computed(() => {
  return `var(--${props.color})`;
});

const setFontFamily = computed(() => {
  return `var(--${props.fontFamily})`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    style: _normalizeStyle({
      fontWeight: __props.fontWeight,
      fontSize: __props.fontSize,
      color: setColor.value,
      fontFamily: setFontFamily.value,
    })
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 4))
}
}

})