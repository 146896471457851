import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "averages-statistic" }
const _hoisted_2 = { class: "statistic-item" }
const _hoisted_3 = { class: "item-content" }
const _hoisted_4 = { class: "statistic-item" }
const _hoisted_5 = { class: "item-content" }

import { useStore } from "vuex";
import { computed, ref } from "vue";
import { IEstimate } from "@/modules/finances/types/finances.types";
import UIEditInput from "@/modules/finances/components/UIEditInput.vue";
import { Roles } from "@/enums/enums";
import { toast } from "vue3-toastify";
import {
  updateAverageBill,
  updateDaysInMonth,
  updateSalesPerDay,
} from "@/modules/finances/services/finances.services";
import { useRoute } from "vue-router";
import { useOutsideClick } from "@/composables/useOutsideClick";


export default /*@__PURE__*/_defineComponent({
  __name: 'AveragesStatistic',
  props: {},
  setup(__props) {

const props = __props;

const store = useStore();
const route = useRoute();

const editAverageBill = ref<string | number>("");
const editSalesPerDay = ref<string | number>("");
const editDaysInMonth = ref<string | number>("");
const showAverageBillInput = ref<boolean>(false);
const showSalesPerDayInput = ref<boolean>(false);
const showDaysInMonthInput = ref<boolean>(false);
const bills = ref(null);
const sales = ref(null);
const days = ref(null);

const spotId = localStorage.getItem("spotId");

const estimate = computed<IEstimate>(() => store.state.financesStore.estimate);
const role = computed<Roles>(() => store.state.authStore.role);

const formattedPrice = computed(() => {
  return function (price: number) {
    return new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "RUB",
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 0,
    }).format(price);
  };
});

const formatted = computed(() => {
  return function (price: number) {
    return new Intl.NumberFormat("ru-RU", {
      minimumFractionDigits: 0,
    }).format(price);
  };
});

const showAverageBill = () => {
  if (role.value !== Roles.manager) {
    return;
  }

  editAverageBill.value = estimate.value.averageBill;
  showAverageBillInput.value = true;
};

const showSalesPerDay = () => {
  if (role.value !== Roles.manager) {
    return;
  }

  editSalesPerDay.value = estimate.value.salesPerDay;
  showSalesPerDayInput.value = true;
};

const showDaysInMonth = () => {
  if (role.value !== Roles.manager) {
    return;
  }

  editDaysInMonth.value = estimate.value.daysInMonth;
  showDaysInMonthInput.value = true;
};

const updateBills = async () => {
  try {
    const response = await updateAverageBill(
      estimate.value.id,
      editAverageBill.value
    );
    showAverageBillInput.value = false;
    await store.dispatch("financesStore/fetchEstimate", {
      role: role.value,
      partnerId: route.params.id,
      spotId,
    });
  } catch (e) {
    toast.error("Не удалось обновить значение");
    console.log(e);
  }
};

const updateSales = async () => {
  try {
    const response = await updateSalesPerDay(
      estimate.value.id,
      editSalesPerDay.value
    );
    showSalesPerDayInput.value = false;
    await store.dispatch("financesStore/fetchEstimate", {
      role: role.value,
      partnerId: route.params.id,
      spotId,
    });
  } catch (e) {
    toast.error("Не удалось обновить значение");
    console.log(e);
  }
};

const updateDays = async () => {
  try {
    const response = await updateDaysInMonth(
      estimate.value.id,
      editDaysInMonth.value
    );
    showDaysInMonthInput.value = false;
    await store.dispatch("financesStore/fetchEstimate", {
      role: role.value,
      partnerId: route.params.id,
      spotId,
    });
  } catch (e) {
    toast.error("Не удалось обновить значение");
    console.log(e);
  }
};

useOutsideClick(bills, async () => {
  if (showAverageBillInput.value) {
    showAverageBillInput.value = false;
    await updateBills();
  }
});

useOutsideClick(sales, async () => {
  if (showSalesPerDayInput.value) {
    showSalesPerDayInput.value = false;
    await updateSales();
  }
});

useOutsideClick(days, async () => {
  if (showDaysInMonthInput.value) {
    showDaysInMonthInput.value = false;
    await updateDays();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "statistic-item",
      onKeydown: _withKeys(updateBills, ["enter"]),
      ref_key: "bills",
      ref: bills
    }, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "item-title" }, "Средний чек", -1)),
      (showAverageBillInput.value)
        ? (_openBlock(), _createBlock(UIEditInput, {
            key: 0,
            modelValue: editAverageBill.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((editAverageBill).value = $event)),
            class: "edit-input"
          }, null, 8, ["modelValue"]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            onClick: showAverageBill,
            class: "item-content"
          }, _toDisplayString(formattedPrice.value(estimate.value.averageBill)), 1))
    ], 544),
    _createElementVNode("div", {
      class: "statistic-item",
      onKeydown: _withKeys(updateSales, ["enter"]),
      ref_key: "sales",
      ref: sales
    }, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "item-title" }, "Продаж в день", -1)),
      (showSalesPerDayInput.value)
        ? (_openBlock(), _createBlock(UIEditInput, {
            key: 0,
            modelValue: editSalesPerDay.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((editSalesPerDay).value = $event)),
            class: "edit-input"
          }, null, 8, ["modelValue"]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            onClick: showSalesPerDay,
            class: "item-content"
          }, _toDisplayString(formatted.value(estimate.value.salesPerDay)), 1))
    ], 544),
    _createElementVNode("div", {
      class: "statistic-item",
      onKeydown: _withKeys(updateDays, ["enter"]),
      ref_key: "days",
      ref: days
    }, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "item-title" }, "Дней в месяце", -1)),
      (showDaysInMonthInput.value)
        ? (_openBlock(), _createBlock(UIEditInput, {
            key: 0,
            modelValue: editDaysInMonth.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((editDaysInMonth).value = $event)),
            class: "edit-input"
          }, null, 8, ["modelValue"]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            onClick: showDaysInMonth,
            class: "item-content"
          }, _toDisplayString(formatted.value(estimate.value.daysInMonth)), 1))
    ], 544),
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "item-title" }, "Ср. выручка в день", -1)),
      _createElementVNode("div", _hoisted_3, _toDisplayString(formattedPrice.value(estimate.value.averageIncomePerDay)), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "item-title" }, "Продаж в месяц", -1)),
      _createElementVNode("div", _hoisted_5, _toDisplayString(formatted.value(estimate.value.averageSalesPerMonth)), 1)
    ])
  ]))
}
}

})