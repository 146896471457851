import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "building-item-status" }
const _hoisted_2 = {
  key: 0,
  class: "link-container"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "status-container" }

import { useStore } from "vuex";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BuildingListItemStatus',
  props: {
  status: {
    type: String,
    required: true,
  },
  payLink: {
    type: String,
    default: "",
  },
},
  setup(__props) {

const store = useStore();

const role = computed(() => store.state.authStore.role);

const props = __props;

const setStatusClass = computed(() => {
  switch (props.status) {
    case "CREATED":
      return "done";

    case "NOT_PAID":
      return "payment";

    case "FORECAST_IN_PROGRESS":
      return "forecast";

    case "ON_MANAGER_CHECK":
      return "forecast";

    case "ON_INSTRUCTOR_CHECK":
      return "forecast";

    case "SELECTED":
      return "done";

    case "CANCELED":
      return "canceled";
  }
});

const setStatusText = computed(() => {
  switch (props.status) {
    case "CREATED":
      return "Создано";

    case "NOT_PAID":
      return "Требует оплаты";

    case "FORECAST_IN_PROGRESS":
      return "На согласовании";

    case "ON_MANAGER_CHECK":
      return "На согласовании";

    case "ON_INSTRUCTOR_CHECK":
      return "На согласовании";

    case "SELECTED":
      return "Выбрано";

    case "CANCELED":
      return "Отклонено";
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.status === 'NOT_PAID' && role.value === 'ROLE_PARTNER')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("a", {
            href: __props.payLink,
            class: "link",
            target: "_blank"
          }, "Оплатить", 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", {
        class: _normalizeClass(["status", setStatusClass.value])
      }, _toDisplayString(setStatusText.value), 3)
    ])
  ]))
}
}

})