import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "decorations-tab" }
const _hoisted_2 = {
  key: 1,
  class: "decorations-tab-main"
}
const _hoisted_3 = { class: "button-container" }

import UIButton from "@/modules/UI-kit/UIButton.vue";
import IconPlus from "@/icons/IconPlus.vue";
import DecorList from "@/modules/decorations/components/DecorList.vue";
import { computed, PropType } from "vue";
import { IDecoration } from "@/modules/decorations/types/decorations.types";
import EmptyDecorations from "@/modules/decorations/components/EmptyDecorations.vue";
import { useStore } from "vuex";
import { ModalTypes, Roles } from "@/enums/enums";


export default /*@__PURE__*/_defineComponent({
  __name: 'DecorationsTab',
  props: {
  decorations: {
    type: Array as PropType<Array<IDecoration>>,
    required: true,
  },
},
  setup(__props) {

const store = useStore();

const role = computed(() => store.state.authStore.role);

const props = __props;

const showWindow = () => {
  if (
    role.value === Roles.partner ||
    role.value === Roles.instructor ||
    role.value === Roles.executive
  ) {
    store.commit("modalsStore/showModal", {
      modalType: ModalTypes.addDecoration,
    });
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!__props.decorations.length)
      ? (_openBlock(), _createBlock(EmptyDecorations, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(DecorList, { decorations: __props.decorations }, null, 8, ["decorations"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(UIButton, {
              class: "button",
              onClick: showWindow
            }, {
              default: _withCtx(() => [
                _createVNode(IconPlus, { class: "plus" }),
                _cache[0] || (_cache[0] = _createTextVNode(" Добавить декор "))
              ]),
              _: 1
            })
          ])
        ]))
  ]))
}
}

})