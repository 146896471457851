import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navigation-list" }
const _hoisted_2 = { class: "list-item" }
const _hoisted_3 = { class: "list-item" }

import IconExit from "@/icons/navbar/IconExit.vue";
import IconHome from "@/icons/navbar/IconHome.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExecutiveNavbar',
  setup(__props) {

const store = useStore();
const router = useRouter();

const onExit = async () => {
  store.commit("authStore/clearAuthState");
  await router.push({ name: "Auth" });
};

const setIconColor = computed(() => {
  return function (isActive: boolean) {
    return isActive ? "main-black" : "main-gray";
  };
});

const setActiveText = computed(() => {
  return function (isActive: boolean) {
    return isActive ? "active-text" : "";
  };
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "link",
        "active-class": "active",
        to: { name: 'ExecutivePage' }
      }, {
        default: _withCtx(({ isActive }) => [
          _createVNode(IconHome, {
            class: "icon",
            color: setIconColor.value(isActive)
          }, null, 8, ["color"]),
          _createElementVNode("span", {
            class: _normalizeClass(["route-text", setActiveText.value(isActive)])
          }, " Главная ", 2)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", _hoisted_3, [
      _createElementVNode("button", {
        class: "link",
        onClick: onExit
      }, [
        _createVNode(IconExit, {
          class: "icon",
          color: setIconColor.value(_ctx.isActive)
        }, null, 8, ["color"]),
        _createElementVNode("span", {
          class: _normalizeClass(["route-text", setActiveText.value(_ctx.isActive)])
        }, " Выход ", 2)
      ])
    ])
  ]))
}
}

})