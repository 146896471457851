import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'IconHome',
  props: {
  width: {
    type: Number,
    default: 16,
  },
  height: {
    type: Number,
    default: 17,
  },
  color: {
    type: String,
    default: "main-black",
  },
},
  setup(__props) {

const props = __props;

const setColor = computed(() => {
  return `var(--${props.color})`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    width: __props.width,
    height: __props.height,
    viewBox: "0 0 16 17",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M8.61612 2.74316L13.4907 7.32047C13.8159 7.62584 14 8.04976 14 8.49312V13.1521C14 13.8965 13.3895 14.5 12.6364 14.5H9.36364C9.06239 14.5 8.81818 14.2586 8.81818 13.9608V10.9954C8.81818 10.5488 8.45187 10.1867 8 10.1867C7.54813 10.1867 7.18182 10.5488 7.18182 10.9954V13.9608C7.18182 14.2586 6.93761 14.5 6.63636 14.5H3.36364C2.61052 14.5 2 13.8965 2 13.1521V8.49312C2 8.04976 2.18412 7.62584 2.50932 7.32047L7.38388 2.74316C7.72915 2.41895 8.27085 2.41895 8.61612 2.74316ZM8 3.65192L3.26068 8.10224C3.15228 8.20403 3.09091 8.34534 3.09091 8.49312V13.1521C3.09091 13.301 3.21301 13.4217 3.36364 13.4217H6.09091V10.9954C6.09091 9.95327 6.94564 9.10842 8 9.10842C9.05436 9.10842 9.90909 9.95327 9.90909 10.9954V13.4217H12.6364C12.787 13.4217 12.9091 13.301 12.9091 13.1521V8.49312C12.9091 8.34534 12.8477 8.20403 12.7393 8.10224L8 3.65192Z",
      fill: setColor.value
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}
}

})