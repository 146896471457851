import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "progress-tab" }
const _hoisted_2 = { class: "events" }
const _hoisted_3 = { class: "stages" }

import {
  IProgressEvent,
  IProgressStage,
} from "@/modules/progress/types/progress.types";
import { PropType, ref } from "vue";
import FullEventList from "@/modules/progress/components/FullEventList.vue";
import LastEventList from "@/modules/progress/components/LastEventList.vue";
import ProgressStageList from "@/modules/progress/components/ProgressStageList.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgressTab',
  props: {
  lastHistoryItems: {
    type: Array as PropType<Array<IProgressEvent>>,
    required: true,
  },
  fullHistoryItems: {
    type: Array as PropType<Array<IProgressEvent>>,
    required: true,
  },
  stageList: {
    type: Array as PropType<Array<IProgressStage>>,
    required: true,
  },
},
  setup(__props) {

const isFullList = ref<boolean>(false);

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(LastEventList, {
        "last-history": __props.lastHistoryItems,
        "onUpdate:lastList": _cache[0] || (_cache[0] = ($event: any) => (isFullList.value = true))
      }, null, 8, ["last-history"]), [
        [_vShow, !isFullList.value]
      ]),
      _withDirectives(_createVNode(FullEventList, {
        "onUpdate:fullList": _cache[1] || (_cache[1] = ($event: any) => (isFullList.value = false)),
        "full-event-list": __props.fullHistoryItems
      }, null, 8, ["full-event-list"]), [
        [_vShow, isFullList.value]
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createVNode(ProgressStageList, { stages: __props.stageList }, null, 8, ["stages"])
    ], 512), [
      [_vShow, !isFullList.value]
    ])
  ]))
}
}

})