import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "surf-file-uploader" }
const _hoisted_2 = { class: "file-loader" }
const _hoisted_3 = { class: "actions" }
const _hoisted_4 = { class: "files" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "info" }
const _hoisted_7 = { class: "info-subtitle" }
const _hoisted_8 = ["onClick"]

import { ref, computed } from "vue";
import UIText from "@/modules/UI-kit/UIText.vue";
import IconClose from "@/icons/IconClose.vue";

export interface Props {
  title: string;
  subtitle?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UIFileUploader',
  props: {
    title: { default: "Файлы" },
    subtitle: {}
  },
  emits: ["update:files", "update:deleteFile"],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const files = ref([]);

const formattedFileSize = computed(() => {
  return function (fileSize) {
    let size = fileSize;
    let unit = "B";

    if (size > 1024) {
      size /= 1024;
      unit = "KB";

      if (size > 1024) {
        size /= 1024;
        unit = "MB";
      }
    }

    return size.toFixed(2) + " " + unit;
  };
});

const handlerFiles = (event) => {
  const fileList = event.target.files;
  const fileReadPromises = [];

  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i];
    const reader = new FileReader();

    const fileReadPromise = new Promise((resolve) => {
      reader.onload = (event) => {
        files.value.push({
          preview: event.target.result,
          file: file,
          name: file.name,
          size: file.size,
        });
        resolve();
      };
    });

    reader.readAsDataURL(file);
    fileReadPromises.push(fileReadPromise);
  }

  Promise.all(fileReadPromises).then(() => {
    const emitData = files.value.map((item) => item);
    emit("update:files", emitData);
  });
};

const deleteFile = (index) => {
  files.value.splice(index, 1);
  emit("update:deleteFile", files.value[index]);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "file-subscription" }, [
        _createElementVNode("p", { class: "not-files" }, "Выберите или перенесите фото"),
        _createElementVNode("p", { class: "format-subscription" }, "до 20 мб, PDF, JPG, PNG")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("input", {
          class: "input-file",
          type: "file",
          onChange: handlerFiles,
          multiple: ""
        }, null, 32)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(files.value, (file, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "file"
        }, [
          _createElementVNode("img", {
            src: file.preview,
            alt: "Preview Image",
            class: "image-preview"
          }, null, 8, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(UIText, {
              "font-weight": 600,
              "font-size": "15px"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(file.name), 1)
              ]),
              _: 2
            }, 1024),
            _createElementVNode("span", _hoisted_7, _toDisplayString(formattedFileSize.value(file.size)), 1)
          ]),
          _createElementVNode("button", {
            class: "delete",
            onClick: ($event: any) => (deleteFile(index))
          }, [
            _createVNode(IconClose)
          ], 8, _hoisted_8)
        ]))
      }), 128))
    ])
  ]))
}
}

})