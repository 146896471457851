import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "expenses-statistic",
  "data-testid": "expense-statistic"
}
const _hoisted_2 = { class: "statistic" }
const _hoisted_3 = { class: "counter" }
const _hoisted_4 = { class: "left text" }
const _hoisted_5 = { class: "right text" }
const _hoisted_6 = { class: "button-container" }

import { useStore } from "vuex";
import UIButton from "@/modules/UI-kit/UIButton.vue";
import IconPlus from "@/icons/IconPlus.vue";
import { ModalTypes } from "@/enums/enums";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpenseStatistic',
  props: {
  currentAmount: {
    type: Number,
    required: true,
  },
  expectedAmount: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const store = useStore();

// const estimate = computed(() => store.state.financesStore.estimate);

const props = __props;

const formattedPrice = computed(() => {
  return function (price: number) {
    return new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "RUB",
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 0,
    }).format(price);
  };
});

const showModal = () => {
  store.commit("modalsStore/showModal", {
    modalType: ModalTypes.addExpensesModal,
  });
};
const showModalHistory = () => {
  store.commit("modalsStore/showModal", {
    modalType: ModalTypes.historyExpensesModal,
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "title text" }, "вложения", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(formattedPrice.value(__props.currentAmount)), 1),
        _createElementVNode("div", _hoisted_5, "/ " + _toDisplayString(formattedPrice.value(__props.expectedAmount)), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(UIButton, {
        class: "button",
        onClick: showModal
      }, {
        default: _withCtx(() => [
          _createVNode(IconPlus, { class: "plus" }),
          _cache[1] || (_cache[1] = _createTextVNode(" Добавить расходы "))
        ]),
        _: 1
      }),
      _createVNode(UIButton, {
        class: "button-history",
        onClick: showModalHistory
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("История расходов")
        ])),
        _: 1
      })
    ])
  ]))
}
}

})