import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "buildings-list-item" }
const _hoisted_2 = { class: "building-item-info" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { class: "status-container" }

import { PropType } from "vue";
import { IBuilding } from "@/modules/buildings/types/buildings.types";
import { useStore } from "vuex";
import { ModalTypes } from "@/enums/enums";
import BuildingListItemStatus from "@/modules/buildings/components/BuildingListItemStatus.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BuildingsListItem',
  props: {
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: true,
  },
  status: {
    type: String,
    required: true,
  },
  building: {
    type: Object as PropType<IBuilding>,
    required: true,
  },
},
  setup(__props) {

const store = useStore();

const setCurrentBuilding = () => {
  store.commit("buildingsStore/setCurrentBuilding", props.building);
  store.commit("modalsStore/showModal", {
    modalType: ModalTypes.createBuildingModal,
  });
};

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "title",
        onClick: _withModifiers(setCurrentBuilding, ["stop"])
      }, _toDisplayString(__props.title), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(__props.subtitle), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(BuildingListItemStatus, {
        "pay-link": __props.building.paymentLink,
        status: __props.status
      }, null, 8, ["pay-link", "status"])
    ])
  ]))
}
}

})