import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "radio-buttons-container" }
const _hoisted_2 = { class: "title-container" }

import { computed, PropType } from "vue";
import UIRadioButton from "@/modules/UI-kit/UIRadioButton.vue";

interface IField {
  value: unknown;
  checked: boolean;
  label: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UIRadioButtons',
  props: {
  title: {
    type: String,
    default: "Выбрать",
  },
  modelValue: {
    type: Array as PropType<Array<IField>>,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  direction: {
    type: String,
    default: "column",
  },
  isEditable: {
    type: Boolean,
    default: true,
  },
  isSmallLabel: {
    type: Boolean,
    default: false,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const props = __props;

const setAsRow = computed(() => {
  if (props.direction === "row") {
    return "row";
  }

  return "column";
});

const isLabelSmall = computed(() => {
  return props.isSmallLabel ? "small-label" : "";
});

const setMargin = computed(() => {
  if (props.direction === "column") {
  }
});

const onChangeHandler = (field: IField) => {
  if (!props.isEditable) {
    return;
  }

  const res = props.modelValue.map((item) => {
    if (item.label === field.label) {
      return {
        ...item,
        checked: true,
      };
    }

    return { ...item, checked: false };
  });

  emits("update:modelValue", res);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass(["title", isLabelSmall.value])
      }, _toDisplayString(__props.title), 3)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["buttons", setAsRow.value])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.modelValue, (field) => {
        return (_openBlock(), _createBlock(UIRadioButton, {
          key: field.label,
          id: `${field.value}`,
          name: __props.name,
          checked: field.checked,
          label: field.label,
          value: `${field.value}`,
          "model-value": field.checked,
          "onUpdate:modelValue": ($event: any) => (onChangeHandler(field)),
          class: "radio-button"
        }, null, 8, ["id", "name", "checked", "label", "value", "model-value", "onUpdate:modelValue"]))
      }), 128))
    ], 2)
  ]))
}
}

})