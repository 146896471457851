import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, type PropType } from "vue";

export type AllowedTags =
  | "p"
  | "span"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6";


export default /*@__PURE__*/_defineComponent({
  __name: 'UIHeader',
  props: {
  fontWeight: {
    type: Number,
    default: 600,
  },
  fontSize: {
    type: String,
    default: "22px",
  },
  color: {
    type: String,
    default: "main-black",
  },
  fontFamily: {
    type: String,
    default: "proxima-nova-semibold",
  },
  isUppercase: {
    type: Boolean,
    default: false,
  },
  tag: {
    type: String as PropType<AllowedTags>,
    default: "h1",
  },
},
  setup(__props) {

const props = __props;

const setColor = computed(() => {
  return `var(--${props.color})`;
});

const setFontFamily = computed(() => {
  return `var(--${props.fontFamily})`;
});

const setUpperCase = computed(() => {
  return props.isUppercase ? "uppercase" : "";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.tag), {
    style: _normalizeStyle({
      fontWeight: __props.fontWeight,
      fontSize: __props.fontSize,
      color: setColor.value,
      fontFamily: setFontFamily.value,
      textTransform: setUpperCase.value,
    })
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["style"]))
}
}

})