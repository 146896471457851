<template>
  <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5042 2.10001C16.5195 2.10001 19.4001 5.37832 19.4001 9.63159V11.2312C19.4001 11.4599 19.5933 11.763 20.1206 12.3071C20.1882 12.3768 20.2519 12.4415 20.391 12.5823C21.4722 13.679 21.9001 14.3108 21.9001 15.3272C21.9001 15.7717 21.8482 16.1182 21.6324 16.5335C21.1762 17.411 20.2091 17.9 18.7633 17.9L16.8515 17.901C16.2223 20.2335 14.721 21.5002 12.5001 21.5002C10.2555 21.5002 8.74601 20.2064 8.1289 17.8261L8.1481 17.9H6.23694C4.74898 17.9 3.77144 17.3977 3.33476 16.4894C3.1438 16.0922 3.1001 15.7698 3.1001 15.3272C3.1001 14.3108 3.52797 13.679 4.60922 12.5823C4.74829 12.4415 4.81196 12.3768 4.87958 12.3071C5.40685 11.763 5.6001 11.4599 5.6001 11.2312V9.63159C5.6001 5.37997 8.48782 2.10001 12.5042 2.10001ZM14.9661 17.9019H10.0341C10.4901 19.1485 11.2759 19.7002 12.5001 19.7002C13.7243 19.7002 14.5101 19.1485 14.9661 17.9019ZM12.5042 3.90001C9.53656 3.90001 7.4001 6.32668 7.4001 9.63159V11.2312C7.4001 12.0838 7.01066 12.6946 6.17211 13.5598C6.09884 13.6354 6.02968 13.7057 5.89098 13.8461C5.1441 14.6036 4.9001 14.9639 4.9001 15.3272C4.9001 15.5193 4.91365 15.6192 4.95702 15.7095C5.06432 15.9327 5.39 16.1 6.23694 16.1H18.7633C19.5833 16.1 19.9166 15.9315 20.0353 15.7032C20.0847 15.6081 20.1001 15.5055 20.1001 15.3272C20.1001 14.9639 19.8561 14.6036 19.1092 13.8461C18.9705 13.7057 18.9014 13.6354 18.8281 13.5598C17.9895 12.6946 17.6001 12.0838 17.6001 11.2312V9.63159C17.6001 6.32436 15.4698 3.90001 12.5042 3.90001Z"
      fill="black"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
