import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useStore } from "vuex";
import IconClose from "@/icons/IconClose.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UICloseButton',
  emits: ["click"],
  setup(__props, { emit: __emit }) {

const store = useStore();

const emits = __emit;

const onClickHandler = () => {
  emits("click");
  store.commit("modalsStore/closeModal");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: "close-button",
    onClick: onClickHandler
  }, [
    _createVNode(IconClose, {
      size: 16,
      color: "black"
    })
  ]))
}
}

})