import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "empty-buildings" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = {
  key: 0,
  class: "subtitle-text"
}

import { computed } from "vue";
import { useStore } from "vuex";
import { ModalTypes, Roles } from "@/enums/enums";


export default /*@__PURE__*/_defineComponent({
  __name: 'EmptyEquipments',
  setup(__props) {

const store = useStore();

const role = computed(() => store.state.authStore.role);

const showWindow = () => {
  if (
    role.value === Roles.partner ||
    role.value === Roles.instructor ||
    role.value === Roles.executive
  ) {
    store.commit("modalsStore/showModal", {
      modalType: ModalTypes.addEquipment,
    });
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "title" }, [
        _createElementVNode("span", { class: "title-text" }, " Оборудования еще нет ")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        (
            role.value === _unref(Roles).partner ||
            role.value === _unref(Roles).instructor ||
            role.value === _unref(Roles).executive
          )
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _cache[0] || (_cache[0] = _createTextVNode(" Но вы можете ")),
              _createElementVNode("span", {
                class: "bold",
                onClick: showWindow
              }, " добавить "),
              _cache[1] || (_cache[1] = _createTextVNode(" оборудование "))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})