import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "side-modal" }

import { computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import { ModalTypes } from "@/enums/enums";
import CppStructureItem from "@/modules/cpp/components/CppStructureItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CppSideModal',
  setup(__props) {

const store = useStore();

const sideModalData = computed(() => store.state.modalsStore.sideModalData);

const onInputHandler = () => {};

const onRadioHandler = () => {};

const onDatePickerHandler = () => {};

const onFileHandler = () => {};

onUnmounted(() => {
  store.commit("modalsStore/showModal", {
    data: null,
    modalType: ModalTypes.cppMainPopup,
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sideModalData.value, (item, index) => {
      return (_openBlock(), _createBlock(CppStructureItem, {
        class: "structure-item",
        "structure-index": +index,
        item: item,
        key: index,
        "onUpdate:textValue": onInputHandler,
        "onUpdate:radioValue": onRadioHandler,
        "onUpdate:datePickerValue": onDatePickerHandler,
        "onUpdate:addFile": onFileHandler
      }, null, 8, ["structure-index", "item"]))
    }), 128))
  ]))
}
}

})