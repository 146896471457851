import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "coffee-shop-page" }

import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { Undefinable } from "@/types/types";
import SidebarPageHeader from "@/modules/layouts/components/headers/SidebarPageHeader.vue";
import UITabs from "@/modules/UI-kit/UITabs.vue";
import FinancesTab from "@/modules/finances/views/FinancesTab.vue";
import BuildingsTab from "@/modules/buildings/views/BuildingsTab.vue";
import EquipmentsTab from "@/modules/equipments/views/EquipmentsTab.vue";
import DecorationsTab from "@/modules/decorations/views/DecorationsTab.vue";
import ProgressTab from "@/modules/progress/views/ProgressTab.vue";
import DocumentsTab from "@/modules/documents/views/DocumentsTab.vue";

interface ITab {
  label: string;
  value: string;
  checked: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CoffeeShopPage',
  setup(__props) {

const spotId = localStorage.getItem("spotId");

const route = useRoute();
const router = useRouter();
const store = useStore();

const tabs = ref<ITab[]>([
  {
    label: "Финансы",
    value: "finances",
    checked: true,
  },
  {
    label: "Прогресс",
    value: "progress",
    checked: false,
  },
  {
    label: "Помещение",
    value: "buildings",
    checked: false,
  },
  {
    label: "Декор",
    value: "decorations",
    checked: false,
  },
  {
    label: "Оборудование",
    value: "equipment",
    checked: false,
  },
  {
    label: "Документы",
    value: "documents",
    checked: false,
  },
]);

const currentTab = computed<Undefinable<ITab>>(() =>
  tabs.value.find((tab: ITab) => tab.checked)
);

const role = computed<string>(() => store.state.authStore.role);

const initTabs = async () => {
  const activeTab = tabs.value.find((tab) => tab.value === route.params.value);

  if (!activeTab) {
    await router.replace({
      path: `/partner/shop/finances`,
    });
    return;
  }

  await router.replace({
    path: `/partner/shop/${activeTab?.value}`,
  });

  tabs.value = tabs.value.map((tab) => {
    if (tab.value === route.params.value) {
      return {
        ...tab,
        checked: true,
      };
    }
    return {
      ...tab,
      checked: false,
    };
  });
};

watch(
  () => tabs.value,
  async () => {
    const activeTab = tabs.value.find((tab) => tab.checked);
    await router.replace({
      path: `/partner/shop/${activeTab?.value}`,
    });
  }
);

onMounted(async () => {
  await initTabs();

  // partner information
  await store.dispatch("partnerProfileStore/fetchUserInfo", {
    partnerId: route?.params?.id,
    role: role.value,
    spotId,
  });

  // partner progress
  await store.dispatch("progressStore/fetchFullHistory", {
    partnerId: route.params?.id,
    role: role.value,
    spotId,
  });
  await store.dispatch("progressStore/fetchStages", {
    partnerId: route.params?.id,
    role: role.value,
    spotId,
  });
  await store.dispatch("progressStore/fetchLastHistory", {
    partnerId: route.params?.id,
    role: role.value,
    spotId,
  });

  // decorations
  await store.dispatch("decorationsStore/fetchDecorations", {
    partnerId: route.params?.id,
    role: role.value,
    spotId,
  });

  // equipments
  await store.dispatch("equipmentsStore/fetchEquipments", {
    partnerId: route.params?.id,
    role: role.value,
    spotId,
  });

  // buildings
  await store.dispatch("buildingsStore/fetchBuildings", {
    partnerId: route.params?.id,
    role: role.value,
    spotId,
  });

  // finances
  await store.dispatch("financesStore/fetchEstimate", {
    role: role.value,
    partnerId: route.params.id,
    spotId,
  });

  await store.dispatch("financesStore/fetchOutlays", {
    role: role.value,
    partnerId: route.params.id,
    spotId,
  });

  await store.dispatch("financesStore/fetchExpenses", {
    role: role.value,
    partnerId: route.params.id,
    spotId,
  });

  await store.dispatch("financesStore/fetchExpensesSum", {
    role: role.value,
    partnerId: route.params.id,
    spotId,
  });

  // documents
  await store.dispatch("documentsStore/fetchDocuments", {
    role: role.value,
    partnerId: route.params.id,
    spotId,
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(SidebarPageHeader, { class: "header" }, {
      title: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode(" Моя кофейня ")
      ])),
      _: 1
    }),
    _createVNode(UITabs, {
      class: "tabs",
      name: "tabs",
      modelValue: tabs.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tabs).value = $event))
    }, null, 8, ["modelValue"]),
    (currentTab.value?.value === 'progress')
      ? (_openBlock(), _createBlock(ProgressTab, {
          key: 0,
          "stage-list": _unref(store).state.progressStore.stages,
          "full-history-items": _unref(store).state.progressStore.fullHistory,
          "last-history-items": _unref(store).state.progressStore.lastHistory
        }, null, 8, ["stage-list", "full-history-items", "last-history-items"]))
      : _createCommentVNode("", true),
    (currentTab.value?.value === 'finances')
      ? (_openBlock(), _createBlock(FinancesTab, {
          key: 1,
          estimate: _unref(store).state.financesStore.estimate,
          expenses: _unref(store).state.financesStore.expenses,
          outlays: _unref(store).state.financesStore.outlays,
          sum: _unref(store).state.financesStore.sum
        }, null, 8, ["estimate", "expenses", "outlays", "sum"]))
      : _createCommentVNode("", true),
    (currentTab.value?.value === 'decorations')
      ? (_openBlock(), _createBlock(DecorationsTab, {
          key: 2,
          decorations: _unref(store).state.decorationsStore.decorations
        }, null, 8, ["decorations"]))
      : _createCommentVNode("", true),
    (currentTab.value?.value === 'equipment')
      ? (_openBlock(), _createBlock(EquipmentsTab, {
          key: 3,
          equipments: _unref(store).state.equipmentsStore.equipments
        }, null, 8, ["equipments"]))
      : _createCommentVNode("", true),
    (currentTab.value?.value === 'buildings')
      ? (_openBlock(), _createBlock(BuildingsTab, {
          key: 4,
          buildings: _unref(store).state.buildingsStore.buildings
        }, null, 8, ["buildings"]))
      : _createCommentVNode("", true),
    (currentTab.value?.value === 'documents')
      ? (_openBlock(), _createBlock(DocumentsTab, {
          key: 5,
          documents: _unref(store).state.documentsStore.documents
        }, null, 8, ["documents"]))
      : _createCommentVNode("", true)
  ]))
}
}

})