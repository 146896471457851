import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/modules/appeal/images/logo.svg'
import _imports_1 from '@/modules/appeal/images/done-icon.svg'


const _hoisted_1 = { class: "form-header" }
const _hoisted_2 = {
  class: "header",
  ref: "header"
}
const _hoisted_3 = {
  key: 0,
  class: "form-inputs"
}
const _hoisted_4 = { class: "form-item-container" }
const _hoisted_5 = { class: "form-item-container" }
const _hoisted_6 = {
  class: "form-item-container",
  ref: "container"
}
const _hoisted_7 = { class: "form-item-container" }
const _hoisted_8 = { class: "form-item-container" }
const _hoisted_9 = { class: "form-item-dropdown" }
const _hoisted_10 = { class: "form-item-dropdown" }
const _hoisted_11 = { class: "form-item-dropdown" }
const _hoisted_12 = { class: "form-item-dropdown" }
const _hoisted_13 = { class: "form-item-container" }
const _hoisted_14 = { class: "form-item-container" }
const _hoisted_15 = { class: "form-item-container" }
const _hoisted_16 = { class: "form-item-container" }
const _hoisted_17 = { class: "form-item-container" }
const _hoisted_18 = { class: "form-item-container" }
const _hoisted_19 = { class: "form-item-container" }
const _hoisted_20 = { class: "form-item-container subscription" }
const _hoisted_21 = { class: "button-container" }
const _hoisted_22 = {
  key: 1,
  class: "done"
}

import { computed, reactive, ref } from "vue";
import { createAppeal } from "@/modules/appeal/services/appeal.services";
import { toast } from "vue3-toastify";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  maxLength,
  minLength,
  sameAs,
} from "@vuelidate/validators";
import UIButton from "@/modules/UI-kit/UIButton.vue";
import UIInput from "@/modules/UI-kit/UIInput.vue";
import UIDropdown from "@/modules/UI-kit/UIDropdown.vue";
import UIPhoneInput from "@/modules/UI-kit/UIPhoneInput.vue";
import UICheckbox from "@/modules/UI-kit/UICheckbox.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppealForm',
  setup(__props) {

const isAppealDone = ref<boolean>(false);
const isLoading = ref<boolean>(false);

const appealState = reactive({
  name: "",
  surname: "",
  city: "",
  email: "",
  phoneNumber: "",
  reason: "",
  budget: "",
  monthAmount: "",
  format: "",
  sourceOfFunds: "",
  occupation: "",
  similarProjects: "",
  levelOfInterest: "",
  levelOfInterestAfter: "",
  interestedBuilding: "",
  socialLink: "",
  reformat: false,
  agreed: false,
});

const onDropDownHandler = (option: any, optionKey: string) => {
  // @ts-ignore
  options[optionKey].currentOption.label = option.label;
  // @ts-ignore
  appealState[optionKey] = option.label;
};

const appealStateRules = {
  name: {
    required,
    maxLength: maxLength(40),
    minLength: minLength(2),
  },
  surname: {
    required,
    maxLength: maxLength(40),
    minLength: minLength(2),
  },
  city: {
    required,
    maxLength: maxLength(40),
    minLength: minLength(2),
  },
  email: { required, email },
  phoneNumber: { required },
  reason: { required },
  budget: { required },
  monthAmount: { required },
  format: { required },
  sourceOfFunds: { required },
  occupation: { required },
  similarProjects: { required },
  levelOfInterest: { required },
  levelOfInterestAfter: { required },
  interestedBuilding: { required },
  socialLink: { required },
  agreed: { required, checked: sameAs(true) },
};

const $v = useVuelidate(appealStateRules, appealState);

const disableButton = computed<boolean>(() => {
  return $v.value.$invalid;
});

const options = reactive({
  budget: {
    currentOption: { label: "Выберите вариант из списка" },
    options: [
      { label: "От 10 млн до 12 млн. руб." },
      { label: "От 12 млн до 15 млн. руб." },
      { label: "От 15 млн" },
    ],
  },
  monthAmount: {
    currentOption: { label: "Выберите вариант из списка" },
    options: [
      { label: "до 200 тыс. руб." },
      { label: "от 200 до 400 тыс. руб." },
      { label: "от 400 до 600 тыс. руб." },
    ],
  },
  format: {
    currentOption: { label: "Выберите вариант из списка" },
    options: [
      {
        label: "Стрит-ритейл - помещение с отдельным входом в жилом доме",
      },
      { label: "Отдельно стоящее здание" },
      { label: "ТЦ/БЦ" },
      { label: "Готов рассмотреть любой вариант" },
    ],
  },
});

const onSubmitAppeal = async () => {
  try {
    isLoading.value = true;

    await createAppeal({
      name: appealState.name,
      surname: appealState.surname,
      city: appealState.city,
      email: appealState.email,
      phoneNumber: appealState.phoneNumber,
      reasonToOpen: appealState.reason,
      investAmount: options.budget.currentOption.label,
      expectedIncome: options.monthAmount.currentOption.label,
      openFormat: options.format.currentOption.label,
      sourceOfFunds: appealState.sourceOfFunds,
      occupation: appealState.occupation,
      similarProjects: appealState.similarProjects,
      levelOfInterest: appealState.levelOfInterest,
      levelOfInterestAfter: appealState.levelOfInterestAfter,
      interestedBuilding: appealState.interestedBuilding,
      socialLink: appealState.socialLink,
      reformat: true,
      agreed: true,
    });

    isAppealDone.value = true;
  } catch (e: any) {
    toast.error(e.response.data.errors[0]);
  } finally {
    isLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    class: "appeal-form",
    onSubmit: _cache[17] || (_cache[17] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[18] || (_cache[18] = _createElementVNode("img", {
        src: _imports_0,
        alt: "logo"
      }, null, -1)),
      _createElementVNode("h1", _hoisted_2, "ОТКРЫТИЕ СПОТА SURF COFFEE", 512)
    ]),
    (!isAppealDone.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(UIInput, {
              "data-testid": "appeal-name-input",
              modelValue: appealState.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((appealState.name) = $event)),
              "place-holder": "Имя",
              "max-length": "40",
              class: "form-item"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(UIInput, {
              "data-testid": "appeal-surname-input",
              modelValue: appealState.surname,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((appealState.surname) = $event)),
              "place-holder": "Фамилия",
              "max-length": "40",
              class: "form-item"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(UIInput, {
              "data-testid": "appeal-city-input",
              modelValue: appealState.city,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((appealState.city) = $event)),
              "place-holder": "Город",
              "max-length": "40",
              class: "form-item"
            }, null, 8, ["modelValue"])
          ], 512),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(UIInput, {
              "data-testid": "appeal-email-input",
              modelValue: appealState.email,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((appealState.email) = $event)),
              "place-holder": "Email",
              type: "email",
              class: "form-item"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(UIPhoneInput, {
              "data-testid": "appeal-phone-input",
              modelValue: appealState.phoneNumber,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((appealState.phoneNumber) = $event)),
              "place-holder": "Номер телефона",
              class: "form-item"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(UIInput, {
              "data-testid": "appeal-reason-input",
              modelValue: appealState.reason,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((appealState.reason) = $event)),
              "place-holder": "Почему хотите открыть Surf Coffee®?",
              type: "text",
              class: "form-item"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(UIDropdown, {
              "data-testid": "appeal-budget-dropdown",
              ref: "dropdown",
              "selected-option": options.budget.currentOption,
              options: options.budget.options,
              label: "На какой бюджет открытия рассчитываете?",
              "onUpdate:selectedOption": _cache[6] || (_cache[6] = ($event: any) => (onDropDownHandler($event, 'budget'))),
              class: "form-item"
            }, null, 8, ["selected-option", "options"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(UIDropdown, {
              "data-testid": "appeal-month-amount-dropdown",
              "selected-option": options.monthAmount.currentOption,
              options: options.monthAmount.options,
              label: "Какой ожидаете ежемесячный доход?",
              "onUpdate:selectedOption": _cache[7] || (_cache[7] = ($event: any) => (onDropDownHandler($event, 'monthAmount'))),
              class: "form-item"
            }, null, 8, ["selected-option", "options"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(UIDropdown, {
              "data-testid": "appeal-format-dropdown",
              "selected-option": options.format.currentOption,
              options: options.format.options,
              label: "Предприятие какого формата хотели бы открыть?",
              "onUpdate:selectedOption": _cache[8] || (_cache[8] = ($event: any) => (onDropDownHandler($event, 'format'))),
              class: "form-item"
            }, null, 8, ["selected-option", "options"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(UIInput, {
              "data-testid": "appeal-source-of-founds-input",
              modelValue: appealState.sourceOfFunds,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((appealState.sourceOfFunds) = $event)),
              "place-holder": "Источник привлекаемых средств для открытия",
              type: "text",
              class: "form-item"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(UIInput, {
              "data-testid": "appeal-occupation-input",
              modelValue: appealState.occupation,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((appealState.occupation) = $event)),
              "place-holder": "Род деятельности. Чем сейчас занимаетесь?",
              type: "text",
              class: "form-item"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(UIInput, {
              "data-testid": "appeal-similar-projects-input",
              modelValue: appealState.similarProjects,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((appealState.similarProjects) = $event)),
              "place-holder": "Есть ли у вас кофейня или аналогичный проект в сфере общественного питания?",
              type: "text",
              class: "form-item"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(UIInput, {
              "data-testid": "appeal-level-of-interest-input",
              modelValue: appealState.levelOfInterest,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((appealState.levelOfInterest) = $event)),
              "place-holder": "Насколько глубоко вы готовы погружаться в процессы открытия и запуска предприятия?",
              type: "text",
              class: "form-item"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(UIInput, {
              "data-testid": "appeal-level-of-interest-after-input",
              modelValue: appealState.levelOfInterestAfter,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((appealState.levelOfInterestAfter) = $event)),
              "place-holder": "Насколько глубоко готовы погружаться в процессы после запуска предприятия? Планируемая роль?",
              type: "text",
              class: "form-item"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(UIInput, {
              "data-testid": "appeal-interested-building-input",
              modelValue: appealState.interestedBuilding,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((appealState.interestedBuilding) = $event)),
              "place-holder": "Есть ли у вас на примете помещение для открытия?",
              type: "text",
              class: "form-item"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createVNode(UIInput, {
              "data-testid": "appeal-social-link-input",
              modelValue: appealState.socialLink,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((appealState.socialLink) = $event)),
              "place-holder": "Ссылка на ваш Instagram* или другую соц. сеть.",
              type: "text",
              class: "form-item"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(UICheckbox, {
              id: "1",
              name: "1",
              checked: appealState.agreed,
              value: `${appealState.agreed}`,
              modelValue: appealState.agreed,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((appealState.agreed) = $event)),
              class: "agreed-checkbox"
            }, {
              default: _withCtx(() => _cache[19] || (_cache[19] = [
                _createTextVNode(" Указывая свои данные вы даёте своё согласие на обработку вашей персональной информации согласно условиям "),
                _createElementVNode("a", {
                  class: "href",
                  target: "_blank",
                  href: "https://www.surfcoffee.ru/useragreement"
                }, "пользовательского соглашения. ", -1)
              ])),
              _: 1
            }, 8, ["checked", "value", "modelValue"]),
            _cache[20] || (_cache[20] = _createElementVNode("p", { class: "remark" }, " *Instagram принадлежит компании Meta, признанной экстремистской организацией и запрещенной в Российской Федерации. Чек-бокс. ", -1))
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createVNode(UIButton, {
              "data-testid": "appeal-submit-button",
              onClick: onSubmitAppeal,
              "is-loading": isLoading.value,
              "is-disabled": disableButton.value
            }, {
              default: _withCtx(() => _cache[21] || (_cache[21] = [
                _createElementVNode("span", { class: "button-text" }, " Отправить заявку ", -1)
              ])),
              _: 1
            }, 8, ["is-loading", "is-disabled"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[22] || (_cache[22] = [
          _createElementVNode("h2", { class: "done-header" }, "Заявка успешно отправлена!", -1),
          _createElementVNode("span", { class: "done-description" }, " С вами свяжется менеджер в течение трех дней ", -1),
          _createElementVNode("img", {
            class: "done-icon",
            src: _imports_1,
            alt: "done"
          }, null, -1)
        ])))
  ], 32))
}
}

})