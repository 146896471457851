import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill", "stroke"]

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'IconExit',
  props: {
  width: {
    type: Number,
    default: 16,
  },
  height: {
    type: Number,
    default: 17,
  },
  color: {
    type: String,
    default: "main-black",
  },
},
  setup(__props) {

const props = __props;

const setColor = computed(() => {
  return `var(--${props.color})`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    width: __props.width,
    height: __props.height,
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M5.68185 1L7.36364 1C7.71509 1 8 1.28491 8 1.63636C8 1.98782 7.71509 2.27273 7.36364 2.27273H5.70909C4.9858 2.27273 4.4816 2.27322 4.08906 2.30529C3.70396 2.33676 3.4827 2.39541 3.31511 2.48081C2.95589 2.66384 2.66384 2.95589 2.48081 3.31511C2.39541 3.4827 2.33676 3.70396 2.30529 4.08906C2.27322 4.4816 2.27273 4.9858 2.27273 5.70909V10.2909C2.27273 11.0142 2.27322 11.5184 2.30529 11.9109C2.33676 12.296 2.39541 12.5173 2.48081 12.6849C2.66384 13.0441 2.95589 13.3362 3.31511 13.5192C3.4827 13.6046 3.70396 13.6632 4.08906 13.6947C4.4816 13.7268 4.9858 13.7273 5.70909 13.7273H7.36364C7.71509 13.7273 8 14.0122 8 14.3636C8 14.7151 7.71509 15 7.36364 15H5.68183C4.99216 15 4.43589 15 3.98542 14.9632C3.52161 14.9253 3.11422 14.8452 2.7373 14.6532C2.13861 14.3482 1.65185 13.8614 1.3468 13.2627C1.15475 12.8858 1.07469 12.4784 1.03679 12.0146C0.999989 11.5641 0.999994 11.0078 1 10.3182V5.68185C0.999994 4.99217 0.999989 4.43589 1.03679 3.98542C1.07469 3.52161 1.15475 3.11422 1.3468 2.7373C1.65185 2.13861 2.13861 1.65185 2.7373 1.3468C3.11422 1.15475 3.52161 1.07469 3.98542 1.03679C4.43589 0.999989 4.99217 0.999994 5.68185 1ZM11.3682 5.00457C11.6167 4.75605 12.0196 4.75605 12.2682 5.00457L14.8136 7.55002C14.933 7.66936 15 7.83123 15 8C15 8.16877 14.933 8.33064 14.8136 8.44998L12.2682 10.9954C12.0196 11.2439 11.6167 11.2439 11.3682 10.9954C11.1197 10.7469 11.1197 10.344 11.3682 10.0955L12.8273 8.63636H6.72727C6.37582 8.63636 6.09091 8.35145 6.09091 8C6.09091 7.64855 6.37582 7.36364 6.72727 7.36364H12.8273L11.3682 5.90452C11.1197 5.65601 11.1197 5.25308 11.3682 5.00457Z",
      fill: setColor.value,
      stroke: setColor.value,
      "stroke-width": "0.3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}
}

})