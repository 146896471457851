import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "empty-buildings" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  key: 0,
  class: "subtitle"
}

import { useStore } from "vuex";
import { ModalTypes, Roles } from "@/enums/enums";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EmptyBuildings',
  setup(__props) {

const store = useStore();

const role = computed(() => store.state.authStore.role);

const openCreateBuildingModal = () => {
  store.commit("modalsStore/showModal", {
    modalType: ModalTypes.createBuildingModal,
    modalData: {},
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "title" }, [
        _createElementVNode("span", { class: "title-text" }, " Помещений еще нет ")
      ], -1)),
      (role.value === _unref(Roles).partner || role.value === _unref(Roles).executive)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", { class: "subtitle-text" }, [
              _cache[0] || (_cache[0] = _createTextVNode(" Но вы можете ")),
              _createElementVNode("span", {
                class: "bold",
                onClick: openCreateBuildingModal
              }, " предложить помещение "),
              _cache[1] || (_cache[1] = _createTextVNode(" через опрос "))
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})