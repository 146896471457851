import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../images/backgrounds/investment-background.svg'


const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "invested" }
const _hoisted_3 = { class: "amount-container" }
const _hoisted_4 = { class: "amount-text" }

import { useStore } from "vuex";
import { computed } from "vue";
import { ModalTypes } from "@/enums/enums";


export default /*@__PURE__*/_defineComponent({
  __name: 'InvestmentProgress',
  setup(__props) {

const store = useStore();

const expectedAmount = computed(() => {
  const amount = store.state.financesStore.expectedAmount ?? 0;
  return parseFloat(amount.toFixed(2));
});

const currentAmount = computed(() => {
  const amount = store.state.financesStore.currentAmount ?? 0;
  return parseFloat(amount.toFixed(2));
});

const showModalHistory = () => {
  store.commit("modalsStore/showModal", {
    modalType: ModalTypes.historyExpensesModal,
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "investment-progress",
    onClick: showModalHistory
  }, [
    _cache[1] || (_cache[1] = _createElementVNode("img", {
      src: _imports_0,
      alt: "background",
      class: "background"
    }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(currentAmount.value) + " ₽", 1),
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "invested-mark" }, "Вложения", -1))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, "/ " + _toDisplayString(expectedAmount.value) + " ₽", 1)
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "progress-bar" }, [
      _createElementVNode("div", { class: "main-progress-line" }, [
        _createElementVNode("div", { class: "invested-line" })
      ])
    ], -1))
  ]))
}
}

})