import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "buildings-list" }

import { computed, PropType } from "vue";
import { IBuilding } from "@/modules/buildings/types/buildings.types";
import BuildingsListItem from "@/modules/buildings/components/BuildingsListItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BuildingsList',
  props: {
  buildings: {
    type: Array as PropType<Array<IBuilding>>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const setTitle = computed(() => {
  return (building: IBuilding) => {
    return `${building.city}, ${building.street} ${building.buildingNumber}`;
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.buildings, (building) => {
      return (_openBlock(), _createBlock(BuildingsListItem, {
        class: "building-item",
        building: building,
        key: building.id,
        status: building.status,
        subtitle: `${building.price} Р`,
        title: setTitle.value(building)
      }, null, 8, ["building", "status", "subtitle", "title"]))
    }), 128))
  ]))
}
}

})