import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "stage-item" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "tasks" }

import { computed, PropType } from "vue";
import { IStage } from "@/modules/partner-tasks/types/partner-tasks.types";
import { TaskStatus } from "@/modules/partner-tasks/enums/partner-tasks.enums";

import UIHeader from "@/modules/UI-kit/UIHeader.vue";
import PartnerTask from "@/modules/partner-tasks/components/PartnerTask.vue";
import EmptyTasks from "@/modules/partner-tasks/components/EmptyTasks.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PartnerStageItem',
  props: {
  stage: {
    type: Object as PropType<IStage>,
    required: true,
  },
  activeTaskType: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const getVisibleTasks = computed(() => {
  switch (props.activeTaskType) {
    case TaskStatus.ALL:
      return props.stage?.tasks;

    case TaskStatus.ACTIVE:
      return props.stage.tasks.filter((task) => {
        if (task.status === TaskStatus.ACTIVE) {
          return task;
        }
      });

    case TaskStatus.NOT_STARTED:
      return props.stage.tasks.filter((task) => {
        if (task.status === TaskStatus.NOT_STARTED) {
          return task;
        }
      });

    case TaskStatus.ENDED:
      return props.stage.tasks.filter((task) => {
        if (task.status === TaskStatus.ENDED) {
          return task;
        }
      });

    case TaskStatus.SUSPENDED:
      return props.stage.tasks.filter((task) => {
        if (task.status === TaskStatus.SUSPENDED) {
          return task;
        }
      });
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(UIHeader, {
        "font-family": "inter",
        "font-weight": 600,
        "font-size": "22px"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(__props.stage.name), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getVisibleTasks.value, (task) => {
        return (_openBlock(), _createBlock(PartnerTask, {
          task: task,
          key: task.taskId,
          class: "task"
        }, null, 8, ["task"]))
      }), 128))
    ]),
    (!getVisibleTasks.value.length)
      ? (_openBlock(), _createBlock(EmptyTasks, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}
}

})