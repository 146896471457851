import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = { class: "tabs" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "main-tab" }
const _hoisted_6 = { class: "section address" }
const _hoisted_7 = { class: "flex-row" }
const _hoisted_8 = { class: "section building" }
const _hoisted_9 = { class: "flex-row" }
const _hoisted_10 = { class: "section facade" }
const _hoisted_11 = { class: "flex-row" }
const _hoisted_12 = { class: "section enter" }
const _hoisted_13 = { class: "flex-row" }
const _hoisted_14 = { class: "section place" }
const _hoisted_15 = { class: "flex-row" }
const _hoisted_16 = { class: "full-input" }
const _hoisted_17 = { class: "flex-row" }
const _hoisted_18 = { class: "flex-row" }
const _hoisted_19 = { class: "flex-row" }
const _hoisted_20 = { class: "flex-row" }
const _hoisted_21 = { class: "section price" }
const _hoisted_22 = { class: "flex-row" }
const _hoisted_23 = { class: "flex-row" }
const _hoisted_24 = { class: "section traffic" }
const _hoisted_25 = { class: "flex-row" }
const _hoisted_26 = { class: "full-input" }
const _hoisted_27 = {
  key: 0,
  class: "section photos",
  style: { marginBottom: '1rem' }
}
const _hoisted_28 = { class: "files-container" }
const _hoisted_29 = { class: "section photos" }
const _hoisted_30 = { class: "photos-input" }
const _hoisted_31 = { class: "full-input" }
const _hoisted_32 = { class: "button-container" }
const _hoisted_33 = { class: "additional-tab" }
const _hoisted_34 = { class: "section place" }
const _hoisted_35 = { class: "flex-row" }
const _hoisted_36 = { class: "flex-row" }
const _hoisted_37 = { class: "flex-row" }
const _hoisted_38 = { class: "section design" }
const _hoisted_39 = { class: "flex-row" }
const _hoisted_40 = { class: "flex-row" }
const _hoisted_41 = { class: "flex-row" }
const _hoisted_42 = { class: "flex-row" }
const _hoisted_43 = { class: "flex-row" }
const _hoisted_44 = { class: "flex-row" }
const _hoisted_45 = { class: "section equipment" }
const _hoisted_46 = { class: "full-input" }
const _hoisted_47 = { class: "flex-row" }
const _hoisted_48 = { class: "flex-row" }
const _hoisted_49 = { class: "flex-row" }
const _hoisted_50 = { class: "flex-row" }
const _hoisted_51 = { class: "section decor" }
const _hoisted_52 = { class: "full-input" }
const _hoisted_53 = { class: "button-container" }

import {
  computed,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { toast } from "vue3-toastify";
import {
  instructorUpdateBuilding,
  partnerCreateBuilding,
  partnerUpdateBuilding,
} from "@/modules/buildings/services/buildings.services";
import { camelToSnakeCase } from "@/helpers/camel-to-snake";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { IBuilding } from "@/modules/buildings/types/buildings.types";
import { Roles } from "@/enums/enums";
import { Nullable } from "@/types/types";

import UIInput from "@/modules/UI-kit/UIInput.vue";
import UIDropdown from "@/modules/UI-kit/UIDropdown.vue";
import UIRadioButtons from "@/modules/UI-kit/UIRadioButtons.vue";
import UIButton from "@/modules/UI-kit/UIButton.vue";
import UIFileDownload from "@/modules/UI-kit/UIFileDownload.vue";
import UIFileUploader from "@/modules/buildings/components/UIFileUploader.vue";
import {
  executiveCreateBuilding,
  updatePartnerBuilding,
} from "@/modules/executive/services/executive.services";

interface FileObj {
  file: File;
  name: string;
  preview: Blob;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BuildingModal',
  setup(__props) {

const route = useRoute();
const store = useStore();

const spotId = localStorage.getItem("spotId") || "";

// SWITCHER LOGIC
const switcherTabs = ref<any>([
  {
    label: "основная информация",
    value: "main",
    checked: true,
  },
  {
    label: "дополнительно",
    value: "additional",
    checked: false,
  },
]);

const activeTab = ref({
  label: "основная информация",
  value: "main",
  checked: true,
});

const buildingModal = ref<Nullable<HTMLElement>>(null);

const files = ref<File[]>([]);

const disableUpdate = computed(() => {
  if (
    (currentBuilding.value.id && role.value === Roles.partner) ||
    role.value === Roles.instructor ||
    role.value === Roles.executive
  ) {
    return false;
  }

  return !(!currentBuilding.value.id && role.value === Roles.partner);
});
const role = computed(() => store.state.authStore.role);
const currentBuilding = computed<IBuilding>(
  () => store.state.buildingsStore.currentBuilding
);
const setActiveTab = computed(() => {
  return function (tab: any) {
    return tab.checked ? "active" : "";
  };
});
const setFinalButtonText = computed(() => {
  return currentBuilding.value?.id
    ? "Обновить помещение"
    : "Добавить помещение";
});
const setTitleText = computed(() => {
  return currentBuilding.value?.id ? "Помещение" : "Новое помещение";
});

const disableNextButton = computed(() => {
  return !(
    state.city &&
    state.street &&
    state.buildingNumber &&
    state.square &&
    state.height &&
    state.price &&
    state.vacationForRepair &&
    state.electricityPower &&
    state.weekdayTraffic &&
    state.weekendTraffic
  );
});

const disableFinishButton = computed(() => {
  return !(
    state.city &&
    state.street &&
    state.buildingNumber &&
    state.price &&
    state.square &&
    state.height &&
    state.vacationForRepair &&
    state.electricityPower &&
    state.weekdayTraffic &&
    state.weekendTraffic &&
    (state.livingSpaces || state.livingSpaces === false)
  );
});

const nextTab = () => {
  activeTab.value = {
    label: "дополнительно",
    value: "additional",
    checked: true,
  };

  switcherTabs.value = switcherTabs.value.map((item) => ({
    ...item,
    checked: item.value === "additional",
  }));

  if ("scrollTop" in buildingModal.value) {
    buildingModal.value.scrollTop = 0;
  }
};

const toggleActiveTab = (tab: any) => {
  switcherTabs.value = switcherTabs.value.map((item: any) => {
    if (item.value === tab.value) {
      activeTab.value = {
        ...item,
        checked: true,
      };

      return {
        ...item,
        checked: true,
      };
    }

    return {
      ...item,
      checked: false,
    };
  });
};

// MODAL DATA
const disableActions = ref<boolean>(false);

const state = reactive({
  city: "",
  street: "",
  buildingNumber: "",
  height: "",
  square: "",
  electricityPower: "",
  price: "",
  vacationForRepair: "",
  trafficDescription: "",
  placeComment: "",
  summerAreaComment: "",
  smokingSignsComment: "",
  smokingAreaComment: "",
  materialRestrictionsComment: "",
  outdoorsMenuComment: "",
  cctvComment: "",
  wishesComment: "",
  equipmentComment: "",
  musicEventsComment: "",
  hangerForGuestsComment: "",
  employeeWardrobeComment: "",
  placement: "",
  facadeGlazing: "",
  doorReplaceable: "",
  partitionsDemolished: "",
  ventilation: "",
  ownerDialog: "",
  weekdayTraffic: "",
  weekendTraffic: "",
  culturalHeritage: "",
  coffeeHouseFormat: "",
  additionalAdElements: "",
  summerArea: "",
  cctv: "",
  separated: null,
  summerTerrace: null,
  wheelchairAccessible: null,
  sewerage: null,
  newBuilding: null,
  insects: null,
  badSmell: null,
  lumpSum: null,
  basement: null,
  livingSpaces: null,
  smokingSigns: null,
  smokingArea: null,
  materialRestrictions: null,
  signboardDesignCode: null,
  outdoorsMenu: null,
  placeForm: null,
  clothes: null,
  shelvesForSale: null,
  musicEvents: null,
  hangerForGuests: null,
  employeeWardrobe: null,
});

// FILES HANDLER
const updateFiles = (newFiles: FileObj[]) => {
  files.value = newFiles.map((fileObj) => {
    return fileObj.file;
  });
};

// DROPDOWNS DATA HANDLING
const onDropdownSelect = (
  option: any,
  fields: any,
  currentFieldKey: string,
  bodyKey: string
) => {
  if (
    (currentBuilding.value.id && role.value === Roles.instructor) ||
    role.value === Roles.partner ||
    role.value === Roles.executive
  ) {
    fields.forEach((item: any) => (item.checked = false));
    const field = fields.find((item: any) => item.value === option.value);
    // @ts-ignore
    dropdownCurrentFields[currentFieldKey] = { ...field };
    field.checked = true;
    // @ts-ignore
    state[bodyKey] = field.value;
  }
};

// RADIO FIELDS DATA HANDLING

const onUpdateRadio = (event: any, key: string) => {
  if (
    (currentBuilding.value.id && role.value === Roles.instructor) ||
    role.value === Roles.partner ||
    role.value === Roles.executive
  ) {
    // @ts-ignore
    radioFields[key] = event;
    const field = event.find((item: any) => {
      if (item.checked) {
        return item;
      }
    });
    // @ts-ignore
    state[key] = field.value;
  }
};

const generateBody = () => {
  const formData = new FormData();

  for (const key of Object.keys(state)) {
    const snakeKey = camelToSnakeCase(key);
    // @ts-ignore
    formData.append(snakeKey, state[key]);
  }

  files.value.forEach((file, index) => {
    console.log(`image${index}`);
    formData.append(`image${index}`, file);
  });

  return formData;
};

const createBuilding = async () => {
  try {
    let response;
    const body = generateBody();

    if (currentBuilding.value?.id) {
      if (role.value === Roles.partner) {
        response = await partnerUpdateBuilding(body, currentBuilding.value.id);
      } else if (role.value === Roles.instructor) {
        response = await instructorUpdateBuilding(
          body,
          currentBuilding.value.id
        );
      } else if (role.value === Roles.executive) {
        response = await updatePartnerBuilding(body, currentBuilding.value.id);
      }
    } else {
      if (role.value === Roles.partner) {
        response = await partnerCreateBuilding(body, spotId);
      } else {
        response = await executiveCreateBuilding(
          route.params?.id,
          body,
          spotId
        );
      }
    }

    store.commit("modalsStore/closeModal");
    await store.dispatch("buildingsStore/fetchBuildings", {
      partnerId: route.params?.id,
      role: role.value,
      spotId,
    });
    const toastText = currentBuilding.value?.id
      ? "Помещение успешно обновлено"
      : "Помещение успешно создано";
    toast.success(toastText);
  } catch (e: any) {
    toast.error("Не удалось создать помещение");
  }
};

const initCurrentBuilding = () => {
  if (!currentBuilding.value?.id) {
    return;
  }

  const empty = {
    checked: false,
    label: "Не выбрано",
    value: "Не выбрано",
  };

  // text-fields
  state.city = currentBuilding.value.city;
  state.buildingNumber = currentBuilding.value.buildingNumber;
  state.street = currentBuilding.value.street;
  state.price = `${currentBuilding.value.price}`;
  state.height = currentBuilding.value.height
    ? `${currentBuilding.value.height}`
    : "";
  state.electricityPower = currentBuilding.value?.electricityPower
    ? `${currentBuilding.value.electricityPower}`
    : "";
  state.square = currentBuilding.value?.square
    ? `${currentBuilding.value?.square}`
    : "";
  state.vacationForRepair = currentBuilding.value.vacationForRepair;
  state.trafficDescription = currentBuilding.value?.trafficDescription || "";
  state.placeComment = currentBuilding.value?.placeComment || "";
  state.smokingAreaComment = currentBuilding.value?.smokingAreaComment || "";
  state.smokingSignsComment = currentBuilding.value?.smokingSignsComment || "";
  state.summerAreaComment = currentBuilding.value?.summerAreaComment || "";
  state.materialRestrictionsComment =
    currentBuilding.value?.materialRestrictionsComment || "";
  state.outdoorsMenuComment = currentBuilding.value?.outdoorsMenuComment || "";
  state.cctvComment = currentBuilding.value?.cctvComment || "";
  state.musicEventsComment = currentBuilding.value?.musicEventsComment || "";
  state.hangerForGuestsComment =
    currentBuilding.value?.hangerForGuestsComment || "";
  state.wishesComment = currentBuilding.value?.wishesComment || "";
  state.employeeWardrobeComment =
    currentBuilding.value?.employeeWardrobeComment || "";
  state.equipmentComment = currentBuilding.value?.equipmentComment || "";

  // dropdowns
  state.placement = currentBuilding.value?.placement || "";
  dropdownCurrentFields.placement =
    dropDownFields.placement.find((item) => item.value === state.placement) ||
    empty;
  state.facadeGlazing = currentBuilding.value?.facadeGlazing || "";
  dropdownCurrentFields.facadeGlazing =
    dropDownFields.facadeGlazing.find(
      (item) => item.value === state.facadeGlazing
    ) || empty;
  state.doorReplaceable = currentBuilding.value?.doorReplaceable || "";
  dropdownCurrentFields.doorReplaceable =
    dropDownFields.doorReplaceable.find(
      (item) => item.value === state.doorReplaceable
    ) || empty;
  state.partitionsDemolished =
    currentBuilding.value?.partitionsDemolished || "";
  dropdownCurrentFields.partitionsDemolished =
    dropDownFields.partitionsDemolished.find(
      (item) => item.value === state.partitionsDemolished
    ) || empty;
  state.ventilation = currentBuilding.value?.ventilation || "";
  dropdownCurrentFields.ventilation =
    dropDownFields.ventilation.find(
      (item) => item.value === state.ventilation
    ) || empty;
  state.ownerDialog = currentBuilding.value?.ownerDialog || "";
  dropdownCurrentFields.ownerDialog =
    dropDownFields.ownerDialog.find(
      (item) => item.value === state.ownerDialog
    ) || empty;
  state.weekendTraffic = currentBuilding.value?.weekendTraffic || "";
  dropdownCurrentFields.weekendTraffic =
    dropDownFields.weekendTraffic.find(
      (item) => item.value === state.weekendTraffic
    ) || empty;
  state.weekdayTraffic = currentBuilding.value?.weekdayTraffic || "";
  dropdownCurrentFields.weekdayTraffic =
    dropDownFields.weekdayTraffic.find(
      (item) => item.value === state.weekdayTraffic
    ) || empty;
  state.culturalHeritage = currentBuilding.value?.culturalHeritage || "";
  dropdownCurrentFields.culturalHeritage =
    dropDownFields.culturalHeritage.find(
      (item) => item.value === state.culturalHeritage
    ) || empty;
  state.coffeeHouseFormat = currentBuilding.value?.coffeeHouseFormat || "";
  dropdownCurrentFields.coffeeHouseFormat =
    dropDownFields.coffeeHouseFormat.find(
      (item) => item.value === state.coffeeHouseFormat
    ) || empty;
  state.summerArea = currentBuilding.value?.summerArea || "";
  dropdownCurrentFields.summerArea =
    dropDownFields.summerArea.find((item) => item.value === state.summerArea) ||
    empty;
  state.additionalAdElements =
    currentBuilding.value?.additionalAdElements || "";
  dropdownCurrentFields.additionalAdElements =
    dropDownFields.additionalAdElements.find(
      (item) => item.value === state.additionalAdElements
    ) || empty;
  state.cctv = currentBuilding.value?.cctv || "";
  dropdownCurrentFields.cctv =
    dropDownFields.cctv.find((item) => item.value === state.cctv) || empty;

  // radios
  state.placeForm = currentBuilding.value.placeForm;
  radioFields.placeForm = radioFields.placeForm.map((item) => ({
    ...item,
    checked: state.placeForm === item.value,
  }));
  state.separated = currentBuilding.value.separated;
  radioFields.separated = radioFields.separated.map((item) => ({
    ...item,
    checked: state.separated === item.value,
  }));
  state.summerTerrace = currentBuilding.value.summerTerrace;
  radioFields.summerTerrace = radioFields.summerTerrace.map((item) => ({
    ...item,
    checked: state.summerTerrace === item.value,
  }));
  state.wheelchairAccessible = currentBuilding.value.wheelchairAccessible;
  radioFields.wheelchairAccessible = radioFields.wheelchairAccessible.map(
    (item) => ({
      ...item,
      checked: state.wheelchairAccessible === item.value,
    })
  );
  state.sewerage = currentBuilding.value.sewerage;
  radioFields.sewerage = radioFields.sewerage.map((item) => ({
    ...item,
    checked: state.sewerage === item.value,
  }));
  state.newBuilding = currentBuilding.value.newBuilding;
  radioFields.newBuilding = radioFields.newBuilding.map((item) => ({
    ...item,
    checked: state.newBuilding === item.value,
  }));
  state.insects = currentBuilding.value.insects;
  radioFields.insects = radioFields.insects.map((item) => ({
    ...item,
    checked: state.insects === item.value,
  }));
  state.badSmell = currentBuilding.value.badSmell;
  radioFields.badSmell = radioFields.badSmell.map((item) => ({
    ...item,
    checked: state.badSmell === item.value,
  }));
  state.lumpSum = currentBuilding.value.lumpSum;
  radioFields.lumpSum = radioFields.lumpSum.map((item) => ({
    ...item,
    checked: state.lumpSum === item.value,
  }));
  state.basement = currentBuilding.value.basement;
  radioFields.basement = radioFields.basement.map((item) => ({
    ...item,
    checked: state.basement === item.value,
  }));
  state.livingSpaces = currentBuilding.value.livingSpaces;
  radioFields.livingSpaces = radioFields.livingSpaces.map((item) => ({
    ...item,
    checked: state.livingSpaces === item.value,
  }));
  state.smokingSigns = currentBuilding.value.smokingSigns;
  radioFields.smokingSigns = radioFields.smokingSigns.map((item) => ({
    ...item,
    checked: state.smokingSigns === item.value,
  }));
  state.smokingArea = currentBuilding.value.smokingArea;
  radioFields.smokingArea = radioFields.smokingArea.map((item) => ({
    ...item,
    checked: state.smokingArea === item.value,
  }));
  state.materialRestrictions = currentBuilding.value.materialRestrictions;
  radioFields.materialRestrictions = radioFields.materialRestrictions.map(
    (item) => ({
      ...item,
      checked: state.materialRestrictions === item.value,
    })
  );
  state.signboardDesignCode = currentBuilding.value.signboardDesignCode;
  radioFields.signboardDesignCode = radioFields.signboardDesignCode.map(
    (item) => ({
      ...item,
      checked: state.signboardDesignCode === item.value,
    })
  );
  state.outdoorsMenu = currentBuilding.value.outdoorsMenu;
  radioFields.outdoorsMenu = radioFields.outdoorsMenu.map((item) => ({
    ...item,
    checked: state.outdoorsMenu === item.value,
  }));
  state.clothes = currentBuilding.value.clothes;
  radioFields.clothes = radioFields.clothes.map((item) => ({
    ...item,
    checked: state.clothes === item.value,
  }));
  state.shelvesForSale = currentBuilding.value.shelvesForSale;
  radioFields.shelvesForSale = radioFields.shelvesForSale.map((item) => ({
    ...item,
    checked: state.shelvesForSale === item.value,
  }));
  state.musicEvents = currentBuilding.value.musicEvents;
  radioFields.musicEvents = radioFields.musicEvents.map((item) => ({
    ...item,
    checked: state.musicEvents === item.value,
  }));
  state.hangerForGuests = currentBuilding.value.hangerForGuests;
  radioFields.hangerForGuests = radioFields.hangerForGuests.map((item) => ({
    ...item,
    checked: state.hangerForGuests === item.value,
  }));
  state.employeeWardrobe = currentBuilding.value.employeeWardrobe;
  radioFields.employeeWardrobe = radioFields.employeeWardrobe.map((item) => ({
    ...item,
    checked: state.employeeWardrobe === item.value,
  }));
};

onMounted(() => {
  initCurrentBuilding();
});

onUnmounted(() => {
  store.commit("buildingsStore/clearCurrentBuilding");
});

// DATA
const dropDownFields = reactive({
  placement: [
    { checked: false, label: "В жилом доме", value: "В жилом доме" },
    {
      checked: false,
      label: "В не жилом доме",
      value: "В не жилом доме",
    },
    {
      checked: false,
      label: "В торговом центре на первом этаже",
      value: "В торговом центре на первом этаже",
    },
    { checked: false, label: "Другое", value: "Другое" },
    {
      checked: false,
      label: "В бизнес, административном или офисном центре",
      value: "В бизнес, административном или офисном центре",
    },
    {
      checked: false,
      label: "В торговом центре на втором этаже и выше",
      value: "В торговом центре на втором этаже и выше",
    },
  ],
  facadeGlazing: [
    { checked: false, label: "Мало окон", value: "Мало окон" },
    {
      checked: false,
      label: "Достаточно, большие просторные окна",
      value: "Достаточно, большие просторные окна",
    },
    {
      checked: false,
      label: "Средние окна, обычное остекление",
      value: "Средние окна, обычное остекление",
    },
  ],
  doorReplaceable: [
    {
      checked: false,
      label: "Да, можно только заменить",
      value: "Да, можно только заменить",
    },
    { checked: false, label: "Нет", value: "Нет" },
    {
      checked: false,
      label: "Да, можно заменить расширить, увеличить",
      value: "Да, можно заменить расширить, увеличить",
    },
  ],
  partitionsDemolished: [
    { checked: false, label: "Нет", value: "Нет" },
    {
      checked: false,
      label: "Нет перегородок",
      value: "Нет перегородок",
    },
    { checked: false, label: "Да", value: "Да" },
  ],
  ventilation: [
    {
      checked: false,
      label: "Да, естественная",
      value: "Да, естественная",
    },
    { checked: false, label: "Нет", value: "Нет" },
    {
      checked: false,
      label: "Да, вывод на кровлю",
      value: "Да, вывод на кровлю",
    },
    {
      checked: false,
      label: "Да, принудительная система вытяжки и притока",
      value: "Да, принудительная система вытяжки и притока",
    },
  ],
  ownerDialog: [
    {
      checked: false,
      label: "Негативные, не заинтересован в сотрудничестве",
      value: "Негативные, не заинтересован в сотрудничестве",
    },
    { checked: false, label: "Нейтральные", value: "Нейтральные" },
    {
      checked: false,
      label:
        "Положительные, присутствует понимание взаимотношений в формате win/win",
      value:
        "Положительные, присутствует понимание взаимотношений в формате win/win",
    },
  ],
  weekdayTraffic: [
    { checked: false, label: "До 40 человек", value: "До 40 человек" },
    {
      checked: false,
      label: "От 40 до 70 человек",
      value: "От 40 до 70 человек",
    },
    {
      checked: false,
      label: "От 90 человек и выше",
      value: "От 90 человек и выше",
    },
  ],
  weekendTraffic: [
    { checked: false, label: "До 40 человек", value: "До 40 человек" },
    {
      checked: false,
      label: "От 40 до 70 человек",
      value: "От 40 до 70 человек",
    },
    {
      checked: false,
      label: "От 90 человек и выше",
      value: "От 90 человек и выше",
    },
  ],
  vacationForRepair: [
    { checked: false, label: "До месяца", value: "До месяца" },
    {
      checked: false,
      label: "От 3 месяцев и более",
      value: "От 3 месяцев и более",
    },
    { checked: false, label: "От месяца до 3", value: "От месяца до 3" },
  ],
  culturalHeritage: [
    { label: "Нет", value: "Нет", checked: false },
    {
      label: "Да, только фасады",
      value: "Да, только фасады",
      checked: false,
    },
    {
      label: "Да, элементы интерьера",
      value: "Да, элементы интерьера",
      checked: false,
    },
  ],
  coffeeHouseFormat: [
    { label: "Кофейня", value: "Кофейня", checked: true },
    { label: "Пиццерия", value: "Пиццерия", checked: false },
    { label: "Пекарня", value: "Пекарня", checked: false },
    { label: "Остров", value: "Остров", checked: false },
  ],
  summerArea: [
    { label: "Да", value: "Да", checked: true },
    { label: "Нет", value: "Нет", checked: false },
    { label: "Веранда", value: "Веранда", checked: false },
  ],
  additionalAdElements: [
    { label: "кронштейны", value: "кронштейны", checked: true },
    {
      label: "рекламные элементы на фасадах",
      value: "рекламные элементы на фасадах",
      checked: false,
    },
    {
      label: "оформление окон",
      value: "оформление окон",
      checked: false,
    },
  ],
  cctv: [
    { label: "только на бар", value: "только на бар", checked: true },
    { label: "в зале", value: "в зале", checked: false },
    { label: "не нужно", value: "не нужно", checked: false },
  ],
});

const dropdownCurrentFields = reactive({
  placement: { checked: false, label: "Не выбрано", value: "Не выбрано" },
  facadeGlazing: {
    checked: false,
    label: "Не выбрано",
    value: "Не выбрано",
  },
  doorReplaceable: {
    checked: false,
    label: "Не выбрано",
    value: "Не выбрано",
  },
  partitionsDemolished: {
    checked: false,
    label: "Не выбрано",
    value: "Не выбрано",
  },
  ventilation: {
    checked: false,
    label: "Не выбрано",
    value: "Не выбрано",
  },
  ownerDialog: {
    checked: false,
    label: "Не выбрано",
    value: "Не выбрано",
  },
  weekdayTraffic: {
    checked: false,
    label: "Не выбрано",
    value: "Не выбрано",
  },
  weekendTraffic: {
    checked: false,
    label: "Не выбрано",
    value: "Не выбрано",
  },
  vacationForRepair: {
    checked: false,
    label: "Не выбрано",
    value: "Не выбрано",
  },
  culturalHeritage: {
    checked: false,
    label: "Не выбрано",
    value: "Не выбрано",
  },
  coffeeHouseFormat: {
    checked: false,
    label: "Не выбрано",
    value: "Не выбрано",
  },
  summerArea: {
    checked: false,
    label: "Не выбрано",
    value: "Не выбрано",
  },
  additionalAdElements: {
    checked: false,
    label: "Не выбрано",
    value: "Не выбрано",
  },
  cctv: {
    checked: false,
    label: "Не выбрано",
    value: "Не выбрано",
  },
});

const radioFields = reactive({
  separated: [
    {
      label: "да",
      value: true,
      checked: false,
    },
    {
      label: "нет",
      value: false,
      checked: false,
    },
  ],
  summerTerrace: [
    {
      label: "да",
      value: true,
      checked: false,
    },
    {
      label: "нет",
      value: false,
      checked: false,
    },
  ],
  wheelchairAccessible: [
    {
      label: "да",
      value: true,
      checked: false,
    },
    {
      label: "нет",
      value: false,
      checked: false,
    },
  ],
  placeForm: [
    {
      label: "простая",
      value: true,
      checked: false,
    },
    {
      label: "сложная",
      value: false,
      checked: false,
    },
  ],
  sewerage: [
    {
      label: "имеется",
      value: true,
      checked: false,
    },
    {
      label: "отсутствует",
      value: false,
      checked: false,
    },
  ],
  newBuilding: [
    {
      label: "первичное",
      value: true,
      checked: false,
    },
    {
      label: "вторичное",
      value: false,
      checked: false,
    },
  ],
  insects: [
    {
      label: "да",
      value: true,
      checked: false,
    },
    {
      label: "нет",
      value: false,
      checked: false,
    },
  ],
  badSmell: [
    {
      label: "да",
      value: true,
      checked: false,
    },
    {
      label: "нет",
      value: false,
      checked: false,
    },
  ],
  lumpSum: [
    {
      label: "была",
      value: true,
      checked: false,
    },
    {
      label: "не было",
      value: false,
      checked: false,
    },
  ],
  basement: [
    {
      label: "да",
      value: true,
      checked: false,
    },
    {
      label: "нет",
      value: false,
      checked: false,
    },
  ],
  livingSpaces: [
    {
      label: "да",
      value: true,
      checked: false,
    },
    {
      label: "нет",
      value: false,
      checked: false,
    },
  ],
  smokingSigns: [
    {
      label: "требуется",
      value: true,
      checked: false,
    },
    {
      label: "не требуется",
      value: false,
      checked: false,
    },
  ],
  smokingArea: [
    {
      label: "требуется",
      value: true,
      checked: false,
    },
    {
      label: "не требуется",
      value: false,
      checked: false,
    },
  ],
  outdoorsMenu: [
    {
      label: "требуется",
      value: true,
      checked: false,
    },
    {
      label: "не требуется",
      value: false,
      checked: false,
    },
  ],
  clothes: [
    {
      label: "требуется",
      value: true,
      checked: false,
    },
    {
      label: "не требуется",
      value: false,
      checked: false,
    },
  ],
  shelvesForSale: [
    {
      label: "требуется",
      value: true,
      checked: false,
    },
    {
      label: "не требуется",
      value: false,
      checked: false,
    },
  ],
  musicEvents: [
    {
      label: "требуется",
      value: true,
      checked: false,
    },
    {
      label: "не требуется",
      value: false,
      checked: false,
    },
  ],
  hangerForGuests: [
    {
      label: "требуется",
      value: true,
      checked: false,
    },
    {
      label: "не требуется",
      value: false,
      checked: false,
    },
  ],
  employeeWardrobe: [
    {
      label: "требуется",
      value: true,
      checked: false,
    },
    {
      label: "не требуется",
      value: false,
      checked: false,
    },
  ],
  materialRestrictions: [
    {
      label: "имеется",
      value: true,
      checked: false,
    },
    {
      label: "не имеется",
      value: false,
      checked: false,
    },
  ],
  signboardDesignCode: [
    {
      label: "имеется",
      value: true,
      checked: false,
    },
    {
      label: "не имеется",
      value: false,
      checked: false,
    },
  ],
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "building-modal",
    ref_key: "buildingModal",
    ref: buildingModal
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(setTitleText.value), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(switcherTabs.value, (tab) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["tab", setActiveTab.value(tab)]),
          key: tab.value,
          onClick: ($event: any) => (toggleActiveTab(tab))
        }, _toDisplayString(tab.label), 11, _hoisted_4))
      }), 128))
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _cache[55] || (_cache[55] = _createElementVNode("h3", { class: "section-title-no-margin" }, "адрес", -1)),
        _cache[56] || (_cache[56] = _createElementVNode("span", { class: "required-mark" }, " Обязательные поля обозначены звёздочкой (*) ", -1)),
        _createVNode(UIInput, {
          class: "full-input",
          modelValue: state.city,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((state.city) = $event)),
          "place-holder": "Город*",
          style: { marginTop: '1rem' },
          readonly: disableUpdate.value
        }, null, 8, ["modelValue", "readonly"]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(UIInput, {
            class: "half-input",
            modelValue: state.street,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((state.street) = $event)),
            "place-holder": "Улица*",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"]),
          _createVNode(UIInput, {
            class: "half-input",
            modelValue: state.buildingNumber,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((state.buildingNumber) = $event)),
            "place-holder": "Номер дома*",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[57] || (_cache[57] = _createElementVNode("h3", { class: "section-title" }, "здание", -1)),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(UIDropdown, {
            class: "half-input",
            "selected-option": dropdownCurrentFields.placement,
            options: dropDownFields.placement,
            "is-disabled": disableUpdate.value,
            label: "Где расположено помещение?",
            "onUpdate:selectedOption": _cache[3] || (_cache[3] = ($event: any) => (
              onDropdownSelect(
                $event,
                dropDownFields.placement,
                'placement',
                'placement'
              )
            ))
          }, null, 8, ["selected-option", "options", "is-disabled"]),
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "1",
            "model-value": radioFields.separated,
            direction: "row",
            title: "Это отдельно стоящие здание?",
            "is-small-label": true,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (onUpdateRadio($event, 'separated')))
          }, null, 8, ["model-value"])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[58] || (_cache[58] = _createElementVNode("h3", { class: "section-title" }, "фасад", -1)),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(UIDropdown, {
            class: "half-input",
            "to-top": false,
            "selected-option": dropdownCurrentFields.facadeGlazing,
            options: dropDownFields.facadeGlazing,
            "is-disabled": disableUpdate.value,
            label: "Остекленение на фасаде",
            "onUpdate:selectedOption": _cache[5] || (_cache[5] = ($event: any) => (
              onDropdownSelect(
                $event,
                dropDownFields.facadeGlazing,
                'facadeGlazing',
                'facadeGlazing'
              )
            ))
          }, null, 8, ["selected-option", "options", "is-disabled"]),
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "2",
            "model-value": radioFields.summerTerrace,
            direction: "row",
            title: "Возможно ли разместить летнюю посадку?",
            "is-small-label": true,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (onUpdateRadio($event, 'summerTerrace')))
          }, null, 8, ["model-value"])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[59] || (_cache[59] = _createElementVNode("h3", { class: "section-title" }, "вход", -1)),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(UIDropdown, {
            class: "half-input",
            label: "Возможна ли замена входной двери?",
            "to-top": false,
            "is-disabled": disableUpdate.value,
            "selected-option": dropdownCurrentFields.doorReplaceable,
            options: dropDownFields.doorReplaceable,
            "onUpdate:selectedOption": _cache[7] || (_cache[7] = ($event: any) => (
              onDropdownSelect(
                $event,
                dropDownFields.doorReplaceable,
                'doorReplaceable',
                'doorReplaceable'
              )
            ))
          }, null, 8, ["is-disabled", "selected-option", "options"]),
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "3",
            "model-value": radioFields.wheelchairAccessible,
            direction: "row",
            "is-small-label": true,
            title: "Есть ли возможность войти в помещение с детской коляской и людям с ОВ?",
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (onUpdateRadio($event, 'wheelchairAccessible')))
          }, null, 8, ["model-value"])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[60] || (_cache[60] = _createElementVNode("h3", { class: "section-title" }, "помещение", -1)),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "99",
            "model-value": radioFields.placeForm,
            direction: "row",
            "is-small-label": true,
            title: "Какой формы помещение?",
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (onUpdateRadio($event, 'placeForm')))
          }, null, 8, ["model-value"])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(UIDropdown, {
            class: "half-input",
            label: "Если помещение разделено перегородками, можно ли их снести?",
            "to-top": false,
            "is-disabled": disableUpdate.value,
            "selected-option": dropdownCurrentFields.partitionsDemolished,
            options: dropDownFields.partitionsDemolished,
            "onUpdate:selectedOption": _cache[10] || (_cache[10] = ($event: any) => (
              onDropdownSelect(
                $event,
                dropDownFields.partitionsDemolished,
                'partitionsDemolished',
                'partitionsDemolished'
              )
            ))
          }, null, 8, ["is-disabled", "selected-option", "options"])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createVNode(UIInput, {
            class: "half-input",
            modelValue: state.square,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((state.square) = $event)),
            "place-holder": "Площадь помещения (м²)*",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"]),
          _createVNode(UIInput, {
            class: "half-input",
            modelValue: state.height,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((state.height) = $event)),
            "place-holder": "Высота потолка в помещении (м)*",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "4",
            "model-value": radioFields.sewerage,
            direction: "row",
            "is-small-label": true,
            title: "Наличие канализации",
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (onUpdateRadio($event, 'sewerage')))
          }, null, 8, ["model-value"]),
          _createVNode(UIInput, {
            class: "half-input",
            modelValue: state.electricityPower,
            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((state.electricityPower) = $event)),
            "place-holder": "Мощность доступных электросетей (Вт)*",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createVNode(UIDropdown, {
            class: "half-input",
            label: "Наличие вентиляционной системы",
            "to-top": false,
            "is-disabled": disableUpdate.value,
            "selected-option": dropdownCurrentFields.ventilation,
            options: dropDownFields.ventilation,
            "onUpdate:selectedOption": _cache[15] || (_cache[15] = ($event: any) => (
              onDropdownSelect(
                $event,
                dropDownFields.ventilation,
                'ventilation',
                'ventilation'
              )
            ))
          }, null, 8, ["is-disabled", "selected-option", "options"]),
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "5",
            "model-value": radioFields.newBuilding,
            direction: "row",
            "is-small-label": true,
            title: "Тип использования помещения",
            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (onUpdateRadio($event, 'newBuilding')))
          }, null, 8, ["model-value"])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "6",
            "model-value": radioFields.insects,
            direction: "row",
            "is-small-label": true,
            title: "При осмотре были грызуны/насекомые?",
            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (onUpdateRadio($event, 'insects')))
          }, null, 8, ["model-value"]),
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "7",
            "model-value": radioFields.badSmell,
            direction: "row",
            "is-small-label": true,
            title: "Был ли неприятный запах?",
            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (onUpdateRadio($event, 'badSmell')))
          }, null, 8, ["model-value"])
        ])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _cache[61] || (_cache[61] = _createElementVNode("h3", { class: "section-title" }, "стоимость", -1)),
        _createElementVNode("div", _hoisted_22, [
          _createVNode(UIInput, {
            modelValue: state.price,
            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((state.price) = $event)),
            "place-holder": "Стоимость аренды помещения в месяц (₽)*",
            class: "half-input",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"]),
          _createVNode(UIInput, {
            modelValue: state.vacationForRepair,
            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((state.vacationForRepair) = $event)),
            "place-holder": "Срок каникул на время ремонта (месяцев)*",
            class: "half-input",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "8",
            "model-value": radioFields.lumpSum,
            direction: "row",
            "is-small-label": true,
            title: "Выплата при заключении договора",
            "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (onUpdateRadio($event, 'lumpSum')))
          }, null, 8, ["model-value"]),
          _createVNode(UIDropdown, {
            class: "half-input",
            label: "Ощущения от общения с собственником",
            "to-top": false,
            "is-disabled": disableUpdate.value,
            "selected-option": dropdownCurrentFields.ownerDialog,
            options: dropDownFields.ownerDialog,
            "onUpdate:selectedOption": _cache[22] || (_cache[22] = ($event: any) => (
              onDropdownSelect(
                $event,
                dropDownFields.ownerDialog,
                'ownerDialog',
                'ownerDialog'
              )
            ))
          }, null, 8, ["is-disabled", "selected-option", "options"])
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _cache[62] || (_cache[62] = _createElementVNode("h3", { class: "section-title" }, "трафик", -1)),
        _createElementVNode("div", _hoisted_25, [
          _createVNode(UIDropdown, {
            class: "half-input",
            label: "В будни за 10 мин мимо помещения*",
            "to-top": false,
            "is-disabled": disableUpdate.value,
            "selected-option": dropdownCurrentFields.weekdayTraffic,
            options: dropDownFields.weekdayTraffic,
            "onUpdate:selectedOption": _cache[23] || (_cache[23] = ($event: any) => (
              onDropdownSelect(
                $event,
                dropDownFields.weekdayTraffic,
                'weekdayTraffic',
                'weekdayTraffic'
              )
            ))
          }, null, 8, ["is-disabled", "selected-option", "options"]),
          _createVNode(UIDropdown, {
            class: "half-input",
            label: "В выходные за 10 мин мимо помещения*",
            "to-top": false,
            "is-disabled": disableUpdate.value,
            "selected-option": dropdownCurrentFields.weekendTraffic,
            options: dropDownFields.weekendTraffic,
            "onUpdate:selectedOption": _cache[24] || (_cache[24] = ($event: any) => (
              onDropdownSelect(
                $event,
                dropDownFields.weekendTraffic,
                'weekendTraffic',
                'weekendTraffic'
              )
            ))
          }, null, 8, ["is-disabled", "selected-option", "options"])
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createVNode(UIInput, {
            modelValue: state.trafficDescription,
            "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((state.trafficDescription) = $event)),
            "place-holder": "Преимущественный контингент трафика*",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ])
      ]),
      (currentBuilding.value?.files.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
            _cache[63] || (_cache[63] = _createElementVNode("h3", { class: "section-title" }, "загруженные файлы", -1)),
            _createElementVNode("div", _hoisted_28, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentBuilding.value?.files, (file) => {
                return (_openBlock(), _createBlock(UIFileDownload, {
                  key: file.id,
                  "preview-link": file.getLink,
                  "download-link": file.getLink,
                  title: file.fileName
                }, null, 8, ["preview-link", "download-link", "title"]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_29, [
        _cache[64] || (_cache[64] = _createElementVNode("h3", { class: "section-title" }, "фото", -1)),
        _cache[65] || (_cache[65] = _createElementVNode("p", { class: "section-description" }, " Фото входной группы, фасад всего здания, окон, входной двери, улицы с двух сторон, внутри помещения со стороны входа, фото высоты потолков, фото всего помещения внутри, место для летней посадки ", -1)),
        _createElementVNode("div", _hoisted_30, [
          _createVNode(UIFileUploader, {
            "onUpdate:files": updateFiles,
            style: { marginBottom: '1rem' }
          })
        ]),
        _createElementVNode("div", _hoisted_31, [
          _createVNode(UIInput, {
            modelValue: state.placeComment,
            "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((state.placeComment) = $event)),
            "place-holder": "Комментарий, если что-то упустили",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ])
      ]),
      _createElementVNode("div", _hoisted_32, [
        _createVNode(UIButton, {
          onClick: nextTab,
          "button-style": "solid",
          "is-disabled": disableNextButton.value
        }, {
          default: _withCtx(() => _cache[66] || (_cache[66] = [
            _createTextVNode(" Далее ")
          ])),
          _: 1
        }, 8, ["is-disabled"])
      ])
    ], 512), [
      [_vShow, activeTab.value.value === 'main']
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_33, [
      _createElementVNode("div", _hoisted_34, [
        _cache[67] || (_cache[67] = _createElementVNode("h3", { class: "section-title" }, "помещение", -1)),
        _createElementVNode("div", _hoisted_35, [
          _createVNode(UIDropdown, {
            class: "half-input",
            label: "Здание является культурным наследием?",
            "to-top": false,
            "is-disabled": disableUpdate.value,
            "selected-option": dropdownCurrentFields.culturalHeritage,
            options: dropDownFields.culturalHeritage,
            "onUpdate:selectedOption": _cache[27] || (_cache[27] = ($event: any) => (
              onDropdownSelect(
                $event,
                dropDownFields.culturalHeritage,
                'culturalHeritage',
                'culturalHeritage'
              )
            ))
          }, null, 8, ["is-disabled", "selected-option", "options"]),
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "9",
            "model-value": radioFields.basement,
            direction: "row",
            "is-small-label": true,
            title: "Имеется ли подвал или технический этаж?",
            "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => (onUpdateRadio($event, 'basement')))
          }, null, 8, ["model-value"])
        ]),
        _createElementVNode("div", _hoisted_36, [
          _createVNode(UIDropdown, {
            class: "half-input",
            label: "Предпочитаемый формат кофейни",
            "to-top": false,
            "is-disabled": disableUpdate.value,
            "selected-option": dropdownCurrentFields.coffeeHouseFormat,
            options: dropDownFields.coffeeHouseFormat,
            "onUpdate:selectedOption": _cache[29] || (_cache[29] = ($event: any) => (
              onDropdownSelect(
                $event,
                dropDownFields.coffeeHouseFormat,
                'coffeeHouseFormat',
                'coffeeHouseFormat'
              )
            ))
          }, null, 8, ["is-disabled", "selected-option", "options"]),
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "10",
            "model-value": radioFields.livingSpaces,
            direction: "row",
            "is-small-label": true,
            title: "Имеются ли жилые помещения в здании?*",
            "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => (onUpdateRadio($event, 'livingSpaces')))
          }, null, 8, ["model-value"])
        ]),
        _createElementVNode("div", _hoisted_37, [
          _createVNode(UIDropdown, {
            class: "half-input",
            label: "Наличие летней посадки",
            "to-top": false,
            "is-disabled": disableUpdate.value,
            "selected-option": dropdownCurrentFields.summerArea,
            options: dropDownFields.summerArea,
            "onUpdate:selectedOption": _cache[31] || (_cache[31] = ($event: any) => (
              onDropdownSelect(
                $event,
                dropDownFields.summerArea,
                'summerArea',
                'summerArea'
              )
            ))
          }, null, 8, ["is-disabled", "selected-option", "options"]),
          _createVNode(UIInput, {
            modelValue: state.summerAreaComment,
            "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((state.summerAreaComment) = $event)),
            "place-holder": "Комментарий (при желании)",
            class: "half-input",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ])
      ]),
      _createElementVNode("div", _hoisted_38, [
        _cache[68] || (_cache[68] = _createElementVNode("h3", { class: "section-title" }, "дизайн и отделка", -1)),
        _createElementVNode("div", _hoisted_39, [
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "11",
            "model-value": radioFields.smokingSigns,
            direction: "row",
            "is-small-label": true,
            title: "Таблички о запрете курения и алкоголя",
            "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => (onUpdateRadio($event, 'smokingSigns')))
          }, null, 8, ["model-value"]),
          _createVNode(UIInput, {
            modelValue: state.smokingSignsComment,
            "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((state.smokingSignsComment) = $event)),
            "place-holder": "Комментарий (при желании)",
            class: "half-input",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ]),
        _createElementVNode("div", _hoisted_40, [
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "12",
            "model-value": radioFields.smokingArea,
            direction: "row",
            "is-small-label": true,
            title: "Тамбур",
            "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => (onUpdateRadio($event, 'smokingArea')))
          }, null, 8, ["model-value"]),
          _createVNode(UIInput, {
            modelValue: state.smokingAreaComment,
            "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((state.smokingAreaComment) = $event)),
            "place-holder": "Комментарий (при желании)",
            class: "half-input",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ]),
        _createElementVNode("div", _hoisted_41, [
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "13",
            "model-value": radioFields.materialRestrictions,
            direction: "row",
            "is-small-label": true,
            title: "Ограничения по использованию материалов",
            "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => (onUpdateRadio($event, 'materialRestrictions')))
          }, null, 8, ["model-value"]),
          _createVNode(UIInput, {
            modelValue: state.materialRestrictionsComment,
            "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((state.materialRestrictionsComment) = $event)),
            "place-holder": "Комментарий (при желании)",
            class: "half-input",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ]),
        _createElementVNode("div", _hoisted_42, [
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "13",
            "model-value": radioFields.signboardDesignCode,
            direction: "row",
            "is-small-label": true,
            title: "Имеется ли дизайн-код для вывески",
            "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => (onUpdateRadio($event, 'signboardDesignCode')))
          }, null, 8, ["model-value"]),
          _createVNode(UIDropdown, {
            class: "half-input",
            label: "Размещение рекламных элементов",
            "to-top": false,
            "is-disabled": disableUpdate.value,
            "selected-option": dropdownCurrentFields.additionalAdElements,
            options: dropDownFields.additionalAdElements,
            "onUpdate:selectedOption": _cache[40] || (_cache[40] = ($event: any) => (
              onDropdownSelect(
                $event,
                dropDownFields.additionalAdElements,
                'additionalAdElements',
                'additionalAdElements'
              )
            ))
          }, null, 8, ["is-disabled", "selected-option", "options"])
        ]),
        _cache[69] || (_cache[69] = _createElementVNode("div", { class: "section-subscription" }, [
          _createElementVNode("div", { class: "flex-row" }, [
            _createElementVNode("div", { class: "half-input" }, " Расположение вывески, её размеры и крепление (подложка, направляющие) ")
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_43, [
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "14",
            "model-value": radioFields.outdoorsMenu,
            direction: "row",
            "is-small-label": true,
            title: "Уличное меню",
            "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => (onUpdateRadio($event, 'outdoorsMenu')))
          }, null, 8, ["model-value"]),
          _createVNode(UIInput, {
            modelValue: state.outdoorsMenuComment,
            "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((state.outdoorsMenuComment) = $event)),
            "place-holder": "Комментарий (при желании)",
            class: "half-input",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ]),
        _createElementVNode("div", _hoisted_44, [
          _createVNode(UIDropdown, {
            class: "half-input",
            label: "Нужно ли видеонаблюдение?",
            "to-top": false,
            "is-disabled": disableUpdate.value,
            "selected-option": dropdownCurrentFields.cctv,
            options: dropDownFields.cctv,
            "onUpdate:selectedOption": _cache[43] || (_cache[43] = ($event: any) => (
              onDropdownSelect($event, dropDownFields.cctv, 'cctv', 'cctv')
            ))
          }, null, 8, ["is-disabled", "selected-option", "options"]),
          _createVNode(UIInput, {
            modelValue: state.cctvComment,
            "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => ((state.cctvComment) = $event)),
            "place-holder": "Комментарий (при желании)",
            class: "half-input",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ])
      ]),
      _createElementVNode("div", _hoisted_45, [
        _cache[70] || (_cache[70] = _createElementVNode("h3", { class: "section-title" }, "оборудование, посадка, мебель и декор", -1)),
        _cache[71] || (_cache[71] = _createElementVNode("p", { class: "section-description" }, " Схема расстановки (барная зона, хранение, туалет, посадка,расположение мокрых точек) ", -1)),
        _createElementVNode("div", _hoisted_46, [
          _createVNode(UIInput, {
            modelValue: state.wishesComment,
            "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((state.wishesComment) = $event)),
            "place-holder": "Пожелания в свободной форме",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ]),
        _createElementVNode("div", _hoisted_47, [
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "15",
            "model-value": radioFields.clothes,
            direction: "row",
            "is-small-label": true,
            title: "Будет ли форма?",
            "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => (onUpdateRadio($event, 'clothes')))
          }, null, 8, ["model-value"]),
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "16",
            "model-value": radioFields.shelvesForSale,
            direction: "row",
            "is-small-label": true,
            title: "Полки для продажи кофе и СТМ",
            "onUpdate:modelValue": _cache[47] || (_cache[47] = ($event: any) => (onUpdateRadio($event, 'shelvesForSale')))
          }, null, 8, ["model-value"])
        ]),
        _createElementVNode("div", _hoisted_48, [
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "17",
            "model-value": radioFields.musicEvents,
            direction: "row",
            "is-small-label": true,
            title: "Музыкальные мероприятия и кино",
            "onUpdate:modelValue": _cache[48] || (_cache[48] = ($event: any) => (onUpdateRadio($event, 'musicEvents')))
          }, null, 8, ["model-value"]),
          _createVNode(UIInput, {
            modelValue: state.musicEventsComment,
            "onUpdate:modelValue": _cache[49] || (_cache[49] = ($event: any) => ((state.musicEventsComment) = $event)),
            "place-holder": "Комментарий (при желании)",
            class: "half-input",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ]),
        _createElementVNode("div", _hoisted_49, [
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "18",
            "model-value": radioFields.hangerForGuests,
            direction: "row",
            "is-small-label": true,
            title: "Вешалка для гостей",
            "onUpdate:modelValue": _cache[50] || (_cache[50] = ($event: any) => (onUpdateRadio($event, 'hangerForGuests')))
          }, null, 8, ["model-value"]),
          _createVNode(UIInput, {
            modelValue: state.hangerForGuestsComment,
            "onUpdate:modelValue": _cache[51] || (_cache[51] = ($event: any) => ((state.hangerForGuestsComment) = $event)),
            "place-holder": "Комментарий (при желании)",
            class: "half-input",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ]),
        _createElementVNode("div", _hoisted_50, [
          _createVNode(UIRadioButtons, {
            class: "half-input",
            name: "19",
            "model-value": radioFields.employeeWardrobe,
            direction: "row",
            "is-small-label": true,
            title: "Гардероб для сотрудников",
            "onUpdate:modelValue": _cache[52] || (_cache[52] = ($event: any) => (onUpdateRadio($event, 'employeeWardrobe')))
          }, null, 8, ["model-value"]),
          _createVNode(UIInput, {
            modelValue: state.employeeWardrobeComment,
            "onUpdate:modelValue": _cache[53] || (_cache[53] = ($event: any) => ((state.employeeWardrobeComment) = $event)),
            "place-holder": "Комментарий (при желании)",
            class: "half-input",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ])
      ]),
      _createElementVNode("div", _hoisted_51, [
        _cache[72] || (_cache[72] = _createElementVNode("h3", { class: "section-title" }, "элементы декора", -1)),
        _cache[73] || (_cache[73] = _createElementVNode("p", { class: "section-description" }, " Используем mood board Surf coffee,ссылки,картинки и пожелания в свободной форме ", -1)),
        _createElementVNode("div", _hoisted_52, [
          _createVNode(UIInput, {
            modelValue: state.equipmentComment,
            "onUpdate:modelValue": _cache[54] || (_cache[54] = ($event: any) => ((state.equipmentComment) = $event)),
            "place-holder": "Пожелания в свободной форме",
            readonly: disableUpdate.value
          }, null, 8, ["modelValue", "readonly"])
        ])
      ]),
      _createElementVNode("div", _hoisted_53, [
        _createVNode(UIButton, {
          onClick: createBuilding,
          "button-style": "solid",
          "is-disabled": disableFinishButton.value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(setFinalButtonText.value), 1)
          ]),
          _: 1
        }, 8, ["is-disabled"])
      ])
    ], 512), [
      [_vShow, activeTab.value.value === 'additional']
    ])
  ], 512))
}
}

})