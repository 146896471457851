import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "cpp-modal-container no-scroll" }
const _hoisted_2 = { class: "top" }
const _hoisted_3 = { class: "button-container" }

import {
  managerTaskComplete,
  updateManagerFiles,
  updateManagerVariables,
} from "@/modules/cpp/services/manager-cpp-services";
import {
  instructorTaskComplete,
  updateInstructorFiles,
  updateInstructorVariables,
} from "@/modules/cpp/services/instructor-cpp-services";
import {
  partnerTaskComplete,
  updatePartnerFiles,
  updatePartnerVariables,
} from "@/modules/cpp/services/partner-cpp-services";
import { Roles } from "@/enums/enums";
import { computed, reactive, ref } from "vue";
import { toast } from "vue3-toastify";
import { useStore } from "vuex";
import CppStructureItem from "@/modules/cpp/components/CppStructureItem.vue";
import CppModalTaskInformation from "@/modules/cpp/components/CppModalTaskInformation.vue";
import UIButton from "@/modules/UI-kit/UIButton.vue";
import UIText from "@/modules/UI-kit/UIText.vue";
import {
  executiveFilesUpdate,
  executiveTaskComplete,
  executiveVariablesUpdate,
} from "@/modules/executive/services/executive.services";

const notCompletedMessage = "Не удалось завершить задачу";
const completedMessage = "Задача завершена успешно";

interface IEvent {
  key: string;
  value: string;
}

interface DeleteEvent {
  file: File;
  key: string;
}

// file handlers

export default /*@__PURE__*/_defineComponent({
  __name: 'CppModalContainer',
  setup(__props) {

const store = useStore();

const variables = reactive({});
const files = ref<IEvent[]>([]);
const isLoading = ref<boolean>(false);

const structureItems = computed(
  () => store.state.modalsStore.modalData.structure
);

const taskId = computed(() => store.state.modalsStore.modalData.id);

// Task completing
const generateFormDataBody = () => {
  const formData = new FormData();

  for (const fileObject of files.value) {
    formData.append(fileObject.key, fileObject.value);
  }

  return formData;
};

const completeManagerTask = async () => {
  try {
    const formData = generateFormDataBody();

    await updateManagerVariables(variables, taskId.value);
    await updateManagerFiles(formData, taskId.value);
    await managerTaskComplete(taskId.value);
    await store.dispatch("managerDashboardStore/fetchDashboardStages");

    store.commit("modalsStore/closeModal");
    toast.success(completedMessage);
  } catch (e: any) {
    console.log("error", e.response);
    toast.error(e?.response?.data?.reason || notCompletedMessage);
  } finally {
    isLoading.value = false;
  }
};
const completeInstructorTask = async () => {
  try {
    const formData = generateFormDataBody();

    await updateInstructorVariables(variables, taskId.value);
    await updateInstructorFiles(formData, taskId.value);
    await instructorTaskComplete(taskId.value);
    await store.dispatch("instructorDashboardStore/fetchActs");

    store.commit("modalsStore/closeModal");
    toast.success(completedMessage);
  } catch (e: any) {
    toast.error(e?.response?.data?.reason || notCompletedMessage);
  } finally {
    isLoading.value = false;
  }
};
const completePartnerTask = async () => {
  try {
    const formData = generateFormDataBody();

    await updatePartnerVariables(variables, taskId.value);
    await updatePartnerFiles(formData, taskId.value);
    await partnerTaskComplete(taskId.value);
    await store.dispatch("partnerTasksStore/fetchActiveTasks");

    store.commit("modalsStore/closeModal");
    toast.success(completedMessage);
  } catch (e: any) {
    toast.error(e?.response?.data?.reason || notCompletedMessage);
  } finally {
    isLoading.value = false;
  }
};

const completeExecutiveTask = async () => {
  try {
    const formData = generateFormDataBody();

    await executiveVariablesUpdate(taskId.value, variables);
    await executiveFilesUpdate(taskId.value, formData);
    await executiveTaskComplete(taskId.value);
    await store.dispatch("executiveStore/fetchActs");

    store.commit("modalsStore/closeModal");
    toast.success(completedMessage);
  } catch (e: any) {
    toast.error(e?.response?.data?.reason || notCompletedMessage);
  } finally {
    isLoading.value = false;
  }
};

const completeTask = async () => {
  isLoading.value = true;

  switch (store.state.authStore.role) {
    case Roles.manager:
      return await completeManagerTask();

    case Roles.instructor:
      return await completeInstructorTask();

    case Roles.partner:
      return await completePartnerTask();

    case Roles.executive:
      return await completeExecutiveTask();

    default:
      return;
  }
};

const onFileHandler = (event: IEvent) => {
  files.value.push(event);
};

const onFileDelete = (event: DeleteEvent) => {
  files.value = files.value.filter(
    (file) => file.key !== event.key || file.name === event.file.name
  );
};

const onMultiplyFiles = (event: IEvent) => {
  console.log("event", event);
  const newFiles = event.value.map((item) => ({
    value: item.file,
    key: event.key,
    name: item.name,
  }));
  console.log("new files", newFiles);

  newFiles.forEach((file: File) => {
    files.value.push(file);
  });
};

// value handlers
const onInputHandler = (event: IEvent) => {
  variables[event.key] = event.value;
};

const onRadioHandler = (event: IEvent) => {
  variables[event.key] = event.value;
};

const onDatePickerHandler = (event: IEvent) => {
  variables[event.key] = event.value;
};

const onDropDown = (event: any) => {
  variables[event.key] = event.value.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(CppModalTaskInformation),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(structureItems.value, (item, index) => {
        return (_openBlock(), _createBlock(CppStructureItem, {
          class: "structure-item",
          "structure-index": +index,
          item: item,
          key: index,
          fields: item.fields,
          "onUpdate:textValue": onInputHandler,
          "onUpdate:radioValue": onRadioHandler,
          "onUpdate:datePickerValue": onDatePickerHandler,
          "onUpdate:addFile": onFileHandler,
          "onUpdate:dropDown": onDropDown,
          "onUpdate:deleteFile": onFileDelete,
          "onUpdate:multipleFiles": onMultiplyFiles
        }, null, 8, ["structure-index", "item", "fields"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(UIButton, {
        "is-loading": isLoading.value,
        onClick: completeTask
      }, {
        default: _withCtx(() => [
          _createVNode(UIText, {
            color: "white",
            "font-family": "proxima-nova-semibold"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Завершить задачу ")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["is-loading"])
    ])
  ]))
}
}

})