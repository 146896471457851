import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-avatar" }

import IconAvatarEmpty from "@/icons/IconAvatarEmpty.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UIUserAvatar',
  props: {
  imageUrl: {
    type: String,
    default: "",
  },
  size: {
    type: String,
    default: "48px",
  },
},
  setup(__props) {

_useCssVars(_ctx => ({
  "7e6f8d40": (__props.size)
}))

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!__props.imageUrl)
      ? (_openBlock(), _createBlock(IconAvatarEmpty, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}
}

})