import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "add-equipment-modal" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "upload-photo" }
const _hoisted_4 = { class: "inputs-container" }
const _hoisted_5 = { class: "button-container" }

import { useStore } from "vuex";
import { computed, reactive, ref, watch } from "vue";
import UIText from "@/modules/UI-kit/UIText.vue";
import UIPhotoUpload from "@/modules/equipments/components/UIPhotoUpload.vue";
import { Nullable, Undefinable } from "@/types/types";
import UIInput from "@/modules/UI-kit/UIInput.vue";
import UIRadioButtons from "@/modules/UI-kit/UIRadioButtons.vue";
import UIButton from "@/modules/UI-kit/UIButton.vue";
import { toast } from "vue3-toastify";
import { createDecoration } from "@/modules/decorations/services/decorations.services";
import { useRoute } from "vue-router";

interface IBody {
  file: Nullable<File>;
  title: string;
  price: number | "";
  amount: number | "";
  ordered: Undefinable<boolean>;
}

interface IField {
  label: string;
  value: boolean;
  checked: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddNewDecorationModal',
  setup(__props) {

const store = useStore();
const route = useRoute();

const spotId = localStorage.getItem("spotId");

const isLoading = ref<boolean>(false);

const body = reactive<IBody>({
  file: null,
  title: "",
  price: "",
  amount: "",
  ordered: false,
});

const fields = ref<IField[]>([
  {
    label: "заказан",
    value: true,
    checked: false,
  },
  {
    label: "не заказан",
    value: false,
    checked: false,
  },
]);

watch(
  () => fields.value,
  () => {
    const field = fields.value.find((item) => item.checked);
    body.ordered = field?.value;
  }
);

const role = computed(() => store.state.authStore.role);

const updateFile = (file: File) => {
  body.file = file;
};

const addDecoration = async () => {
  try {
    isLoading.value = true;

    const formData = new FormData();

    for (const [key, val] of Object.entries(body)) {
      if (key === "ordered" && !val) {
        formData.append(key, val);
      }

      // @ts-ignore
      if (val && val !== false) {
        // @ts-ignore
        formData.append(key, val);
      }
    }

    const partnerId = +route?.params?.id;

    const response = await createDecoration(
      role.value,
      partnerId,
      formData,
      spotId
    );
    await store.dispatch("decorationsStore/fetchDecorations", {
      partnerId,
      role: role.value,
      spotId,
    });
    store.commit("modalsStore/closeModal");

    toast.success("Оборудование успешно создано");
  } catch (e: any) {
    toast.error(e.response.reason);
  } finally {
    isLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(UIText, {
        "font-size": "34px",
        "font-family": "proxima-nova-semibold"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode(" Добавить декор ")
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(UIPhotoUpload, {
        "onUpdate:file": updateFile,
        class: "uploader"
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(UIInput, {
        modelValue: body.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((body.title) = $event)),
        "place-holder": "Наименование",
        "max-length": "50"
      }, null, 8, ["modelValue"]),
      _createVNode(UIInput, {
        modelValue: body.price,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((body.price) = $event)),
        modelModifiers: { number: true },
        "place-holder": "Стоимость (₽)",
        "max-length": "7"
      }, null, 8, ["modelValue"]),
      _createVNode(UIInput, {
        modelValue: body.amount,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((body.amount) = $event)),
        modelModifiers: { number: true },
        "place-holder": "Количество",
        "max-length": "3"
      }, null, 8, ["modelValue"]),
      _createVNode(UIRadioButtons, {
        name: "1",
        direction: "row",
        "is-editable": true,
        modelValue: fields.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((fields).value = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(UIButton, {
        "is-disabled": isLoading.value,
        class: "submit",
        "is-loading": isLoading.value,
        onClick: addDecoration
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode(" Добавить ")
        ])),
        _: 1
      }, 8, ["is-disabled", "is-loading"])
    ])
  ]))
}
}

})