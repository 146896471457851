import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "test-page" }
const _hoisted_2 = {
  class: "ui-block button-container",
  style: { maxWidth: '300px' }
}
const _hoisted_3 = { class: "ui-block radio-buttons" }
const _hoisted_4 = { class: "ui-block user-avatar" }
const _hoisted_5 = { class: "ui-block tabs" }
const _hoisted_6 = { class: "ui-block" }
const _hoisted_7 = { class: "ui-block" }
const _hoisted_8 = { class: "ui-block" }

import lottieModel from "@/plugins/lottie/models/Lottie.json";
import { ref } from "vue";
import UIButton from "@/modules/UI-kit/UIButton.vue";
import UIRadioButtons from "@/modules/UI-kit/UIRadioButtons.vue";
import UIUserAvatar from "@/modules/UI-kit/UIUserAvatar.vue";
import UITabs from "@/modules/UI-kit/UITabs.vue";
import InvestmentProgress from "@/modules/finances/components/investment/InvestmentProgress.vue";
import UIGhostTabs from "@/modules/UI-kit/UIGhostTabs.vue";
import { Vue3Lottie } from "vue3-lottie";
import UILottieLoader from "@/modules/UI-kit/UILottieLoader.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TestPage',
  setup(__props) {

const radioFields = ref([
  {
    label: "Label 1",
    value: "first",
    checked: false,
  },
  {
    label: "Label 2",
    value: "second",
    checked: true,
  },
]);

const tabs = ref([
  {
    label: "Финансы",
    value: "finances",
    checked: false,
  },
  {
    label: "Прогресс",
    value: "progress",
    checked: false,
  },
  {
    label: "Декор",
    value: "decorations",
    checked: true,
  },
  {
    label: "Оборудование",
    value: "equipments",
    checked: false,
  },
  {
    label: "Помещение",
    value: "buildings",
    checked: false,
  },
  {
    label: "Сотрудники",
    value: "employers",
    checked: false,
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("h1", null, "Button", -1)),
      _createVNode(UIButton, { "button-style": "solid" }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode(" Готово")
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[5] || (_cache[5] = _createElementVNode("h1", null, "Radio", -1)),
      _createVNode(UIRadioButtons, {
        name: "name1",
        modelValue: radioFields.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((radioFields).value = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[6] || (_cache[6] = _createElementVNode("h1", null, "User avatar", -1)),
      _createVNode(UIUserAvatar)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[7] || (_cache[7] = _createElementVNode("h1", null, "Tabs", -1)),
      _createVNode(UITabs, {
        name: "name32",
        modelValue: tabs.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((tabs).value = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[8] || (_cache[8] = _createElementVNode("h1", null, "investment", -1)),
      _createVNode(InvestmentProgress)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[9] || (_cache[9] = _createElementVNode("h1", null, "ghost", -1)),
      _createVNode(UIGhostTabs, {
        name: "name3231",
        modelValue: tabs.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((tabs).value = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(UILottieLoader)
    ])
  ]))
}
}

})