import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "main-header",
  "data-testid": "main-header"
}
const _hoisted_2 = { class: "actions" }

import UIDigitalClock from "@/modules/UI-kit/UIDigitalClock.vue";
import UINotificationsButton from "@/modules/UI-kit/UINotificationsButton.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'MainHeader',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(UIDigitalClock),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(UINotificationsButton)
    ])
  ]))
}
}

})