import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMessage } from "firebase/messaging";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";
import { Roles } from "@/enums/enums";


export default /*@__PURE__*/_defineComponent({
  __name: 'FirebaseInit',
  setup(__props) {

const store = useStore();

const getMessagingState = computed(() => {
  return store.getters["firebaseStore/getMessagingState"];
});

const role = computed(() => {
  return store.state.authStore.role;
});

const initFirebaseOnMessage = () => {
  onMessage(getMessagingState.value, async (payload) => {
    const timeoutId = setTimeout(async () => {
      toast(`${payload?.notification?.title}. ${payload?.notification?.body}`);

      if (role.value === Roles.manager) {
        await store.dispatch("managerDashboardStore/fetchDashboardStages");
      }

      if (role.value === Roles.instructor) {
        await store.dispatch("instructorDashboardStore/fetchActs");
      }

      if (role.value === Roles.partner) {
        await store.dispatch("partnerTasksStore/fetchStages");
      }

      clearTimeout(timeoutId);
    }, 3000);

    await store.dispatch("notificationsStore/fetchNotifications");
  });
};

onMounted(async () => {
  await store.dispatch("firebaseStore/initFirebase");
  initFirebaseOnMessage();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div"))
}
}

})