import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dashboard-act" }
const _hoisted_2 = { class: "top" }
const _hoisted_3 = { class: "left" }
const _hoisted_4 = { class: "act-name" }
const _hoisted_5 = { class: "mid" }
const _hoisted_6 = { class: "dates" }
const _hoisted_7 = { class: "left-date date" }
const _hoisted_8 = { class: "right-date date" }
const _hoisted_9 = { class: "bottom" }
const _hoisted_10 = { class: "last-activity-container" }
const _hoisted_11 = { class: "activity" }
const _hoisted_12 = { class: "tasks-container" }
const _hoisted_13 = { class: "tasks" }

import { IAct } from "@/modules/instructor-dashboard/types/instructor-dashboard.types";
import IconDropDown from "@/icons/IconDropDown.vue";
import { computed, PropType, ref } from "vue";
import { useStore } from "vuex";
import { ModalTypes } from "@/enums/enums";
import InstructorDashboardActsTask from "@/modules/instructor-dashboard/components/InstructorDashboardActsTask.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InstructorDashboardAct',
  props: {
  act: {
    type: Object as PropType<IAct>,
    required: true,
  },
},
  setup(__props) {

_useCssVars(_ctx => ({
  "79bd9dc8": (progressLineWidth.value)
}))

const isVisiblePartOfAct = ref<boolean>(true);

const store = useStore();

const props = __props;

const formatDate = computed(() => {
  return function (date: string) {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString("ru-RU");
  };
});

const progressLineWidth = computed(() => {
  return `${props.act?.completePercentage}%`;
});

const isRotated = computed(() => {
  return isVisiblePartOfAct.value ? "" : "arrow-rotated";
});

const toggleActVisibility = () => {
  isVisiblePartOfAct.value = !isVisiblePartOfAct.value;
};

const openPartnerAct = () => {
  store.commit("modalsStore/showModal", { modalType: ModalTypes.partnerAct });
  store.commit("instructorDashboardStore/setCurrentAct", props.act);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "title-button",
          onClick: openPartnerAct
        }, [
          _createTextVNode(_toDisplayString(__props.act.name) + " ", 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(`${__props.act.partner.name} ${__props.act.partner.surname}`), 1)
        ])
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, _toDisplayString(formatDate.value(__props.act.startDate)), 1),
        _createElementVNode("div", _hoisted_8, _toDisplayString(formatDate.value(__props.act.endDate)), 1)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "progress-bar" }, [
        _createElementVNode("div", { class: "line" })
      ], -1))
    ], 512), [
      [_vShow, isVisiblePartOfAct.value]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_9, [
      _withDirectives(_createElementVNode("div", _hoisted_10, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "bottom-title" }, "последнее действие:", -1)),
        _createElementVNode("div", _hoisted_11, _toDisplayString(__props.act.event?.title), 1)
      ], 512), [
        [_vShow, __props.act.event]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_12, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "bottom-title" }, "требуемое действие", -1)),
        _createElementVNode("ul", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.act.tasks, (task) => {
            return (_openBlock(), _createBlock(InstructorDashboardActsTask, {
              key: task.id,
              task: task,
              class: "instructor-dashboard-task"
            }, null, 8, ["task"]))
          }), 128))
        ])
      ], 512), [
        [_vShow, __props.act.tasks.length]
      ])
    ], 512), [
      [_vShow, isVisiblePartOfAct.value]
    ])
  ]))
}
}

})