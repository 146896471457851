import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-wrapper" }

import { useStore } from "vuex";
import { computed } from "vue";
import { ModalTypes } from "@/enums/enums";
import ModalContainer from "@/modules/layouts/components/modals/ModalContainer.vue";
import CppModalContainer from "@/modules/cpp/components/CppModalContainer.vue";
import CppSideModal from "@/modules/cpp/components/CppSideModal.vue";
import BuildingModal from "@/modules/buildings/components/BuildingModal.vue";
import AddNewDecorationModal from "@/modules/decorations/components/AddNewDecorationModal.vue";
import UpdateDecorationModal from "@/modules/decorations/components/UpdateDecorationModal.vue";
import UpdateEquipmentModal from "@/modules/equipments/components/UpdateEquipmentModal.vue";
import AddNewEquipmentModal from "@/modules/equipments/components/AddNewEquipmentModal.vue";
import DashboardManagerFilters from "@/modules/manager-dashboard/components/DashboardManagerFilters.vue";
import PartnerActModal from "@/modules/instructor-dashboard/components/PartnerActModal.vue";
import AddExpenseModal from "@/modules/finances/components/AddExpenseModal.vue";
import UICloseButton from "@/modules/layouts/components/UICloseButton.vue";
import ChangeSpotWindow from "@/modules/partner-dashboard/components/ChangeSpotWindow.vue";
import HistoryExpensesModal from "@/modules/finances/components/HistoryExpensesModal.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalWrapper',
  setup(__props) {

const store = useStore();

const modalType = computed<ModalTypes>(() => {
  return store.state.modalsStore.modalType;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ModalContainer, null, {
      "close-button": _withCtx(() => [
        _createVNode(UICloseButton)
      ]),
      "modal-container": _withCtx(() => [
        (modalType.value === _unref(ModalTypes).cppMainPopup)
          ? (_openBlock(), _createBlock(CppModalContainer, { key: 0 }))
          : _createCommentVNode("", true),
        (modalType.value === _unref(ModalTypes).cppSidePopup)
          ? (_openBlock(), _createBlock(CppSideModal, { key: 1 }))
          : _createCommentVNode("", true),
        (modalType.value === _unref(ModalTypes).createBuildingModal)
          ? (_openBlock(), _createBlock(BuildingModal, { key: 2 }))
          : _createCommentVNode("", true),
        (modalType.value === _unref(ModalTypes).addDecoration)
          ? (_openBlock(), _createBlock(AddNewDecorationModal, { key: 3 }))
          : _createCommentVNode("", true),
        (modalType.value === _unref(ModalTypes).addEquipment)
          ? (_openBlock(), _createBlock(AddNewEquipmentModal, { key: 4 }))
          : _createCommentVNode("", true),
        (modalType.value === _unref(ModalTypes).updateDecor)
          ? (_openBlock(), _createBlock(UpdateDecorationModal, { key: 5 }))
          : _createCommentVNode("", true),
        (modalType.value === _unref(ModalTypes).updateEquipment)
          ? (_openBlock(), _createBlock(UpdateEquipmentModal, { key: 6 }))
          : _createCommentVNode("", true),
        (modalType.value === _unref(ModalTypes).dashboardManagerFilters)
          ? (_openBlock(), _createBlock(DashboardManagerFilters, { key: 7 }))
          : _createCommentVNode("", true),
        (modalType.value === _unref(ModalTypes).partnerAct)
          ? (_openBlock(), _createBlock(PartnerActModal, { key: 8 }))
          : _createCommentVNode("", true),
        (modalType.value === _unref(ModalTypes).addExpensesModal)
          ? (_openBlock(), _createBlock(AddExpenseModal, { key: 9 }))
          : _createCommentVNode("", true),
        (modalType.value === _unref(ModalTypes).historyExpensesModal)
          ? (_openBlock(), _createBlock(HistoryExpensesModal, { key: 10 }))
          : _createCommentVNode("", true),
        (modalType.value === _unref(ModalTypes).changeSpotModal)
          ? (_openBlock(), _createBlock(ChangeSpotWindow, { key: 11 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})