import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ghost-tabs" }

import { PropType } from "vue";
import UIGhostTab from "@/modules/UI-kit/UIGhostTab.vue";

interface ITab {
  value: string;
  label: string;
  checked: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UIGhostTabs',
  props: {
  modelValue: {
    type: Array as PropType<Array<ITab>>,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  direction: {
    type: String,
    default: "row",
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const props = __props;

const onTabUpdate = (tab: ITab) => {
  const res = props.modelValue.map((item) => {
    if (item.label === tab.label) {
      return {
        ...item,
        checked: true,
      };
    }

    return { ...item, checked: false };
  });

  emits("update:modelValue", res);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.modelValue, (tab) => {
      return (_openBlock(), _createBlock(UIGhostTab, {
        key: tab.label,
        id: `${tab.value}`,
        name: __props.name,
        checked: tab.checked,
        label: tab.label,
        value: tab.value,
        "model-value": tab.checked,
        "onUpdate:modelValue": ($event: any) => (onTabUpdate(tab)),
        class: "tab"
      }, null, 8, ["id", "name", "checked", "label", "value", "model-value", "onUpdate:modelValue"]))
    }), 128))
  ]))
}
}

})