import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "progress-bar" }


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardColumnProgressBar',
  props: {
  percent: {
    type: [String, Number],
    default: 0,
  },
  partnersLength: {
    type: [String, Number],
    required: true,
  },
},
  setup(__props) {

_useCssVars(_ctx => ({
  "e73e8aa2": (__props.percent)
}))

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(__props.partnersLength), 1))
}
}

})