import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "stages-list" }

import { computed, ref } from "vue";
import { useStore } from "vuex";
import PartnerStageItem from "@/modules/partner-tasks/components/PartnerStageItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PartnerStageList',
  props: {
  activeTaskType: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const store = useStore();

// const activeTaskType = ref<string>("ALL");
const props = __props;

const getStages = computed(() => {
  return store.state.partnerTasksStore.stages;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getStages.value, (stage) => {
      return (_openBlock(), _createBlock(PartnerStageItem, {
        stage: stage,
        key: stage.id,
        "active-task-type": __props.activeTaskType,
        class: "stage"
      }, null, 8, ["stage", "active-task-type"]))
    }), 128))
  ]))
}
}

})