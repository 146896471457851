import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]


export default /*@__PURE__*/_defineComponent({
  __name: 'UIEditInput',
  props: {
  modelValue: {
    type: String,
    required: true,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const props = __props;

const onInputHandler = (event: InputEvent) => {
  const target = event.target as HTMLInputElement;
  const value = target.value;
  emits("update:modelValue", value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("input", {
    class: "surf-edit-input",
    type: "text",
    onInput: onInputHandler,
    value: __props.modelValue
  }, null, 40, _hoisted_1))
}
}

})