import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "name"]
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "ghost-tab-label-text" }


export default /*@__PURE__*/_defineComponent({
  __name: 'UIGhostTab',
  props: {
  modelValue: {
    type: Boolean,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  checked: {
    type: Boolean,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;

const onChangeValue = (event: InputEvent) => {
  const target = event.target as HTMLInputElement;
  emits("update:modelValue", target.checked);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ghost-tab-container", { active: __props.checked }])
  }, [
    _createElementVNode("input", {
      class: "input",
      type: "radio",
      value: __props.value,
      onChange: onChangeValue,
      name: __props.name
    }, null, 40, _hoisted_1),
    _createElementVNode("label", {
      class: "ghost-tab-label",
      for: __props.id
    }, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(__props.label.toLowerCase()), 1)
    ], 8, _hoisted_2)
  ], 2))
}
}

})