import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "notifications-page page" }
const _hoisted_2 = { class: "actions" }
const _hoisted_3 = { class: "notifications-block" }

import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { getMainPagePath } from "@/helpers/get-main-page";
import SecondaryHeader from "@/modules/layouts/components/headers/SecondaryHeader.vue";
import NotificationsList from "@/modules/notifications/components/NotificationsList.vue";
import UIButton from "@/modules/UI-kit/UIButton.vue";
import {
  readAllNotifications,
  removeAllNotifications,
} from "@/modules/notifications/services/notifications.services";
import { toast } from "vue3-toastify";


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsPage',
  setup(__props) {

const store = useStore();
const router = useRouter();

const role = computed(() => store.state.authStore.role);
const readNotifications = computed(
  () => store.state.notificationsStore.readNotifications
);
const newNotifications = computed(
  () => store.state.notificationsStore.newNotifications
);

const toMain = async () => {
  await router.push(getMainPagePath(role.value));
};

const newNotificationsTitle = computed(() => {
  if (newNotifications.value.length) {
    if (newNotifications.value.length === 1) {
      return `${newNotifications.value.length} новое`;
    }

    return `${newNotifications.value.length} новых`;
  } else {
    return "новые уведомления";
  }
});

const onHideAll = async () => {
  try {
    await removeAllNotifications();
    await store.dispatch("notificationsStore/fetchNotifications");
  } catch {
    toast.error("Не удалось удалить все уведомления");
  }
};

const onReadAll = async () => {
  try {
    await readAllNotifications();
    await store.dispatch("notificationsStore/fetchNotifications");
  } catch {
    toast.error("Не удалось прочитать все уведомления");
  }
};

onMounted(async () => {
  await store.dispatch("notificationsStore/fetchNotifications");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(SecondaryHeader, {
      "onUpdate:pushBack": toMain,
      class: "header"
    }, {
      pageTitle: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" Уведомления")
      ])),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(UIButton, {
        onClick: onReadAll,
        class: "actions-button"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" Прочитать все ")
        ])),
        _: 1
      }),
      _createVNode(UIButton, {
        onClick: onHideAll,
        class: "actions-button"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Удалить все ")
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(NotificationsList, {
        title: newNotificationsTitle.value,
        notifications: newNotifications.value,
        "is-new": true,
        class: "new-list"
      }, null, 8, ["title", "notifications"]),
      _createVNode(NotificationsList, { notifications: readNotifications.value }, null, 8, ["notifications"])
    ])
  ]))
}
}

})