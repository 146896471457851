import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-container" }
const _hoisted_2 = { class: "close-button-container" }

import { ref } from "vue";
import { useStore } from "vuex";
import { useOutsideClick } from "@/composables/useOutsideClick";


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalContainer',
  setup(__props) {

const modal = ref(null);

const store = useStore();

useOutsideClick(modal, () => {
  store.commit("modalsStore/closeModal");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "modal",
      ref_key: "modal",
      ref: modal
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "close-button")
      ]),
      _renderSlot(_ctx.$slots, "modal-container")
    ], 512)
  ]))
}
}

})