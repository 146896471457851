import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "deep-link" }
const _hoisted_2 = ["href"]

import { computed } from "vue";
import { API_URL } from "@/api";
import UIText from "@/modules/UI-kit/UIText.vue";
import { useStore } from "vuex";
import { Roles } from "@/enums/enums";


export default /*@__PURE__*/_defineComponent({
  __name: 'UIDeepLink',
  props: {
  link: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const store = useStore();

const props = __props;

const role = computed(() => store.state.authStore.role);

const fullLink = computed(() => {
  let replaced = API_URL.replace("/api", "");
  let formatted = `${props.link}`;

  if (role.value === Roles.partner) {
    formatted = formatted.replace("/profile", "/partner/shop");
    return `${replaced}${formatted}`;
  } else {
    return `${replaced}${props.link}`;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(UIText, {
      class: "text",
      "font-weight": 700,
      "font-family": "proxima-nova-bold"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(__props.description), 1)
      ]),
      _: 1
    }),
    _createElementVNode("a", {
      href: fullLink.value,
      target: "_blank"
    }, _toDisplayString(fullLink.value), 9, _hoisted_2)
  ]))
}
}

})