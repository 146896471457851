import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["fill"]
const _hoisted_6 = ["fill"]
const _hoisted_7 = ["fill"]
const _hoisted_8 = ["fill"]
const _hoisted_9 = ["fill"]

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'IconCoffeeShop',
  props: {
  width: {
    type: Number,
    default: 16,
  },
  height: {
    type: Number,
    default: 17,
  },
  color: {
    type: String,
    default: "main-black",
  },
},
  setup(__props) {

const props = __props;

const setColor = computed(() => {
  return `var(--${props.color})`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    width: __props.width,
    height: __props.height,
    viewBox: "0 0 16 17",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M4.66667 9.84838C4.99804 9.84838 5.26667 10.12 5.26667 10.4551V13.2865H10.7333V10.4551C10.7333 10.12 11.002 9.84838 11.3333 9.84838C11.6647 9.84838 11.9333 10.12 11.9333 10.4551V13.8933C11.9333 14.2284 11.6647 14.5 11.3333 14.5H4.66667C4.3353 14.5 4.06667 14.2284 4.06667 13.8933V10.4551C4.06667 10.12 4.3353 9.84838 4.66667 9.84838Z",
      fill: setColor.value
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M2 13.8933C2 13.5582 2.26863 13.2865 2.6 13.2865H13.4C13.7314 13.2865 14 13.5582 14 13.8933C14 14.2284 13.7314 14.5 13.4 14.5H2.6C2.26863 14.5 2 14.2284 2 13.8933Z",
      fill: setColor.value
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M11.2667 7.15175C11.2667 7.56131 11.595 7.89332 12 7.89332C12.405 7.89332 12.7333 7.56131 12.7333 7.15175H13.9333C13.9333 8.23149 13.0678 9.10679 12 9.10679C10.9323 9.10679 10.0667 8.23149 10.0667 7.15175H11.2667Z",
      fill: setColor.value
    }, null, 8, _hoisted_4),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M10.6667 6.54502C10.998 6.54502 11.2667 6.81666 11.2667 7.15175C11.2667 7.56131 11.595 7.89332 12 7.89332C12.405 7.89332 12.7333 7.56131 12.7333 7.15175C12.7333 6.81666 13.002 6.54502 13.3333 6.54502C13.6647 6.54502 13.9333 6.81666 13.9333 7.15175C13.9333 8.23149 13.0678 9.10679 12 9.10679C10.9323 9.10679 10.0667 8.23149 10.0667 7.15175C10.0667 6.81666 10.3353 6.54502 10.6667 6.54502Z",
      fill: setColor.value
    }, null, 8, _hoisted_5),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M8 6.54502C8.33137 6.54502 8.6 6.81667 8.6 7.15176C8.6 7.56131 8.92833 7.89332 9.33334 7.89332C9.73835 7.89332 10.0667 7.56131 10.0667 7.15175C10.0667 6.81666 10.3353 6.54502 10.6667 6.54502C10.998 6.54502 11.2667 6.81666 11.2667 7.15175C11.2667 8.23149 10.4011 9.10679 9.33334 9.10679C8.26559 9.10679 7.4 8.23149 7.4 7.15176C7.4 6.81667 7.66863 6.54502 8 6.54502Z",
      fill: setColor.value
    }, null, 8, _hoisted_6),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M5.33334 6.54502C5.66471 6.54502 5.93334 6.81667 5.93334 7.15176C5.93334 7.56131 6.26166 7.89332 6.66667 7.89332C7.07168 7.89332 7.4 7.56131 7.4 7.15176C7.4 6.81667 7.66863 6.54502 8 6.54502C8.33137 6.54502 8.6 6.81667 8.6 7.15176C8.6 8.23149 7.73442 9.10679 6.66667 9.10679C5.59892 9.10679 4.73334 8.23149 4.73334 7.15176C4.73334 6.81667 5.00197 6.54502 5.33334 6.54502Z",
      fill: setColor.value
    }, null, 8, _hoisted_7),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M2.66667 6.54502C2.99804 6.54502 3.26667 6.81667 3.26667 7.15176C3.26667 7.56131 3.595 7.89332 4 7.89332C4.40501 7.89332 4.73334 7.56131 4.73334 7.15176C4.73334 6.81667 5.00197 6.54502 5.33334 6.54502C5.66471 6.54502 5.93334 6.81667 5.93334 7.15176C5.93334 8.23149 5.06775 9.10679 4 9.10679C2.93225 9.10679 2.06667 8.23149 2.06667 7.15176C2.06667 6.81667 2.3353 6.54502 2.66667 6.54502Z",
      fill: setColor.value
    }, null, 8, _hoisted_8),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M5.8021 2.50006C5.826 2.50009 5.85015 2.50011 5.87455 2.50011H10.1255C10.1499 2.50011 10.174 2.50009 10.1979 2.50006C10.5666 2.49968 10.8773 2.49935 11.1666 2.59278C11.4205 2.67477 11.6549 2.80898 11.8551 2.98697C12.0831 3.18976 12.2427 3.45929 12.4321 3.77922C12.4443 3.79995 12.4567 3.8209 12.4693 3.84206L13.5438 5.65306C13.5499 5.66339 13.556 5.67361 13.562 5.68373C13.6575 5.84447 13.7382 5.98025 13.7966 6.129C13.8481 6.26004 13.8855 6.39633 13.908 6.53549C13.9335 6.69344 13.9335 6.85184 13.9334 7.03935C13.9333 7.05116 13.9333 7.06309 13.9333 7.07514L13.9333 7.15175H12.7333L12.7333 7.07514C12.7333 6.83366 12.7315 6.77965 12.7237 6.73136C12.7152 6.67857 12.701 6.62688 12.6815 6.57717C12.6636 6.5317 12.6377 6.48445 12.5148 6.27739L11.4403 4.46638C11.1933 4.05008 11.1318 3.95975 11.0627 3.89826C10.9867 3.83074 10.8978 3.77984 10.8015 3.74873C10.7138 3.7204 10.6056 3.71359 10.1255 3.71359H5.87455C5.39444 3.71359 5.28623 3.7204 5.1985 3.74873C5.10219 3.77984 5.01328 3.83074 4.93735 3.89826C4.86819 3.95975 4.80673 4.05008 4.55972 4.46638L3.48518 6.27739C3.36233 6.48445 3.3364 6.5317 3.31852 6.57717C3.29899 6.62688 3.28483 6.67857 3.27629 6.73136C3.26848 6.77965 3.26667 6.83366 3.26667 7.07514L3.26667 7.15176H2.06667L2.06667 7.07514C2.06667 7.06309 2.06667 7.05117 2.06666 7.03936C2.06656 6.85184 2.06648 6.69345 2.09203 6.53549C2.11453 6.39633 2.15187 6.26004 2.20337 6.129C2.26183 5.98025 2.34249 5.84448 2.43798 5.68373C2.44399 5.67361 2.45006 5.66339 2.45619 5.65306L3.53073 3.84206C3.54328 3.8209 3.55569 3.79995 3.56796 3.77921C3.75734 3.45929 3.91689 3.18976 4.14495 2.98697C4.34512 2.80898 4.57953 2.67477 4.83345 2.59278C5.12274 2.49935 5.43338 2.49968 5.8021 2.50006Z",
      fill: setColor.value
    }, null, 8, _hoisted_9)
  ], 8, _hoisted_1))
}
}

})