import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import SidebarPageHeader from "@/modules/layouts/components/headers/SidebarPageHeader.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'SupportPage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(SidebarPageHeader, null, {
      title: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" Поддержка ")
      ])),
      _: 1
    })
  ]))
}
}

})