import { authService } from "@/modules/auth/services/auth.services";
import { getSpots } from "@/modules/spot/services/spot.services";
import { Roles } from "@/enums/enums";

export default {
  namespaced: true,
  state: {
    role: "",
    accessToken: "",
    refreshToken: "",
    username: "",
    surname: "",
    email: "",
    spotName: "",
  },
  getters: {
    getRole: (state: any) => {
      return state.role;
    },
  },
  mutations: {
    setSpotName(state: any, payload: string) {
      state.spotName = payload;
    },
    setAuthState(state: any, payload: any) {
      state.role = payload.role;
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.username = payload.username;
      localStorage.setItem("accessToken", payload.accessToken);
      localStorage.setItem("refreshToken", payload.refreshToken);
    },
    setUserData(state: any, payload: any) {
      state.username = payload.name;
      state.surname = payload.surname;
    },
    setRole(state: any, payload: any) {
      state.role = payload.role;
    },
    clearAuthState(state: any) {
      state.role = "";
      state.username = "";
      state.accessToken = "";
      state.refreshToken = "";
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("spotId");
      localStorage.removeItem("userId");
    },
  },
  actions: {
    async login(context: any, payload: any) {
      try {
        const response = await authService.login({
          email: payload.email,
          password: payload.password,
        });
        context.commit("setAuthState", response.data);
        return response;
      } catch (e: any) {
        return e.response;
      }
    },
    async checkUser(context: any, payload: any) {
      try {
        const response = await authService.checkRole();
        const userDataResponse = await authService.getInfo();
        const userId = userDataResponse.data.id;
        const currentSpotId = localStorage.getItem("spotId");
        localStorage.setItem("userId", userId);

        if (response.data === Roles.partner) {
          const spotsResponse = await getSpots();

          if (spotsResponse && !currentSpotId) {
            const spotId = spotsResponse.data.acts[0].id;
            localStorage.setItem("spotId", spotId);
            context.commit("setSpotName", spotsResponse.data.acts[0].name);
          } else {
            const name = spotsResponse.data.acts.find(
              (item: any) => +item.id === Number(currentSpotId)
            );
            context.commit("setSpotName", name.name);
          }
        }

        context.commit("setRole", { role: response.data });
        context.commit("setUserData", userDataResponse.data);
      } catch (e: any) {
        context.commit("clearAuthState");
        return e.response;
      }
    },
  },
};
