import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "label",
  style: { marginBottom: '15px' }
}
const _hoisted_2 = { class: "button-container" }
const _hoisted_3 = ["onClick"]

import { Undefinable } from "@/types/types";
import { computed, PropType, reactive, ref } from "vue";
import { useOutsideClick } from "@/composables/useOutsideClick";
import IconArrow from "@/icons/IconArrow.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UIDropdown',
  props: {
  options: {
    type: Array as PropType<Undefinable<Array<any>>>,
    required: true,
  },
  selectedOption: {
    type: Object as PropType<any>,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "Выбрать поле",
  },
  toTop: {
    type: Boolean,
    default: false,
  },
},
  emits: ["update:selectedOption"],
  setup(__props, { emit: __emit }) {

_useCssVars(_ctx => ({
  "1f8273da": (defaultRotate.value)
}))

const container = ref(null);
const listItems = ref(null);

const emits = __emit;

const props = __props;

const state = reactive({
  isDropdownOpen: false,
});

const openToTop = computed(() => {
  return props.toTop ? "open-to-top" : "default-open";
});

const rotateArrow = computed(() => {
  if (state.isDropdownOpen) {
    if (props.toTop) {
      return "rotate-to-bottom";
    } else {
      return "rotate-to-top";
    }
  }

  return "rotate-to-bottom";
});

const defaultRotate = computed(() => {
  return props.toTop ? "rotate(180deg)" : "";
});

const onChangeHandler = (option: any) => {
  emits("update:selectedOption", option);
  state.isDropdownOpen = false;
};

const toggleDropdown = () => {
  if (!props.isDisabled) {
    state.isDropdownOpen = !state.isDropdownOpen;
  }
};

useOutsideClick(container, () => {
  state.isDropdownOpen = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "dropdown",
    ref_key: "container",
    ref: container
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(__props.label), 1),
    _createElementVNode("button", {
      onClick: _withModifiers(toggleDropdown, ["prevent"])
    }, [
      _createElementVNode("span", _hoisted_2, [
        _createElementVNode("span", null, _toDisplayString(__props.selectedOption?.label || __props.selectedOption?.title), 1),
        _createVNode(IconArrow, {
          class: _normalizeClass(["arrow", rotateArrow.value])
        }, null, 8, ["class"])
      ])
    ]),
    _withDirectives(_createElementVNode("ul", {
      class: _normalizeClass(openToTop.value)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
        return (_openBlock(), _createElementBlock("li", {
          key: option?.id,
          onClick: _withModifiers(($event: any) => (onChangeHandler(option)), ["stop"]),
          class: "list-item"
        }, [
          _createElementVNode("span", null, _toDisplayString(option?.label || option?.title), 1)
        ], 8, _hoisted_3))
      }), 128))
    ], 2), [
      [_vShow, state.isDropdownOpen]
    ])
  ], 512))
}
}

})