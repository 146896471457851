import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconDropDown',
  props: {
  width: {
    type: Number,
    default: 15,
  },
  height: {
    type: Number,
    default: 8,
  },
  color: {
    type: String,
    default: "black",
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    width: __props.width,
    height: __props.height,
    viewBox: "0 0 12 6",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M1.2381 0.18057C0.92361 -0.0889959 0.450137 -0.0525746 0.180571 0.261919C-0.0889953 0.576413 -0.0525742 1.04989 0.261919 1.31945L5.51191 5.81944C5.79277 6.06019 6.20723 6.06019 6.48809 5.81944L11.7381 1.31945C12.0526 1.04989 12.089 0.576413 11.8194 0.261919C11.5499 -0.0525746 11.0764 -0.0889959 10.7619 0.18057L6 4.2622L1.2381 0.18057Z",
      fill: __props.color
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}
}

})