import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "last-event-list" }
const _hoisted_2 = {
  key: 0,
  class: "list"
}

import ProgressEventItem from "@/modules/progress/components/ProgressEventItem.vue";
import { PropType } from "vue";
import { IProgressEvent } from "@/modules/progress/types/progress.types";
import EventEmptyState from "@/modules/progress/components/EventEmptyState.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LastEventList',
  props: {
  lastHistory: {
    type: Array as PropType<Array<IProgressEvent>>,
    required: true,
  },
},
  emits: ["update:lastList"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;

const openFullHistory = () => {
  emits("update:lastList");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", { class: "title" }, [
      _cache[0] || (_cache[0] = _createElementVNode("span", null, "Последние события", -1)),
      _createElementVNode("button", { onClick: openFullHistory }, "Все")
    ]),
    (__props.lastHistory.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.lastHistory, (eventItem, index) => {
            return (_openBlock(), _createBlock(ProgressEventItem, {
              title: eventItem.title,
              date: eventItem.date,
              subtitle: eventItem.performer,
              key: index,
              class: "item"
            }, null, 8, ["title", "date", "subtitle"]))
          }), 128))
        ]))
      : (_openBlock(), _createBlock(EventEmptyState, { key: 1 }))
  ]))
}
}

})