import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "buildings-tab" }
const _hoisted_2 = {
  key: 1,
  class: "list-container"
}
const _hoisted_3 = {
  key: 0,
  class: "button-container"
}

import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import EmptyBuildings from "@/modules/buildings/components/EmptyBuildings.vue";
import BuildingsList from "@/modules/buildings/components/BuildingsList.vue";
import UIButton from "@/modules/UI-kit/UIButton.vue";
import IconPlus from "@/icons/IconPlus.vue";
import { ModalTypes, Roles } from "@/enums/enums";


export default /*@__PURE__*/_defineComponent({
  __name: 'BuildingsTab',
  setup(__props) {

const store = useStore();

const buildings = computed(() => store.state.buildingsStore.buildings);

const role = computed(() => store.state.authStore.role);

const openCreateBuildingModal = () => {
  store.commit("modalsStore/showModal", {
    modalType: ModalTypes.createBuildingModal,
    modalData: {},
  });
};
// onMounted(async () => {
//   await store.dispatch("buildingsStore/fetchBuildings");
// });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!buildings.value.length)
      ? (_openBlock(), _createBlock(EmptyBuildings, { key: 0 }))
      : _createCommentVNode("", true),
    (buildings.value.length >= 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(BuildingsList, { buildings: buildings.value }, null, 8, ["buildings"]),
          (role.value === _unref(Roles).partner || role.value === _unref(Roles).executive)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(UIButton, {
                  class: "button",
                  onClick: openCreateBuildingModal
                }, {
                  default: _withCtx(() => [
                    _createVNode(IconPlus, { class: "plus" }),
                    _cache[0] || (_cache[0] = _createTextVNode(" Добавить помещение "))
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})