import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from "vue";
import { useStore } from "vuex";
import { ModalTypes } from "@/enums/enums";
import IconSettings from "@/icons/IconSettings.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardFiltersBar',
  setup(__props) {

const store = useStore();

const filtersRequestBody = computed(
  () => store.state.managerDashboardStore.filtersRequestBody
);

const setIconColor = computed(
  () => (isActive: boolean) => isActive ? "main-white" : "main-black"
);

const isActive = computed(() => {
  return !!(
    filtersRequestBody.value.cities.length ||
    filtersRequestBody.value.statuses.length ||
    filtersRequestBody.value.sub_statuses.length ||
    filtersRequestBody.value.date.end ||
    filtersRequestBody.value.date.start
  );
});

const onClickHandler = () => {
  store.commit("modalsStore/showModal", {
    modalType: ModalTypes.dashboardManagerFilters,
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["settings-button", { active: isActive.value }]),
    onClick: onClickHandler
  }, [
    _createVNode(IconSettings, {
      color: setIconColor.value(isActive.value)
    }, null, 8, ["color"])
  ], 2))
}
}

})