import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "partner-main-information" }
const _hoisted_2 = { class: "main-info" }
const _hoisted_3 = { class: "user-info" }
const _hoisted_4 = { class: "info" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "city" }

import { PropType } from "vue";
import { Undefinable } from "@/types/types";
import UIUserAvatar from "@/modules/UI-kit/UIUserAvatar.vue";
import IconDropDown from "@/icons/IconDropDown.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PartnerMainInformation',
  props: {
  name: {
    type: Object as PropType<Undefinable<string>>,
    required: true,
  },
  surname: {
    type: Object as PropType<Undefinable<string>>,
    required: true,
  },
  city: {
    type: Object as PropType<Undefinable<string>>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(UIUserAvatar, { class: "avatar" }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(`${__props.name} ${__props.surname}`), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(__props.city || ""), 1)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "status" }, "Партнер", -1))
      ])
    ])
  ]))
}
}

})