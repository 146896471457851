import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "equipments-list" }

import { PropType } from "vue";
import { IEquipment } from "@/modules/equipments/types/equipments.types";
import EquipmentsListItem from "@/modules/equipments/components/EquipmentsListItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'EquipmentsList',
  props: {
  equipments: {
    type: Array as PropType<Array<IEquipment>>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.equipments, (equipment) => {
      return (_openBlock(), _createBlock(EquipmentsListItem, {
        key: equipment.id,
        equipment: equipment,
        class: "item"
      }, null, 8, ["equipment"]))
    }), 128))
  ]))
}
}

})