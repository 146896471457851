import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "checkbox-container" }
const _hoisted_2 = ["value", "name"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "label-text" }

import { computed } from "vue";
import IconCheck from "@/icons/IconCheck.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UICheckbox',
  props: {
  modelValue: {
    type: Boolean,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
  checked: {
    type: Boolean,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;

const onChangeValue = (event: InputEvent) => {
  const target = event.target as HTMLInputElement;
  emits("update:modelValue", target.checked);
};

const isChecked = computed(() => {
  return props.checked ? "circle-checked" : "";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      class: "input",
      value: __props.value,
      name: __props.name,
      onChange: onChangeValue
    }, null, 40, _hoisted_2),
    _createElementVNode("label", {
      class: "label",
      for: __props.id
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(["circle", isChecked.value])
      }, [
        _withDirectives(_createVNode(IconCheck, { class: "icon" }, null, 512), [
          [_vShow, isChecked.value]
        ])
      ], 2),
      _createElementVNode("span", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(__props.label), 1)
        ])
      ])
    ], 8, _hoisted_3)
  ]))
}
}

})