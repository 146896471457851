import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filters-date" }
const _hoisted_2 = { class: "inputs" }
const _hoisted_3 = { class: "input" }
const _hoisted_4 = { class: "input" }

import { useStore } from "vuex";
import { onMounted, PropType, reactive, watch } from "vue";
import UIInput from "@/modules/UI-kit/UIInput.vue";
import UIText from "@/modules/UI-kit/UIText.vue";

interface IDate {
  title?: string;
  start: string;
  end: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardDateFilter',
  props: {
  date: {
    type: Object as PropType<IDate>,
    required: true,
  },
},
  setup(__props) {

const store = useStore();

const dates = reactive<IDate>({
  title: "",
  start: "",
  end: "",
});

const props = __props;

watch(
  () => dates,
  (newDates) => {
    store.commit("managerDashboardStore/setDate", newDates);
  }
);

onMounted(() => {
  dates.start = props.date.start;
  dates.end = props.date.end;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(UIText, {
      "font-size": "17px",
      "font-family": "proxima-nova-semibold",
      class: "title"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(__props.date.title), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(UIInput, {
          "place-holder": "с",
          modelValue: dates.start,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dates.start) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(UIInput, {
          "place-holder": "по",
          modelValue: dates.end,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((dates.end) = $event))
        }, null, 8, ["modelValue"])
      ])
    ])
  ]))
}
}

})