import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dashboard-search-bar" }
const _hoisted_2 = ["placeholder", "value"]

import { computed, ref } from "vue";
import IconSearch from "@/icons/IconSearch.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardSearchbar',
  props: {
  modelValue: {
    type: String,
    required: true,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {



const emits = __emit;

const isFocused = ref(false);

const placeholderText = computed(() =>
  !isFocused.value ? "Фамилия, город и так далее" : ""
);

const onInputHandler = (event: InputEvent) => {
  const target = event.target as HTMLInputElement;
  const value = target.value;
  emits("update:modelValue", value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      ref: "searchInput",
      type: "text",
      class: "search-input",
      placeholder: placeholderText.value,
      value: __props.modelValue,
      onInput: onInputHandler,
      onFocusin: _cache[0] || (_cache[0] = ($event: any) => (isFocused.value = true)),
      onFocusout: _cache[1] || (_cache[1] = ($event: any) => (isFocused.value = false))
    }, null, 40, _hoisted_2),
    _createVNode(IconSearch, { class: "search-icon" })
  ]))
}
}

})